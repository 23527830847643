/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
/* eslint-disable no-console */
import Vue from 'vue'
import App from './App.vue'

import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import SelectOverflow from '@/directives/v-select-overflow'

Vue.use(SelectOverflow)

Vue.use(VueLoading, {
  color: '#12598d'
})

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/filled.css'
import 'material-icons/iconfont/outlined.css'
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw.js?v2', {}).then(
    function (registration) {
      // Registration was successful
      console.log('ServiceWorker registration successful with scope: ', registration.scope)
    },
    function (err) {
      // registration failed :(
      console.log('ServiceWorker registration failed: ', err)
    }
  )
}

// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// Filters
import './filters/filters.js'

// Theme Configurations
import '../themeConfig.js'

// Firebase
import '@/firebase/firebaseConfig'

// Globally Registered Components
import './globalComponents.js'

// Styles: SCSS
import './assets/scss/main.scss'

// Tailwind
import '@/assets/css/main.css'

// Vue Router
import router from './router'

// Vuex Store
import store from './store/store'

// i18n
import i18n from './i18n/i18n'

let locale = navigator.language.slice(0, 2)
if (!['en', 'de'].includes(locale)) {
  locale = 'en'
}
i18n.locale = locale

// VeeValidate
import VeeValidate, { Validator } from 'vee-validate'
Vue.use(VeeValidate)

import customRules from './validations/custom-rules'
customRules(Validator)

// Merge all defined languages in the dictionary and sets the current locale.
import validationDictionary from './validations/validationDictionary'
Validator.localize(i18n.locale, validationDictionary[i18n.locale])

const config = require(`./environment/${process.env.NODE_ENV}.json`)
Vue.prototype.$config = config
// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

//Firebase
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/database'
import 'firebase/analytics'
Vue.prototype.$db = firebase.firestore()
Vue.prototype.$firebase = firebase
Vue.prototype.$functions = firebase.app().functions('europe-west1')
Vue.prototype.$database = firebase.database()
Vue.prototype.$defaultTimezone = 'Europe/Berlin'

Vue.prototype.$languagesSuppported = ['en', 'de']
Vue.prototype.$defaultLanguage = 'en'
Vue.prototype.$vegaCostPerVisitor = 0.02

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// Feather font icon
require('./assets/css/iconfont.css')

// Croppa
import 'vue-croppa/dist/vue-croppa.css'
import Croppa from 'vue-croppa'
Vue.use(Croppa)

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

// Server bus
export const serverBus = new Vue()
Vue.prototype.$serverBus = serverBus

// Mobile app settings

import { Device } from '@capacitor/device'
import { StatusBar, Style } from '@capacitor/status-bar'
import { SafeArea } from 'capacitor-plugin-safe-area'

const setStatusBarStyleLight = async () => {
  await StatusBar.setStyle({ style: Style.Light })
}

const setupWithInsets = async function () {
  const insets = await SafeArea.getSafeAreaInsets()

  if (insets && insets.insets) {
    const { top } = insets.insets

    if (!top) {
      return
    }

    const ADDITIONAL_TOP_OFFSET = 10
    const HEIGHT = top + ADDITIONAL_TOP_OFFSET

    const $app = document.querySelector('#app')
    const newDiv = document.createElement('div')

    newDiv.style.position = 'fixed'
    newDiv.style.top = `-${HEIGHT}px`
    newDiv.style.left = '0px'
    newDiv.style.height = `${HEIGHT}px`
    newDiv.style.width = '100%'
    newDiv.style.backgroundColor = '#ffffff'
    newDiv.style.zIndex = '999999'

    document.body.insertBefore(newDiv, $app)
  }
}

const setMobileApp = async () => {
  const { platform } = await Device.getInfo()

  if (['ios', 'android'].includes(platform)) {
    // Display content under transparent status bar (Android only)
    StatusBar.setOverlaysWebView({ overlay: true })

    setStatusBarStyleLight()

    setupWithInsets()
  }
}

setMobileApp()

Vue.config.devtools = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'pathadvice-app'
Vue.config.debug = process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'pathadvice-app'
Vue.config.silent = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'pathadvice-app'

firebase.analytics()
Vue.prototype.$analytics = firebase.analytics()

Vue.config.productionTip = false

/* Add Mollie Profile Id */
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'pathadvice-app') {
  Vue.prototype.$mollieProfileId = 'pfl_NgwFfBvQy6'
  Vue.prototype.$mollieTestMode = false
  Vue.prototype.$functionsURL = 'https://europe-west1-pathadvice-app.cloudfunctions.net'
  Vue.prototype.$appURL = 'https://app.letsconnect.at'
  Vue.prototype.$encryptedShopifyApiKey = 'åÝÜÛfüs¶ÚÙÿ\x1C×w]qÎ÷ÕÍ\x1C\x7F·Z'
  Vue.prototype.$shopifyThemeAppExtensionId = 'e4f2485c-8572-4770-b66c-9e8370bfd857'
  Vue.prototype.$gen2EndPoint = '-hsvelwbvja-ew.a.run.app'
  Vue.prototype.$vegaEndPoint = '-ngqzu6ymaa-ew.a.run.app'
} else {
  Vue.prototype.$mollieProfileId = 'pfl_NgwFfBvQy6'
  Vue.prototype.$mollieTestMode = true
  Vue.prototype.$functionsURL = 'https://europe-west1-pathadvice-stage.cloudfunctions.net'
  Vue.prototype.$appURL = 'https://pathadvice-stage.web.app'
  Vue.prototype.$encryptedShopifyApiKey = 'ß·|Ûf¹wM\x1FñýwÛM·ã®]ëOuïÇß'
  Vue.prototype.$shopifyThemeAppExtensionId = 'aba0efab-f7db-4726-bfd2-b72cd9d864a2'
  Vue.prototype.$gen2EndPoint = '-bplgfip3ta-ew.a.run.app'
  Vue.prototype.$vegaEndPoint = '-sej5fmjyta-ew.a.run.app'
}

/* Save location search in cookie if utm_source exists in query string */
if (window.location.search.includes('utm_source')) {
  document.cookie = `locationSearch=${window.location.search};path=/;domain=${location.hostname}`
}

if (document.location.host === 'app.pathadvice.ai' || document.location.host === 'app.pathadvice.at') {
  window.location.href = document.location.href.replace(document.location.host, 'app.letsconnect.at')
}

if (document.location.host === 'pathadvice-stage.pathadvice.ai') {
  window.location.href = document.location.href.replace(document.location.host, 'pathadvice-stage.web.app')
}

import { mtranslations } from '@/mixins/mtranslations'
Vue.mixin(mtranslations)

import { mvega } from '@/mixins/mvega'
Vue.mixin(mvega)

/* Vue Toast */
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

const filterBeforeCreate = (toast, toasts) => {
  if (toasts.filter((t) => t.id === toast.id).length !== 0) {
    // Returning false discards the toast
    return false
  }
  // You can modify the toast if you want
  return toast
}

Vue.use(Toast, {
  transition: 'fade-move',
  maxToasts: 20,
  newestOnTop: true,
  filterBeforeCreate
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
