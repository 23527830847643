<template>
  <div class="ui-locked-status">
    <div class="ui-locked-status__overlay"></div>
    <div class="ui-locked-status__content">
      <div class="ui-locked-status__wrapper">
        <img class="ui-locked-status__icon" src="@/assets/images/elements/lock-icon.svg" alt="Secure" />
        <div class="ui-locked-status__title">{{ $t('locked.title') }}</div>
        <div class="ui-locked-status__text">{{ $t('locked.text') }}</div>
        <template v-if="company && company.paymentStatus === 'unpaid'">
          <vs-button class="mb-5" color="primary" type="filled" @click="goToInvoices"> {{ $t('locked.buttonMainText') }}</vs-button>
          <div class="ui-locked-status__secondary-button" @click="updatePaymentMethod">{{ $t('locked.buttonSecondaryText') }}</div>
        </template>
        <template v-else>
          <vs-button class="mb-5" color="primary" type="filled" @click="updatePaymentMethod"> {{ $t('locked.buttonSecondaryText') }}</vs-button>
          <vs-button class="mb-5" color="primary" type="filled" @click="USER_LOGOUT"> {{ $t('vue.logout') }}</vs-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { muser } from '@/mixins/muser'

export default {
  computed: {
    ...mapGetters({
      company: 'company'
    })
  },
  mixins: [muser],
  methods: {
    goToInvoices() {
      if (this.$route.name !== 'payment') {
        this.$router.push({ name: 'payment', params: { action: 'show-invoices' } })
      } else {
        this.$serverBus.$emit('show-invoices')
      }
    },
    updatePaymentMethod() {
      if (this.$route.name !== 'payment') {
        this.$router.push({ name: 'payment', params: { action: 'update-payment-method' } })
      } else {
        this.$serverBus.$emit('update-payment-method')
      }
    }
  }
}
</script>

<style lang="scss">
.ui-locked-status {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(18, 89, 141, 0.2);
  z-index: 100001;
  &__overlay {
    filter: blur(10px);
  }
  &__content {
    width: 100%;
    max-width: 710px;
    background: #ffffff;
    border-radius: 30px;
    min-height: 424px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 162px;
  }
  &__wrapper {
    max-width: 456px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__icon {
    margin-bottom: 22px;
  }
  &__title {
    font-weight: bold;
    font-size: 30px;
    line-height: 38px;
    color: #575757;
    margin-bottom: 15px;
  }
  &__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #575757;
    margin-bottom: 33px;
  }
  &__secondary-button {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #3b86f7;
    cursor: pointer;
  }
}
</style>
