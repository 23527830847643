var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mollie-checkout" }, [
    _vm.supportedPaymentMethods.length > 1
      ? _c("div", { staticClass: "mollie-checkout__checkout" }, [
          _c("div", { staticClass: "mollie-checkout__checkout__buttons" }, [
            _vm.supportedPaymentMethods.includes("card")
              ? _c(
                  "div",
                  {
                    staticClass: "mollie-checkout__checkout__button",
                    class: {
                      "mollie-checkout__checkout__button--active":
                        _vm.paymentType === "card",
                    },
                    on: {
                      click: function () {
                        return _vm.showPaymentType("card")
                      },
                    },
                  },
                  [_vm._m(0)]
                )
              : _vm._e(),
            _vm.supportedPaymentMethods.includes("paypal")
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mollie-checkout__checkout__button mollie-checkout__checkout__button--paypal",
                    on: {
                      click: function () {
                        return _vm.showPaymentType("paypal")
                      },
                    },
                  },
                  [_vm._m(1)]
                )
              : _vm._e(),
            _vm.supportedPaymentMethods.includes("invoice")
              ? _c(
                  "div",
                  {
                    staticClass: "mollie-checkout__checkout__button",
                    class: {
                      "mollie-checkout__checkout__button--active":
                        _vm.paymentType === "invoice",
                    },
                    on: {
                      click: function () {
                        return _vm.showPaymentType("invoice")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("vue.invoice")) + "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm.supportedPaymentMethods.includes("sepa")
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mollie-checkout__checkout__button mollie-checkout__checkout__button--sepa",
                    on: {
                      click: function () {
                        return _vm.showPaymentType("sepa")
                      },
                    },
                  },
                  [_vm._m(2)]
                )
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "mollie-checkout__holder",
        class: {
          "mollie-checkout__holder--modal": _vm.isModal,
        },
      },
      [
        _vm.isLoading
          ? _c("div", { staticClass: "mollie-checkout__loading" }, [
              _vm.isLoadingInProccess
                ? _c(
                    "div",
                    { staticClass: "mollie-checkout__loading__loader" },
                    [
                      _c("span", {
                        staticClass:
                          "mollie-checkout__loading__loader__spinner",
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "mollie-checkout__loading__loader__text",
                        },
                        [_vm._v(_vm._s(_vm.loaderSpinnerText))]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.isLoadingSuccessful && !_vm.isLoadingInProccess
                ? _c(
                    "div",
                    { staticClass: "mollie-checkout__loading__loader" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/register-funnel/check.png"),
                          alt: "Success",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mollie-checkout__loading__loader__title",
                        },
                        [_vm._v("All good!")]
                      ),
                      _vm.isFirstInvoice
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "mollie-checkout__loading__loader__text",
                            },
                            [_vm._v("We’ll redirect you to your account")]
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.paymentType === "card" &&
                  _vm.mollie &&
                  _vm.areMollieComponentsReady,
                expression:
                  "paymentType === 'card' && mollie && areMollieComponentsReady",
              },
            ],
          },
          [
            _c("div", { staticClass: "wrapper" }, [
              _c(
                "form",
                {
                  ref: "mcForm",
                  staticClass: "form",
                  attrs: { id: "mcForm" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onMollieCreditCardPayment.apply(
                        null,
                        arguments
                      )
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mollie-checkout__fields-container form-fields relative overflow-hidden",
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "form-group form-group--card-holder" },
                          [
                            _c("div", {
                              ref: "card-holder",
                              attrs: { id: "card-holder" },
                            }),
                            _vm._m(3),
                            _c("div", {
                              ref: "card-holder-error",
                              staticClass: "field-error",
                              attrs: { id: "card-holder-error", role: "alert" },
                            }),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "form-group form-group--card-number" },
                          [
                            _c("div", {
                              ref: "card-number",
                              attrs: { id: "card-number" },
                            }),
                            _vm._m(4),
                            _c("div", {
                              ref: "card-number-error",
                              staticClass: "field-error",
                              attrs: { id: "card-number-error", role: "alert" },
                            }),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "form-group form-group--expiry-date" },
                          [
                            _vm._m(5),
                            _c("div", {
                              ref: "expiry-date",
                              attrs: { id: "expiry-date" },
                            }),
                            _c("div", {
                              ref: "expiry-date-error",
                              staticClass: "field-error",
                              attrs: { id: "expiry-date-error", role: "alert" },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "form-group form-group--verification-code",
                          },
                          [
                            _vm._m(6),
                            _c("div", {
                              ref: "verification-code",
                              attrs: { id: "verification-code" },
                            }),
                            _c("div", {
                              ref: "verification-code-error",
                              staticClass: "field-error",
                              attrs: {
                                id: "verification-code-error",
                                role: "alert",
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  !_vm.updatePaymentMethod ? _c("Promocode") : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-center" },
                    [
                      _c(
                        "button",
                        {
                          ref: "submit-button",
                          staticClass: "mollie-checkout__next-button",
                          attrs: { id: "submit-button", type: "submit" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.btnTitle) +
                              "\n              "
                          ),
                          _c("img", {
                            staticClass: "mollie-checkout__next-button__icon",
                            attrs: {
                              src: require("@/assets/images/register-funnel/forward-arrow.png"),
                              alt: "Secure",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _c("div", {
                    ref: "form-error",
                    staticClass: "field-error",
                    attrs: { id: "form-error", role: "alert" },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
        !_vm.selectedPaymentMethod && !_vm.updatePaymentMethod
          ? _c("Promocode")
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.paymentType === "paypal",
                expression: "paymentType === 'paypal'",
              },
            ],
          },
          [
            _c("div", { staticClass: "mollie-checkout__paypal__wrapper" }, [
              _vm._v("Paypal"),
            ]),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.paymentType === "sepa",
                expression: "paymentType === 'sepa'",
              },
            ],
          },
          [_vm._v("Sepa")]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.paymentType === "invoice",
                expression: "paymentType === 'invoice'",
              },
            ],
          },
          [
            _c("div", { staticClass: "mollie-checkout__invoice__wrapper" }, [
              _c(
                "div",
                { staticClass: "mollie-checkout__invoice__wrapper__content" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mollie-checkout__invoice__wrapper__content__form",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mollie-checkout__invoice__wrapper__content__form__fields",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mollie-checkout__invoice__wrapper__content__form__fields__field mt-0",
                              staticStyle: { "max-width": "179px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                },
                                [
                                  _c(
                                    "multiselect",
                                    {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      ref: "title",
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__select mollie-checkout__invoice__wrapper__content__form__fields__field__select--small-min-height",
                                      attrs: {
                                        options: _vm.titles,
                                        multiple: false,
                                        "close-on-select": true,
                                        "clear-on-select": false,
                                        placeholder: "",
                                        selectLabel: "",
                                        selectedLabel: "",
                                        deselectLabel: "",
                                        label: "name",
                                        "track-by": "name",
                                        name: _vm.$t("inputs.salutation"),
                                        "open-direction": "bottom",
                                        "data-vv-as":
                                          _vm.$t("inputs.salutation"),
                                        "preserve-search": true,
                                        "preselect-first": false,
                                      },
                                      on: {
                                        close: function ($event) {
                                          _vm.isOpenTitleSelect = false
                                        },
                                        open: function ($event) {
                                          _vm.isOpenTitleSelect = true
                                        },
                                      },
                                      model: {
                                        value: _vm.invoice.title,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.invoice, "title", $$v)
                                        },
                                        expression: "invoice.title",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "noResult" }, [
                                        _vm._v("No results found"),
                                      ]),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                      class: {
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                          !!_vm.invoice.title ||
                                          _vm.isOpenTitleSelect,
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label--over":
                                          _vm.isOpenTitleSelect,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.title.$el.focus()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.$t("inputs.salutation")) +
                                          " *\n                  "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm.errors.first(_vm.$t("inputs.salutation"))
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-danger text-lg flex items-center",
                                      staticStyle: { "margin-top": "10px" },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          src: require("@/assets/images/pages/login/error-icon.svg"),
                                        },
                                      }),
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            _vm.$t("inputs.salutation")
                                          )
                                        ) + "\n                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mollie-checkout__invoice__wrapper__content__form__fields__field",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.invoice.academicTitle,
                                        expression: "invoice.academicTitle",
                                      },
                                    ],
                                    ref: "academicTitle",
                                    staticClass:
                                      "mollie-checkout__invoice__wrapper__content__form__fields__field__input",
                                    attrs: {
                                      type: "text",
                                      name: _vm.$t("inputs.academicTitle"),
                                      "label-placeholder": _vm.$t(
                                        "inputs.academicTitle"
                                      ),
                                    },
                                    domProps: {
                                      value: _vm.invoice.academicTitle,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.invoice,
                                          "academicTitle",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                      class: {
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                          _vm.invoice.academicTitle &&
                                          _vm.invoice.academicTitle.length > 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.academicTitle.focus()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t("inputs.academicTitle")
                                          ) +
                                          "\n                  "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mollie-checkout__invoice__wrapper__content__form__fullname",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.invoice.firstName,
                                            expression: "invoice.firstName",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        ref: "firstName",
                                        staticClass:
                                          "mollie-checkout__invoice__wrapper__content__form__fields__field__input",
                                        attrs: {
                                          type: "text",
                                          "data-vv-as":
                                            _vm.$t("inputs.firstName"),
                                          name: _vm.$t("inputs.firstName"),
                                          "label-placeholder":
                                            _vm.$t("inputs.firstName"),
                                        },
                                        domProps: {
                                          value: _vm.invoice.firstName,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.invoice,
                                              "firstName",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                          class: {
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                              _vm.invoice.firstName &&
                                              _vm.invoice.firstName.length > 0,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.firstName.focus()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.$t("inputs.firstName")
                                              ) +
                                              " *\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.errors.first(_vm.$t("inputs.firstName"))
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-danger text-lg flex items-center",
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              src: require("@/assets/images/pages/login/error-icon.svg"),
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                _vm.$t("inputs.firstName")
                                              )
                                            ) + "\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.invoice.lastName,
                                            expression: "invoice.lastName",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        ref: "lastName",
                                        staticClass:
                                          "mollie-checkout__invoice__wrapper__content__form__fields__field__input",
                                        attrs: {
                                          type: "text",
                                          "data-vv-as":
                                            _vm.$t("inputs.lastName"),
                                          name: _vm.$t("inputs.lastName"),
                                          "label-placeholder":
                                            _vm.$t("inputs.lastName"),
                                        },
                                        domProps: {
                                          value: _vm.invoice.lastName,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.invoice,
                                              "lastName",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                          class: {
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                              _vm.invoice.lastName &&
                                              _vm.invoice.lastName.length > 0,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.lastName.focus()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.$t("inputs.lastName")
                                              ) +
                                              " *\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.errors.first(_vm.$t("inputs.lastName"))
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-danger text-lg flex items-center",
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              src: require("@/assets/images/pages/login/error-icon.svg"),
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                _vm.$t("inputs.lastName")
                                              )
                                            ) + "\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mollie-checkout__invoice__wrapper__content__form__fields__field",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.invoice.company,
                                        expression: "invoice.company",
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    ref: "company",
                                    staticClass:
                                      "mollie-checkout__invoice__wrapper__content__form__fields__field__input",
                                    attrs: {
                                      type: "text",
                                      "data-vv-as": _vm.$t("inputs.company"),
                                      name: _vm.$t("inputs.company"),
                                      "label-placeholder":
                                        _vm.$t("inputs.company"),
                                    },
                                    domProps: { value: _vm.invoice.company },
                                    on: {
                                      change: function ($event) {
                                        _vm.$validator.validate(
                                          _vm.$t("inputs.vatNumber")
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.invoice,
                                          "company",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                      class: {
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                          _vm.invoice.company &&
                                          _vm.invoice.company.length > 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.company.focus()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.$t("inputs.company")) +
                                          " *\n                  "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.errors.first(_vm.$t("inputs.company"))
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-danger text-lg flex items-center",
                                      staticStyle: { "margin-top": "10px" },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          src: require("@/assets/images/pages/login/error-icon.svg"),
                                        },
                                      }),
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            _vm.$t("inputs.company")
                                          )
                                        ) + "\n                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mollie-checkout__invoice__wrapper__content__form__fields__field",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                },
                                [
                                  _vm.paymentType === "invoice"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model.lazy",
                                            value: _vm.invoice.vatNumber,
                                            expression: "invoice.vatNumber",
                                            modifiers: { lazy: true },
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "isvatvalid",
                                            expression: "'isvatvalid'",
                                          },
                                        ],
                                        ref: "vatNumber",
                                        staticClass:
                                          "mollie-checkout__invoice__wrapper__content__form__fields__field__input",
                                        attrs: {
                                          type: "text",
                                          name: _vm.$t("inputs.vatNumber"),
                                          "label-placeholder":
                                            _vm.$t("inputs.vatNumber"),
                                          "data-vv-validate-on": "change",
                                        },
                                        domProps: {
                                          value: _vm.invoice.vatNumber,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.invoice,
                                              "vatNumber",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.errors.first(_vm.$t("inputs.vatNumber"))
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-danger text-lg flex items-center",
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              src: require("@/assets/images/pages/login/error-icon.svg"),
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                _vm.$t("inputs.vatNumber")
                                              )
                                            ) + "\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                      class: {
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                          _vm.invoice.vatNumber &&
                                          _vm.invoice.vatNumber.length > 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.vatNumber.focus()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.$t("inputs.vatNumber")) +
                                          "\n                  "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mollie-checkout__invoice__wrapper__content__form__fields__field",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.invoice.streetAddress,
                                        expression: "invoice.streetAddress",
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    ref: "streetAddress",
                                    staticClass:
                                      "mollie-checkout__invoice__wrapper__content__form__fields__field__input",
                                    attrs: {
                                      type: "text",
                                      "data-vv-as": _vm.$t(
                                        "inputs.streetAddress"
                                      ),
                                      name: _vm.$t("inputs.streetAddress"),
                                      "label-placeholder": _vm.$t(
                                        "inputs.streetAddress"
                                      ),
                                    },
                                    domProps: {
                                      value: _vm.invoice.streetAddress,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.invoice,
                                          "streetAddress",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                      class: {
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                          _vm.invoice.streetAddress &&
                                          _vm.invoice.streetAddress.length > 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.streetAddress.focus()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t("inputs.streetAddress")
                                          ) +
                                          " *\n                  "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.errors.first(_vm.$t("inputs.streetAddress"))
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-danger text-lg flex items-center",
                                      staticStyle: { "margin-top": "10px" },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          src: require("@/assets/images/pages/login/error-icon.svg"),
                                        },
                                      }),
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            _vm.$t("inputs.streetAddress")
                                          )
                                        ) + "\n                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mollie-checkout__invoice__wrapper__content__form__equal-parts",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.invoice.city,
                                            expression: "invoice.city",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        ref: "city",
                                        staticClass:
                                          "mollie-checkout__invoice__wrapper__content__form__fields__field__input",
                                        attrs: {
                                          type: "text",
                                          "data-vv-as": _vm.$t("inputs.city"),
                                          name: _vm.$t("inputs.city"),
                                          "label-placeholder":
                                            _vm.$t("inputs.city"),
                                        },
                                        domProps: { value: _vm.invoice.city },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.invoice,
                                              "city",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                          class: {
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                              _vm.invoice.city &&
                                              _vm.invoice.city.length > 0,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.city.focus()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(_vm.$t("inputs.city")) +
                                              " *\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.errors.first(_vm.$t("inputs.city"))
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-danger text-lg flex items-center",
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              src: require("@/assets/images/pages/login/error-icon.svg"),
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                _vm.$t("inputs.city")
                                              )
                                            ) + "\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.invoice.zipOrPostalCode,
                                            expression:
                                              "invoice.zipOrPostalCode",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        ref: "zipOrPostalCode",
                                        staticClass:
                                          "mollie-checkout__invoice__wrapper__content__form__fields__field__input",
                                        attrs: {
                                          type: "text",
                                          "data-vv-as": _vm.$t(
                                            "inputs.zipOrPostalCode"
                                          ),
                                          name: _vm.$t(
                                            "inputs.zipOrPostalCode"
                                          ),
                                          "label-placeholder": _vm.$t(
                                            "inputs.zipOrPostalCode"
                                          ),
                                        },
                                        domProps: {
                                          value: _vm.invoice.zipOrPostalCode,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.invoice,
                                              "zipOrPostalCode",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                          class: {
                                            "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                              _vm.invoice.zipOrPostalCode &&
                                              _vm.invoice.zipOrPostalCode
                                                .length > 0,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.zipOrPostalCode.focus()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.$t("inputs.zipOrPostalCode")
                                              ) +
                                              " *\n                    "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.errors.first(
                                    _vm.$t("inputs.zipOrPostalCode")
                                  )
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-danger text-lg flex items-center",
                                          staticStyle: { "margin-top": "10px" },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              src: require("@/assets/images/pages/login/error-icon.svg"),
                                            },
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first(
                                                _vm.$t("inputs.zipOrPostalCode")
                                              )
                                            ) + "\n                  "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mollie-checkout__invoice__wrapper__content__form__fields__field",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mollie-checkout__invoice__wrapper__content__form__fields__field__inner",
                                },
                                [
                                  _c(
                                    "multiselect",
                                    {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      ref: "country",
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__select",
                                      attrs: {
                                        options: _vm.countryCodes,
                                        multiple: false,
                                        "close-on-select": true,
                                        "clear-on-select": false,
                                        placeholder: "",
                                        selectLabel: "",
                                        selectedLabel: "",
                                        deselectLabel: "",
                                        label: "countryName",
                                        "track-by": "name",
                                        name: _vm.$t("inputs.country"),
                                        "open-direction": "bottom",
                                        "data-vv-as": _vm.$t("inputs.country"),
                                        "preserve-search": true,
                                        "preselect-first": false,
                                      },
                                      on: {
                                        close: function ($event) {
                                          _vm.isOpenCountrySelect = false
                                        },
                                        open: function ($event) {
                                          _vm.isOpenCountrySelect = true
                                        },
                                      },
                                      model: {
                                        value: _vm.invoice.country,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.invoice, "country", $$v)
                                        },
                                        expression: "invoice.country",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "noResult" }, [
                                        _vm._v("No results found"),
                                      ]),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label",
                                      class: {
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved":
                                          !!_vm.invoice.country ||
                                          _vm.isOpenCountrySelect,
                                        "mollie-checkout__invoice__wrapper__content__form__fields__field__label--over":
                                          _vm.isOpenCountrySelect,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.country.$el.focus()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.$t("inputs.country")) +
                                          " *\n                  "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm.errors.first(_vm.$t("inputs.country"))
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-danger text-lg flex items-center",
                                      staticStyle: { "margin-top": "10px" },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          src: require("@/assets/images/pages/login/error-icon.svg"),
                                        },
                                      }),
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            _vm.$t("inputs.country")
                                          )
                                        ) + "\n                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "flex items-center justify-center" }, [
              _c(
                "button",
                {
                  staticClass: "mollie-checkout__next-button",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onInvoiceSubmit.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.btnTitle) + "\n          "
                  ),
                  _c("img", {
                    staticClass: "mollie-checkout__next-button__icon",
                    attrs: {
                      src: require("@/assets/images/register-funnel/forward-arrow.png"),
                      alt: "Secure",
                    },
                  }),
                ]
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "mollie-checkout__checkout__button__wrapper" },
      [
        _c("div", { staticClass: "flex flex-col" }, [
          _c("div", { staticClass: "flex" }, [
            _c("img", {
              staticClass: "m-px",
              attrs: {
                src: require("@/assets/images/register-funnel/VISA.svg"),
                alt: "VISA",
              },
            }),
            _c("img", {
              staticClass: "m-px",
              attrs: {
                src: require("@/assets/images/register-funnel/MASTERCARD.svg"),
                alt: "MASTERCARD",
              },
            }),
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("img", {
              staticClass: "m-px",
              attrs: {
                src: require("@/assets/images/register-funnel/AMERICAN EXPRESS.svg"),
                alt: "AMERICAN EXPRESS",
              },
            }),
            _c("img", {
              staticClass: "m-px",
              attrs: {
                src: require("@/assets/images/register-funnel/DISCOVER.svg"),
                alt: "DISCOVER",
              },
            }),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "mollie-checkout__checkout__button__wrapper" },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/images/register-funnel/paypal.png"),
            alt: "paypal",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "mollie-checkout__checkout__button__wrapper" },
      [
        _c("div", { staticClass: "flex flex-col" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/register-funnel/sepa.svg"),
              alt: "sepa",
            },
          }),
          _c("img", {
            attrs: {
              src: require("@/assets/images/register-funnel/Lastschrift.svg"),
              alt: "Lastschrift",
            },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "label", attrs: { for: "card-holder" } },
      [_c("div", [_vm._v("Card holder")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "label", attrs: { for: "card-number" } },
      [_c("div", [_vm._v("Card number")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "label", attrs: { for: "expiry-date" } },
      [_c("div", [_vm._v("Expiry date")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "label", attrs: { for: "verification-code" } },
      [_c("div", [_vm._v("CVC")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }