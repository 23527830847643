/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

const actions = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  updateVerticalNavMenuWidth({ commit }, width) {
    commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
  },

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit('UPDATE_STARRED_PAGE', payload)
  },

  // The Navbar
  arrangeStarredPagesLimited({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_LIMITED', list)
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_MORE', list)
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////

  toggleContentOverlay({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY')
  },
  updateTheme({ commit }, val) {
    commit('UPDATE_THEME', val)
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  updateUserInfo({ commit }, payload) {
    commit('UPDATE_USER_INFO', payload)
  },

  removeUserInfo({ commit }, payload) {
    commit('REMOVE_USER_INFO', payload)
  },

  // /////////////////////////////////////////////
  // Visitors
  // /////////////////////////////////////////////
  updateVisitors({ commit }, payload) {
    commit('UPDATE_VISITORS', payload)
  },
  updateIncomingRequests({ commit }, payload) {
    commit('UPDATE_INCOMING_REQUESTS', payload)
  },
  updateStaticIncoming({ commit }, payload) {
    commit('UPDATE_STATIC_INCOMING', payload)
  },
  updateB2BEnabled({ commit }, payload) {
    commit('UPDATE_VISITORS_B2B_ENABLED', payload)
  },
  updateShowOnlineVisitors({ commit }, payload) {
    commit('UPDATE_VISITORS_SHOW_ONLINE_VISITORS', payload)
  },
  updateVisitorsDateRange({ commit }, payload) {
    commit('UPDATE_VISITORS_DATE_RANGE', payload)
  },
  updateVisitorsDateRangeIncoming({ commit }, payload) {
    commit('UPDATE_VISITORS_DATE_RANGE_INCOMING', payload)
  },
  addNewVisitors({ commit }, payload) {
    commit('NEW_VISITORS_ADD', payload)
  },
  removeNotifications({ commit }, payload) {
    commit('NOTIFICATIONS_REMOVE', payload)
  },
  clearNotifications({ commit }) {
    commit('NOTIFICATIONS_CLEAR')
  },
  unreadVisitorsClear({ commit }, payload) {
    commit('UNREAD_VISITORS_CLEAR', payload)
  },
  updateAudioAccess({ commit }, payload) {
    commit('UPDATE_AUDIO_ACCESS', payload)
  },

  updateDialogs({ commit }, payload) {
    commit('UPDATE_DIALOGS', payload)
  },
  updateABTests({ commit }, payload) {
    commit('UPDATE_ABTESTS', payload)
  },
  updateAllDialogs({ commit }, payload) {
    commit('UPDATE_ALL_DIALOGS', payload)
  },

  updateCompanyUsers({ commit }, payload) {
    commit('UPDATE_COMPANY_USERS', payload)
  },

  updateMeetings({ commit }, payload) {
    commit('UPDATE_MEETINGS', payload)
  },

  updateVersion({ commit }, payload) {
    commit('UPDATE_VERSION', payload)
  },
  updateCampaigns({ commit }, payload) {
    commit('UPDATE_CAMPAIGNS', payload)
  },
  updateUsers({ commit }, payload) {
    commit('UPDATE_USERS', payload)
  },
  updateAssignmentRules({ commit }, payload) {
    commit('UPDATE_ASSIGNMENT_RULES', payload)
  },
  updateUserGroups({ commit }, payload) {
    commit('UPDATE_USER_GROUPS', payload)
  },
  updateInvoices({ commit }, payload) {
    commit('UPDATE_INVOICES', payload)
  },
  updateSubscription({ commit }, payload) {
    commit('UPDATE_SUBSCRIPTION', payload)
  },
  updateSubscriptionPlans({ commit }, payload) {
    commit('UPDATE_SUBSCRIPTION_PLANS', payload)
  },
  updateRefreshState({ commit }, payload) {
    commit('UPDATE_REFRESH_STATE', payload)
  },
  updateCompany({ commit }, payload) {
    commit('UPDATE_COMPANY', payload)
  },
  updateChatFilesUpload({ commit }, payload) {
    commit('setChatFilesUpload', payload)
  },
  updateCallCenterSettings({ commit }, payload) {
    commit('UPDATE_CALL_CENTER_SETTINGS', payload)
  },
  updateCallCenterUserWorktime({ commit }, payload) {
    commit('UPDATE_CALL_CENTER_USER_WORKTIME', payload)
  },
  updateAgencySettings({ commit }, payload) {
    commit('UPDATE_AGENCY_SETTINGS', payload)
  },
  updateVisitorIsTyping({ commit }, payload) {
    commit('UPDATE_VISITOR_IS_TYPING', payload)
  },
  updateVisitorId({ commit }, payload) {
    commit('UPDATE_VISITOR_ID', payload)
  },
  updateVisitor({ commit }, payload) {
    commit('UPDATE_VISITOR', payload)
  },
  updateCallCenterAgentsToBeNotified({ commit }, payload) {
    commit('UPDATE_CALL_CENTER_AGENTS_TO_BE_NOTIFIED', payload)
  },
  addOnlineUsers({ commit }, payload) {
    commit('ADD_ONLINE_USERS', payload)
  },
  removeOnlineUsers({ commit }, payload) {
    commit('REMOVE_ONLINE_USERS', payload)
  },
  updateBanners({ commit }, payload) {
    commit('UPDATE_BANNERS', payload)
  },
  updateBannersCarouselSettings({ commit }, payload) {
    commit('UPDATE_BANNERS_CAROUSEL_SETTINGS', payload)
  },
  updateDialog({ commit }, payload) {
    commit('UPDATE_DIALOG', payload)
  },
  updateDialogPartial({ commit }, payload) {
    commit('UPDATE_DIALOG_PARTIAL', payload)
  },
  updateLockedUI({ commit }, payload) {
    commit('UPDATE_LOCKED_UI', payload)
  },
  updateSubscriptionCancelled({ commit }, payload) {
    commit('UPDATE_SUBSCRIPTION_CANCELLED', payload)
  },
  updateAppSumoSubscriptionRefunded({ commit }, payload) {
    commit('UPDATE_APPSUMO_SUBSCRIPTION_REFUNDED', payload)
  },
  updateAppSumoDeleteUsers({ commit }, payload) {
    commit('UPDATE_APPSUMO_DELETE_USERS', payload)
  },
  updateCurrency({ commit }, payload) {
    commit('UPDATE_CURRENCY', payload)
  },
  updateWidgetsAssigned({ commit }, payload) {
    commit('UPDATE_WIDGETS_ASSIGNED', payload)
  },
  updateApiKeys({ commit }, payload) {
    commit('UPDATE_API_KEYS', payload)
  },
  resetMainStore({ commit }, payload) {
    commit('RESET_MAIN_STORE', payload)
  },
  setSelectedAccount({ commit }, payload) {
    commit('SET_SELECTED_ACCOUNT', payload)
  },
  setAccountList({ commit }, payload) {
    commit('SET_ACCOUNT_LIST', payload)
  },
  setSelectedAccountApiKeys({ commit }, payload) {
    commit('SET_SELECTED_ACCOUNT_APIKEYS', payload)
  }
}

export default actions
