export default {
  updateLastUpdated({ commit }, value) {
    commit('UPDATE_LAST_UPDATE', value)
  },
  updateDateRange({ commit }, value) {
    commit('UPDATE_DATE_RANGE', value)
  },
  updateDialogList({ commit }, value) {
    commit('UPDATE_DIALOG_LIST', value)
  },
  updateCampaignList({ commit }, value) {
    commit('UPDATE_CAMPAIGN_LIST', value)
  },
  updateDeviceData({ commit }, value) {
    commit('UPDATE_DEVICE_DATA', value)
  },
  updateBrowserData({ commit }, value) {
    commit('UPDATE_BROWSER_DATA', value)
  },
  updateAgentsData({ commit }, value) {
    commit('UPDATE_AGENTS_DATA', value)
  },
  updateRouteClicks({ commit }, value) {
    commit('UPDATE_ROUTE_CLICKS', value)
  },
  updateTopReferers({ commit }, value) {
    commit('UPDATE_TOP_REFERERS', value)
  },
  updateTopOrigins({ commit }, value) {
    commit('UPDATE_TOP_ORIGINS', value)
  },
  updateCallCenterUserWorktime({ commit }, value) {
    commit('UPDATE_CALL_CENTER_USER_WORKTIME', value)
  },
  updateCallCenterCallRequests({ commit }, value) {
    commit('UPDATE_CALL_CENTER_CALL_REQUESTS', value)
  },
  updateTotalVisitors({ commit }, value) {
    commit('UPDATE_TOTAL_VISITORS', value)
  },
  updateUniqueVisitors({ commit }, value) {
    commit('UPDATE_UNIQUE_VISITORS', value)
  },
  updateReturningVisitors({ commit }, value) {
    commit('UPDATE_RETURNING_VISITORS', value)
  },
  updateTopVisitors({ commit }, value) {
    commit('UPDATE_TOP_VISITORS', value)
  },
  updateContactFormShownCount({ commit }, value) {
    commit('UPDATE_CONTACT_FORM_SHOWN_COUNT', value)
  },
  updateContactFormFilledCount({ commit }, value) {
    commit('UPDATE_CONTACT_FORM_FILLED_COUNT', value)
  },
  updateContactFormBookedAppointmnetCount({ commit }, value) {
    commit('UPDATE_CONTACT_FORM_BOOKED_APPOINTMENT_COUNT', value)
  },
  updateShowsCount({ commit }, value) {
    commit('UPDATE_SHOWS_COUNT', value)
  },
  updateRequestCount({ commit }, value) {
    commit('UPDATE_REQUESTS_COUNT', value)
  },
  updateChatRequestCount({ commit }, value) {
    commit('UPDATE_CHAT_REQUEST_COUNT', value)
  },
  updateAudioRequestCount({ commit }, value) {
    commit('UPDATE_AUDIO_REQUEST_COUNT', value)
  },
  updateVideoRequestCount({ commit }, value) {
    commit('UPDATE_VIDEO_REQUEST_COUNT', value)
  },
  updateSessionsCount({ commit }, value) {
    commit('UPDATE_SESSIONS_COUNT', value)
  },
  updateChatSessionCount({ commit }, value) {
    commit('UPDATE_CHAT_SESSION_COUNT', value)
  },
  updateAudioSessionCount({ commit }, value) {
    commit('UPDATE_AUDIO_SESSION_COUNT', value)
  },
  updateVideoSessionCount({ commit }, value) {
    commit('UPDATE_VIDEO_SESSION_COUNT', value)
  },
  updateSessionDuration({ commit }, value) {
    commit('UPDATE_SESSIONS_DURATION', value)
  },
  updateShowsData({ commit }, value) {
    commit('UPDATE_SHOWS_DATA', value)
  },
  updaterequestsData({ commit }, value) {
    commit('UPDATE_REQUESTS_DATA', value)
  },
  updateSessionsData({ commit }, value) {
    commit('UPDATE_SESSIONS_DATA', value)
  },
  /* CAMPAIGNS */
  updateCampaignShowsCount({ commit }, value) {
    commit('UPDATE_CAMPAIGN_SHOWS_COUNT', value)
  },
  updateCampaignRequestCount({ commit }, value) {
    commit('UPDATE_CAMPAIGN_REQUESTS_COUNT', value)
  },
  updateCampaignChatRequestCount({ commit }, value) {
    commit('UPDATE_CAMPAIGN_CHAT_REQUEST_COUNT', value)
  },
  updateCampaignAudioRequestCount({ commit }, value) {
    commit('UPDATE_CAMPAIGN_AUDIO_REQUEST_COUNT', value)
  },
  updateCampaignVideoRequestCount({ commit }, value) {
    commit('UPDATE_CAMPAIGN_VIDEO_REQUEST_COUNT', value)
  },
  updateCampaignSessionsCount({ commit }, value) {
    commit('UPDATE_CAMPAIGN_SESSIONS_COUNT', value)
  },
  updateCampaignChatSessionCount({ commit }, value) {
    commit('UPDATE_CAMPAIGN_CHAT_SESSION_COUNT', value)
  },
  updateCampaignAudioSessionCount({ commit }, value) {
    commit('UPDATE_CAMPAIGN_AUDIO_SESSION_COUNT', value)
  },
  updateCampaignVideoSessionCount({ commit }, value) {
    commit('UPDATE_CAMPAIGN_VIDEO_SESSION_COUNT', value)
  },
  updateCampaignSessionDuration({ commit }, value) {
    commit('UPDATE_CAMPAIGN_SESSIONS_DURATION', value)
  },
  updateCampaignShowsData({ commit }, value) {
    commit('UPDATE_CAMPAIGN_SHOWS_DATA', value)
  },
  updateCampaignRequestsData({ commit }, value) {
    commit('UPDATE_CAMPAIGN_REQUESTS_DATA', value)
  },
  updateCampaignSessionsData({ commit }, value) {
    commit('UPDATE_CAMPAIGN_SESSIONS_DATA', value)
  },
  updateEndScreenFeedbackData({ commit }, value) {
    commit('UPDATE_ENDSCREEN_FEEDBACK_DATA', value)
  },
  updateVisitorsRatingData({ commit }, value) {
    commit('UPDATE_VISITORS_RATING_DATA', value)
  },
  updateVegaAgentConversationRatingData({ commit }, value) {
    commit('UPDATE_VEGA_AGENT_CONVERSATION_RATING_DATA', value)
  },
  updateVegaVisitorScoreFlowData({ commit }, value) {
    commit('UPDATE_VEGA_VISITOR_SCORE_FLOW_DATA', value)
  },
  updateVegaConversationStatusData({ commit }, value) {
    commit('UPDATE_VEGA_CONVERSATION_STATUS_DATA', value)
  },
  updateVegaVisitorAnalyticsData({ commit }, value) {
    commit('UPDATE_VEGA_VISITOR_ANALYTICS_DATA', value)
  },
  updateVegaVisitorsCountPerDayData({ commit }, value) {
    commit('UPDATE_VEGA_VISITORS_COUNT_PER_DAY_DATA', value)
  },
  updateVegaFilledContactFormStatusData({ commit }, value) {
    commit('UPDATE_VEGA_FILLED_CONTACT_FORM_STATUS_DATA', value)
  },
  updateVegaScoresAnalyticsData({ commit }, value) {
    commit('UPDATE_VEGA_SCORES_ANALYTICS_DATA', value)
  },
  updateVegaCampaignsData({ commit }, value) {
    commit('UPDATE_VEGA_CAMPAIGNS_DATA', value)
  },
  updateVisitorsRatingBuyingIntent({ commit }, value) {
    commit('UPDATE_VISITORS_RATING_BUYING_INTENT', value)
  },
  updateVegaDataFetched({ commit }, value) {
    commit('UPDATE_VEGA_DATA_FETCHED', value)
  },
  updateActiveChartViewRangeOnAnalyticsSummary({ commit }, value) {
    commit('UPDATE_ACTIVE_CHART_VIEW_RANGE_ON_ANALYTICS_SUMMARY', value)
  },
  updateIsCollapsedVisitorAnalyticsBlock({ commit }, value) {
    commit('UPDATE_IS_COLLAPSED_VISITOR_ANALYTICS_BLOCK', value)
  },
  updateIsCollapsedWebsiteAnalyticsBlock({ commit }, value) {
    commit('UPDATE_IS_COLLAPSED_WEBSITE_ANALYTICS_BLOCK', value)
  },
  updateIsCollapsedCampaignsBlock({ commit }, value) {
    commit('UPDATE_IS_COLLAPSED_CAMPAIGNS_BLOCK', value)
  },
  updateIsCollapsedCommunicationAnalyticsBlock({ commit }, value) {
    commit('UPDATE_IS_COLLAPSED_COMMUNICATION_ANALYTICS_BLOCK', value)
  },
  updateIsFetchedVisitorAnalyticsBlockData({ commit }, value) {
    commit('UPDATE_IS_FETCHED_VISITOR_ANALYTICS_BLOCK_DATA', value)
  },
  updateIsFetchedWebsiteAnalyticsBlockData({ commit }, value) {
    commit('UPDATE_IS_FETCHED_WEBSITE_ANALYTICS_BLOCK_DATA', value)
  },
  updateIsFetchedCampaignsBlockData({ commit }, value) {
    commit('UPDATE_IS_FETCHED_CAMPAIGNS_BLOCK_DATA', value)
  },
  updateIsFetchedCommunicationAnalyticsBlockData({ commit }, value) {
    commit('UPDATE_IS_FETCHED_COMMUNICATION_ANALYTICS_BLOCK_DATA', value)
  },
  updateSelectedVegaUserFlow({ commit }, value) {
    commit('UPDATE_SELECTED_VEGA_USER_FLOW', value)
  },
  resetDashboardStore({ commit }, value) {
    commit('RESET_DASHBOARD_STORE', value)
  }
}
