const state = {
  selectedGptChatbotName: '',
  selectedGptWidgetId: -1,
  selectedGptWidget: null,
  consultation: 'all',
  qualifiedPercent: 0,
  gptSpeechBubbleText: '',
  gptBotWelcomeMessage: '',
  botWidgetMainColor: '#3034F7',
  botWidgetSecondColor: '#275D73',
  botWidgetRequestOptionsButtonColor: '#275D73',
  botWidgetRequestOptionsIconColor: '#ffffff',
  totalTestCount: 0,
  currentDataSource: 'file',
  gptIsProcessing: false,
  gptCreatedSuccessfully: false,
  gptIsFilesUploading: false,
  showMollieGpt: false,
  selectedGptPlan: null,
  gptSubscription: null,
  gptLinks: [],
  hasFilesUpdated: false,
  gptPrompt: null
}

const getters = {
  selectedGptChatbotName: (state) => state.selectedGptChatbotName,
  selectedGptWidgetId: (state) => state.selectedGptWidgetId,
  selectedGptWidget: (state) => state.selectedGptWidget,
  consultation: (state) => state.consultation,
  qualifiedPercent: (state) => state.qualifiedPercent,
  gptSpeechBubbleText: (state) => state.gptSpeechBubbleText,
  gptBotWelcomeMessage: (state) => state.gptBotWelcomeMessage,
  botWidgetMainColor: (state) => state.botWidgetMainColor,
  botWidgetSecondColor: (state) => state.botWidgetSecondColor,
  botWidgetRequestOptionsButtonColor: (state) => state.botWidgetRequestOptionsButtonColor,
  botWidgetRequestOptionsIconColor: (state) => state.botWidgetRequestOptionsIconColor,
  currentDataSource: (state) => state.currentDataSource,
  totalTestCount: (state) => (state.totalTestCount ? state.totalTestCount : 0),
  gptIsProcessing: (state) => state.gptIsProcessing,
  gptCreatedSuccessfully: (state) => state.gptCreatedSuccessfully,
  gptIsFilesUploading: (state) => state.gptIsFilesUploading,
  showMollieGpt: (state) => state.showMollieGpt,
  selectedGptPlan: (state) => state.selectedGptPlan,
  gptSubscription: (state) => state.gptSubscription,
  gptLinks: (state) => state.gptLinks,
  hasFilesUpdated: (state) => state.hasFilesUpdated,
  gptPrompt: (state) => state.gptPrompt
}

const mutations = {
  SET_GPT_SUBSCRIPTION(state, val) {
    state.gptSubscription = val
  },

  SET_GPT_LINKS(state, val) {
    state.gptLinks = val
  },

  SET_SELECTED_GPT_PLAN(state, val) {
    state.selectedGptPlan = val
  },

  SET_SHOW_MOLLIE_GPT(state, val) {
    state.showMollieGpt = val
  },

  SET_GPT_FILES_UPLOADING(state, val) {
    state.gptIsFilesUploading = val
  },

  SET_GPT_CREATED(state, val) {
    state.gptCreatedSuccessfully = val
  },

  SET_GPT_PROCESSING(state, val) {
    state.gptIsProcessing = val
  },

  SET_TOTAL_TEST_COUNT(state, val) {
    state.totalTestCount = val
  },

  SET_QUALIFIED_PERCENT(state, val) {
    state.qualifiedPercent = val
  },

  SET_CONSULTATION(state, val) {
    state.consultation = val
  },
  SET_SELECTED_GPT_CHATBOT_NAME(state, val) {
    state.selectedGptChatbotName = val
  },

  SET_SELECTED_GPT_WIDGET_ID(state, val) {
    state.selectedGptWidgetId = val
  },

  SET_SELECTED_GPT_WIDGET(state, val) {
    state.selectedGptWidget = val
  },

  SET_GPT_SPEECH_BUBBLE_TEXT(state, val) {
    state.gptSpeechBubbleText = val
  },

  SET_GPT_BOT_WELCOME_MESSAGE(state, val) {
    state.gptBotWelcomeMessage = val
  },

  SET_BOT_WIDGET_MAIN_COLOR(state, val) {
    state.botWidgetMainColor = val
  },

  SET_BOT_WIDGET_SECOND_COLOR(state, val) {
    state.botWidgetSecondColor = val
  },

  SET_CURRENT_DATA_SOURCE(state, val) {
    state.currentDataSource = val
  },

  SET_BOT_WIDGET_REQUEST_OPTIONS_BUTTON_COLOR(state, val) {
    state.botWidgetRequestOptionsButtonColor = val
  },

  SET_BOT_WIDGET_REQUEST_OPTIONS_ICON_COLOR(state, val) {
    state.botWidgetRequestOptionsIconColor = val
  },

  SET_HAS_FILES_UPDATED(state, val) {
    state.hasFilesUpdated = val
  },

  SET_GPT_PROMPT(state, val) {
    state.gptPrompt = val
  },

  SET_CONFIG_OPTIONS_TO_DEFAULT(state) {
    state.selectedGptChatbotName = ''
    state.selectedGptWidgetId = -1
    state.selectedGptWidget = null
    state.consultation = 'all'
    state.qualifiedPercent = 0
    state.gptSpeechBubbleText = ''
    state.gptBotWelcomeMessage = ''
    state.botWidgetMainColor = '#3034F7'
    state.botWidgetSecondColor = '#275D73'
    state.botWidgetRequestOptionsButtonColor = '#275D73'
    state.botWidgetRequestOptionsIconColor = '#ffffff'
    state.currentDataSource = 'file'
    state.gptIsProcessing = false
    state.gptCreatedSuccessfully = false
    state.gptIsFilesUploading = false
    state.gptLinks = []
    state.hasFilesUpdated = false
    state.gptPrompt = null
  }
}

export const gpt = {
  namespaced: true,
  state,
  getters,
  mutations
}
