var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 64 71",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M55.3333 32.0034H8.66667C4.98477 32.0034 2 34.9882 2 38.6701V62.0034C2 65.6853 4.98477 68.6701 8.66667 68.6701H55.3333C59.0152 68.6701 62 65.6853 62 62.0034V38.6701C62 34.9882 59.0152 32.0034 55.3333 32.0034Z",
          stroke: _vm.color,
          "stroke-width": "3",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15.3203 32V18.6667C15.3203 14.2464 17.0763 10.0072 20.2019 6.88155C23.3275 3.75595 27.5667 2 31.987 2C36.4073 2 40.6465 3.75595 43.7721 6.88155C46.8977 10.0072 48.6536 14.2464 48.6536 18.6667V32",
          stroke: _vm.color,
          "stroke-width": "3",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }