const state = {
  selectedWidgetTranslation: {},
  selectedGptConnectTranslation: {},
  hasWidgetTranslationUpdated: false,
  hasGptConnectWidgetTranslationUpdated: false,
  selectedLanguageForWebConnect: { code: 'en', lnFullText: 'English', lnText: 'ENG' },
  selectedLanguageForGptConnect: { code: 'en', lnFullText: 'English', lnText: 'ENG' },
  hasLoadedWidgetTranslation: null,
  hasLoadedGptWidgetTranslation: null
}

const getters = {
  selectedWidgetTranslation: (state) => state.selectedWidgetTranslation,
  selectedGptConnectTranslation: (state) => state.selectedGptConnectTranslation,
  hasWidgetTranslationUpdated: (state) => state.hasWidgetTranslationUpdated,
  hasGptConnectWidgetTranslationUpdated: (state) => state.hasGptConnectWidgetTranslationUpdated,
  selectedLanguageForWebConnect: (state) => state.selectedLanguageForWebConnect,
  selectedLanguageForGptConnect: (state) => state.selectedLanguageForGptConnect,
  hasLoadedWidgetTranslation: (state) => state.hasLoadedWidgetTranslation,
  hasLoadedGptWidgetTranslation: (state) => state.hasLoadedGptWidgetTranslation
}

const mutations = {
  SET_WIDGET_TRANSLATION: (state, payload) => {
    state.selectedWidgetTranslation = payload
  },
  SET_GPTCONNECT_WIDGET_TRANSLATION: (state, payload) => {
    state.selectedGptConnectTranslation = payload
  },
  UPDATE_WIDGET_TRANSLATION(state, payload) {
    Object.assign(state.selectedWidgetTranslation, payload)
  },
  UPDATE_GPTCONNECT_WIDGET_TRANSLATION(state, payload) {
    Object.assign(state.selectedGptConnectTranslation, payload)
  },
  SET_HAS_WIDGET_TRANSLATION_UPDATED: (state, payload) => {
    state.hasWidgetTranslationUpdated = payload
  },
  SET_HAS_GPTCONNECT_WIDGET_TRANSLATION_UPDATED: (state, payload) => {
    state.hasGptConnectWidgetTranslationUpdated = payload
  },
  SET_DEFAULT_WIDGET_TRANSLATION: (state, payload) => {
    if (payload === 'en') {
      state.selectedWidgetTranslation = {
        vegaMessage: "Do you have any questions & wish live consultation? Let's connect now via:",
        sessionEndScreenDefaultCopy: 'I hope I could help you ...',
        cancelButtonTextDefault: 'No thanks',
        popupTextDefault: 'May I help you further? I would be happy to support you personally and immediately.',
        waitingRoomDefaultMessage:
          '<b>Great to have you here!</b> <br>You are interested in one of our products? Just let us know how we can help you and you will be connected directly with one of our consultants. <br><br><b>Thank you for your patience!</b>',
        automatedChatResponse: 'We are taking care of your request - pls stay tuned',
        textStep2DefaultText:
          '<p><strong>You have questions about our products?</strong></p><p><strong>We are happy to help you!</strong></p><p><br></p><p>How would you prefer to communicate with us?</p>',
        vegaText: 'Hello 👋',
        feedbackFormQuestionSecond: 'Second Feedback Question',
        privacyInformationHtml: 'Privacy Information Text',
        contactFormText:
          'We are sorry, none of our agents are available right now. You are welcome to leave your email and we will contact you as soon as possible or you can easily book a meeting at a time that works for you.',
        sendMessageDefaultCopy: 'We will get back to you as soon as possible',
        feedbackFormQuestionFirst: 'First Feedback Question',
        dialogTitleDefault: "Let's Connect!",
        sessionEndScreenDefaultTitle: 'Thank you!',
        bookingSlotDefaultTitle: 'Thank you for booking a meeting slot!',
        confirmButtonTextDefault: 'Yes gladly',
        feedbackFormQuestionThird: 'Third Feedback Question',
        moreInformation: 'More Information',
        sendMessageDefaultTitle: 'Thank you for your message!',
        bookingSlotDefaultCopy: 'We are looking forward to seeing you!'
      }
    }
    if (payload === 'de') {
      state.selectedWidgetTranslation = {
        vegaMessage: 'Haben Sie Fragen und wünschen eine Live-Beratung? Lassen Sie uns jetzt über folgende Optionen verbinden:',
        sessionEndScreenDefaultCopy: 'Ich hoffe, ich konnte Ihnen helfen ...',
        cancelButtonTextDefault: 'Nein, Danke',
        popupTextDefault: 'Darf ich Ihnen weiter helfen? Gerne unterstütze ich Sie sofort und persönlich.',
        waitingRoomDefaultMessage:
          '<b>Schön, dass du da bist!</b> <br>Du hast Interesse an einem unserer Produkte? Gib uns kurz bescheid, wie wir dir weiterhelfen können und du wirst direkt mit einem unserer Berater verbunden. <br><br><b>DANKE FÜR DEINE GEDULD!</b>',
        automatedChatResponse: 'Wir kümmern uns um Ihr Anliegen - bitte bleiben Sie dran',
        textStep2DefaultText:
          '<p><strong>Du hast Fragen zu unseren Produkten? Wir beraten dich gerne!</strong></p><p><br></p><p>Wie möchtest du am liebsten mit uns kommunizieren?</p>',
        vegaText: 'Hallo 👋',
        feedbackFormQuestionSecond: 'Zweite Feedback-Frage',
        privacyInformationHtml: 'Datenschutz-Informationstext',
        contactFormText:
          'Es tut uns leid, keiner unserer Agenten ist im Moment verfügbar. Sie können gerne Ihre E-Mail hinterlassen und wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen oder Sie können ganz einfach ein Treffen zu einer Zeit buchen, die für Sie passt.',
        sendMessageDefaultCopy: 'Wir werden uns so schnell wie möglich bei Ihnen melden',
        feedbackFormQuestionFirst: 'Erste Feedback-Frage',
        dialogTitleDefault: "Let's Connect!",
        sessionEndScreenDefaultTitle: 'Vielen Dank!',
        bookingSlotDefaultTitle: 'Vielen Dank, dass Sie einen Besprechungsplatz gebucht haben!',
        confirmButtonTextDefault: 'Ja, gerne',
        feedbackFormQuestionThird: 'Dritte Feedback-Frage',
        moreInformation: 'Mehr Informationen',
        sendMessageDefaultTitle: 'Vielen Dank für Ihre Nachricht!',
        bookingSlotDefaultCopy: 'Wir freuen uns auf Sie!'
      }
    }

    if (payload !== 'en' && payload !== 'de') {
      state.selectedWidgetTranslation = {
        vegaMessage: '',
        sessionEndScreenDefaultCopy: '',
        cancelButtonTextDefault: '',
        popupTextDefault: '',
        waitingRoomDefaultMessage: '',
        automatedChatResponse: '',
        textStep2DefaultText: '',
        vegaText: '',
        feedbackFormQuestionSecond: '',
        privacyInformationHtml: '',
        contactFormText: '',
        sendMessageDefaultCopy: '',
        feedbackFormQuestionFirst: '',
        dialogTitleDefault: '',
        sessionEndScreenDefaultTitle: '',
        bookingSlotDefaultTitle: '',
        confirmButtonTextDefault: '',
        feedbackFormQuestionThird: '',
        moreInformation: '',
        sendMessageDefaultTitle: '',
        bookingSlotDefaultCopy: ''
      }
    }
  },
  SET_SELECTED_LANGUAGE_FOR_WEBCONNECT(state, payload) {
    state.selectedLanguageForWebConnect = payload
  },
  SET_SELECTED_LANGUAGE_FOR_GPTCONNECT(state, payload) {
    state.selectedLanguageForGptConnect = payload
  },
  SET_LOADED_WIDGET_TRANSLATION(state, payload) {
    state.hasLoadedWidgetTranslation = payload
  },
  SET_LOADED_GPTCONNECT_WIDGET_TRANSLATION(state, payload) {
    state.hasLoadedGptWidgetTranslation = payload
  },
  SET_DEFAULT_GPTCONNECT_WIDGET_TRANSLATION: (state, payload) => {
    if (payload === 'en') {
      state.selectedGptConnectTranslation = {
        gptSpeechBubbleText: 'Hello 👋 Do you have questions about our products? I am happy to assist you!',
        gptBotWelcomeMessage: "Welcome, I'm {{Bot Name}} - a chatbot to help you out. How can I assist you today?"
      }
    }
    if (payload === 'de') {
      state.selectedGptConnectTranslation = {
        gptSpeechBubbleText: 'Hallo 👋 Haben Sie Fragen zu unseren Produkten? Ich bin Ihnen gerne behilflich!',
        gptBotWelcomeMessage: 'Willkommen, ich bin {{Bot Name}} - ein Chatbot, der dir helfen kann. Wie kann ich dir heute helfen?'
      }
    }

    if (payload !== 'en' && payload !== 'de') {
      state.selectedGptConnectTranslation = {
        gptBotWelcomeMessage: '',
        gptSpeechBubbleText: ''
      }
    }
  }
}

const actions = {
  setWidgetTranslation({ commit }, payload) {
    commit('SET_WIDGET_TRANSLATION', payload)
  },
  setGptConnectWidgetTranslation({ commit }, payload) {
    commit('SET_GPTCONNECT_WIDGET_TRANSLATION', payload)
  },
  updateWidgetTranslation({ commit }, payload) {
    commit('UPDATE_WIDGET_TRANSLATION', payload)
  },
  updateGptConnectWidgetTranslation({ commit }, payload) {
    commit('UPDATE_GPTCONNECT_WIDGET_TRANSLATION', payload)
  },
  setHasWidgetTranslationUpdated({ commit }, payload) {
    commit('SET_HAS_WIDGET_TRANSLATION_UPDATED', payload)
  },
  setHasGptConnectWidgetTranslationUpdated({ commit }, payload) {
    commit('SET_HAS_GPTCONNECT_WIDGET_TRANSLATION_UPDATED', payload)
  },
  setDefaultWidgetTranslation({ commit }, payload) {
    commit('SET_DEFAULT_WIDGET_TRANSLATION', payload)
  },
  setDefaultGptConnectWidgetTranslation({ commit }, payload) {
    commit('SET_DEFAULT_GPTCONNECT_WIDGET_TRANSLATION', payload)
  },
  setSelectedLanguageForWebConnect({ commit }, payload) {
    commit('SET_SELECTED_LANGUAGE_FOR_WEBCONNECT', payload)
  },
  setSelectedLanguageForGptConnect({ commit }, payload) {
    commit('SET_SELECTED_LANGUAGE_FOR_GPTCONNECT', payload)
  },
  setLoadedWidgetTranslation({ commit }, payload) {
    commit('SET_LOADED_WIDGET_TRANSLATION', payload)
  },
  setLoadedGptConnectWidgetTranslation({ commit }, payload) {
    commit('SET_LOADED_GPTCONNECT_WIDGET_TRANSLATION', payload)
  }
}

export const widgetTranslation = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
