var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "order-details" }, [
    _vm.chosenPlan
      ? _c("div", [
          _c("div", { staticClass: "order-details--heading" }, [
            _vm._v(_vm._s(_vm.$t("choosePricingPlan.orderDetails"))),
          ]),
          _c("div", { staticClass: "order-details__summary-container" }, [
            _c(
              "div",
              { staticClass: "order-details__summary-container--logo" },
              [_c("Logo")],
              1
            ),
            _c(
              "div",
              { staticClass: "order-details__summary-container__price" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "order-details__summary-container__price--title",
                  },
                  [_vm._v(_vm._s(_vm.planName))]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "order-details__summary-container__price--price",
                  },
                  [_vm._v(_vm._s(_vm.totalAmountWithSymbol))]
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "order-details__price_container" }, [
            _c("div", { staticClass: "order-details__price" }, [
              _c("div", { staticClass: "order-details__price--title" }, [
                _vm._v(_vm._s(_vm.$t("choosePricingPlan.subtotal"))),
              ]),
              _c("div", { staticClass: "order-details__price--amount" }, [
                _vm._v(_vm._s(_vm.planPriceWithSymbol)),
              ]),
            ]),
            _c("div", { staticClass: "order-details__price" }, [
              _c("div", { staticClass: "order-details__price--title" }, [
                _vm._v(
                  _vm._s(_vm.$t("choosePricingPlan.vat")) +
                    " " +
                    _vm._s(_vm.vatPercent) +
                    "%"
                ),
              ]),
              _c("div", { staticClass: "order-details__price--amount" }, [
                _vm._v(_vm._s(_vm.vatAmountWithSymbol)),
              ]),
            ]),
            _c("div", { staticClass: "order-details__total" }, [
              _c("div", { staticClass: "order-details__total--title" }, [
                _vm._v(_vm._s(_vm.$t("choosePricingPlan.total"))),
              ]),
              _c("div", { staticClass: "order-details__total--amount" }, [
                _vm._v(_vm._s(_vm.totalAmountWithSymbol)),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }