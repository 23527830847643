<template>
  <div class="lc-choose-pricing-plan">
    <PaymentFailed v-if="showPaymentFailedUI" />
    <!-- LOGO -->
    <div class="lc-choose-pricing-plan__logo">
      <img src="@/assets/images/register-funnel/vega_logo.svg" alt="Logo" class="lc-choose-pricing-plan-detail__logo-img" />
    </div>

    <div class="lc-choose-pricing-plan-close" @click="onClose">
      <div class="lc-choose-pricing-plan-close-icon"></div>
    </div>

    <div class="lc-choose-pricing-plan-detail" :class="{ 'lc-choose-pricing-plan-detail--full': showMollieVega }">
      <!-- DETAIL SECTION -->
      <div class="lc-choose-pricing-plan-detail-section">
        <div v-if="newUidFlowStep > 1" class="lc-choose-pricing-plan-detail-section--back" @click="onUidFlowStepBack">
          <div class="lc-choose-pricing-plan-detail-section--back-icon">
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.18329 0.842714L0.815366 6.67612C0.604004 6.90217 0.5 7.20113 0.5 7.50009C0.5 7.79905 0.604842 8.09729 0.814527 8.32479L6.18245 14.1582C6.60182 14.6139 7.2812 14.6139 7.70057 14.1582C8.11994 13.7025 8.11994 12.9642 7.70057 12.5084L4.16361 8.66677H14.4566C15.0501 8.66677 15.5 8.14432 15.5 7.5329C15.5 6.92149 15.0501 6.33341 14.4566 6.33341H4.16361L7.69973 2.49065C8.1191 2.03492 8.1191 1.29663 7.69973 0.840891C7.28036 0.385156 6.60266 0.386979 6.18329 0.842714Z"
                fill="#878893"
              />
            </svg>
          </div>
          <div class="lc-choose-pricing-plan-detail-section--back-text">{{ $t('vue.back') }}</div>
        </div>

        <div class="lc-choose-pricing-plan-detail__progress-bar-wrapper">
          <div
            class="lc-choose-pricing-plan-detail__progress-bar"
            :style="{
              width: `${(newUidFlowStep / totalCountOfSteps) * 100}%`
            }"
          />
        </div>
        <div class="w-full">
          <ChooseCountry @go-to-next-step="onUidFlowStepForward" v-show="newUidFlowStep === 1" />
          <CompanyDetails @go-to-next-step="onUidFlowStepForward" v-show="newUidFlowStep === 2" />
          <PaymentDetails v-show="newUidFlowStep === 3" />
        </div>
      </div>
    </div>
    <div v-if="!showMollieVega && !showMollieGpt" class="lc-choose-pricing-plan-order">
      <OrderDetails />
    </div>
    <div v-if="showMollieGpt" class="lc-choose-pricing-plan-order">
      <OrderDetailsGpt />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

import ChooseCountry from './ChooseCountry.vue'
import CompanyDetails from './CompanyDetails.vue'
import PaymentDetails from './PaymentDetails.vue'
import OrderDetails from './OrderDetails.vue'
import OrderDetailsGpt from './OrderDetailsGpt.vue'
import PaymentFailed from './PaymentFailed.vue'

export default {
  name: 'ChoosePricingPlan',
  components: {
    ChooseCountry,
    CompanyDetails,
    PaymentDetails,
    OrderDetails,
    OrderDetailsGpt,
    PaymentFailed
  },
  data() {
    return {
      type: 'increment',
      totalCountOfSteps: 3
    }
  },
  computed: {
    ...mapGetters({
      newUidFlowStep: 'newUidFlowStep',
      showPaymentFailedUI: 'showPaymentFailedUI',
      showMollieVega: 'showMollieVega',
      showMollieGpt: 'gpt/showMollieGpt'
    })
  },
  mounted() {
    /* Vega Funnel Paying Process */
    if (this.showMollieVega) {
      this.$serverBus.$emit('vega-funnel-paying-process', `choose-country step(${this.newUidFlowStep})`)
    }
  },
  methods: {
    ...mapMutations({
      updateShowChangePlanUI: 'UPDATE_SHOW_CHANGE_PLAN_UI',
      updateAppliedPromocode: 'UPDATE_APPLIED_PROMOCODE',
      setNewUidFlowStep: 'SET_NEW_UID_FLOW_STEP',
      setShowMollieVega: 'SET_SHOW_MOLLIE_VEGA',
      setShowMollieGpt: 'gpt/SET_SHOW_MOLLIE_GPT',
      setSelectedGptPlan: 'gpt/SET_SELECTED_GPT_PLAN',
      setSelectedBillingCountry: 'SET_SELECTED_BILLING_COUNTRY'
    }),
    onRecordVegaFunnelPayingProcess(closed) {
      const closedPaymentProcess = closed ? 'closed-payment-process: ' : ''
      /* Vega Funnel Paying Process */
      if (this.showMollieVega) {
        switch (this.newUidFlowStep) {
          case 1:
            this.$serverBus.$emit('vega-funnel-paying-process', `${closedPaymentProcess}choose-country step(${this.newUidFlowStep})`)
            break

          case 2:
            this.$serverBus.$emit('vega-funnel-paying-process', `${closedPaymentProcess}company-details step(${this.newUidFlowStep})`)
            break

          case 3:
            this.$serverBus.$emit('vega-funnel-paying-process', `${closedPaymentProcess}choose-payment-method step(${this.newUidFlowStep})`)
            break

          default:
            break
        }
      }
    },
    onClose() {
      this.onRecordVegaFunnelPayingProcess(true)
      this.setNewUidFlowStep(1)
      this.updateAppliedPromocode(null)
      this.updateShowChangePlanUI(false)
      this.setSelectedGptPlan(null)
      this.setSelectedBillingCountry(null)
      this.setShowMollieVega(false)
      this.setShowMollieGpt(false)
    },
    onUidFlowStepBack() {
      this.setNewUidFlowStep(this.newUidFlowStep - 1)
      this.onRecordVegaFunnelPayingProcess()
    },
    onUidFlowStepForward() {
      this.setNewUidFlowStep(this.newUidFlowStep + 1)
      this.onRecordVegaFunnelPayingProcess()
    }
  }
}
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.lc-choose-pricing-plan {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: calc(100vh);
  max-height: 100%;
  z-index: 70000 !important;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  overflow-x: hidden;

  &-order {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 50px;
  }

  &__logo {
    position: absolute;
    left: 10px;
    top: 10px;

    &-img {
      width: 150px;
    }
  }

  &-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    width: 20px;
    height: 20px;

    &-icon {
      opacity: 0.7;
      background-color: #262629;
      position: absolute;
      right: 10px;
    }
    &-icon:hover {
      opacity: 1;
    }
    &-icon:before,
    &-icon:after {
      position: absolute;
      content: ' ';
      height: 20px;
      width: 2px;
      background-color: #262629;
    }

    &-icon:before {
      transform: rotate(45deg);
    }
    &-icon:after {
      transform: rotate(-45deg);
    }
  }

  &-detail {
    width: 68%;
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &--full {
      width: 100%;
    }

    &-section {
      max-width: 503px;
      width: 100%;
      height: 100%;
      overflow-y: auto;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &--back {
        display: flex;
        align-self: flex-start;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 17px;
        cursor: pointer;
        margin-top: 65px;

        &-icon {
          top: -10px;
        }

        &-text {
          margin-left: 7px;
          font-family: 'Larsseit';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 18px;
          letter-spacing: -0.1px;
          color: #878893;
        }
      }
    }

    &__progress-bar {
      width: 0px;
      height: 100%;
      background: #82ceff;
      border-radius: 2.5px;
      transition: width 0.2s;

      &-wrapper {
        width: 100%;
        height: 5px;
        background: #eff1f3;
        border-radius: 2.5px;
        overflow: hidden;
      }
    }

    &__logo-img {
      max-width: 140px;
    }
  }
  &-order {
    width: 32%;
    background: #f5fafd;
  }

  @media only screen and (max-width: 800px) {
    &-detail {
      width: 100%;
    }

    &-order {
      display: none;
    }
  }
}
</style>
