const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

import { mapGetters, mapMutations } from 'vuex'

export const mtranslations = {
  data() {
    return {
      infoImage: require('@/assets/images/elements/info.svg'),
      languages: require('@/assets/languages.json').map((x) => {
        return {
          lnFullText: x.language,
          code: x.code,
          lnText: x.threeLetterCode,
          selected: false
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      currentUserInfo: 'activeUser',
      translations: 'languages/translations',
      myDialogs: 'dialogs',
      myCampaigns: 'campaigns',
      tscroll: 'languages/tscroll',
      default_en: 'languages/en',
      default_de: 'languages/de',
      currentLanguage: 'languages/currentLanguage',
      currentTranslationTab: 'languages/currentTab',
      showWidgetSelection: 'languages/showWidgetSelection',
      showGptWidgetSelection: 'languages/showGptWidgetSelection',
      selectedWidget: 'languages/selectedWidget',
      showQRWidgetSelection: 'languages/showQRWidgetSelection',
      selectedQRWidget: 'languages/selectedQRWidget',
      selectedGptWidget: 'languages/selectedGptWidget',
      translationUpdateKey: 'languages/translationUpdateKey',
      defaultTranslations: 'languages/defaultTranslations',
      selectedWidgetSavedTranslation: 'languages/selectedWidgetSavedTranslation',
      selectedGptWidgetSavedTranslation: 'languages/selectedGptWidgetSavedTranslation',
      selectedQRWidgetSavedTranslation: 'languages/selectedQRWidgetSavedTranslation'
    }),

    _translation() {
      if (!this.translations) {
        return null
      }
      const language = this.currentLanguage || 'en'
      const translation = this.translations.find((x) => x.ln === language)
      return translation || Object.assign({}, JSON.parse(JSON.stringify(this.default_en)))
    }
  },

  methods: {
    ...mapMutations({
      updateScrollListener: 'languages/UPDATE_SCROLL_LISTENER',
      addTranslation: 'languages/ADD_TRANSLATION',
      removeTranslation: 'languages/REMOVE_TRANSLATION',
      updateTranslation: 'languages/UPDATE_TRANSLATION',
      loadTranslation: 'languages/LOAD_TRANSLATION',
      updateField: 'languages/UPDATE_FIELD',
      updateCurrentLanguage: 'languages/UPDATE_CURRENT_LANGUAGE',
      updateDefaultLanguageEN: 'languages/UPDATE_DEFAULT_LANGUAGE_EN',
      updateDefaultLanguageDE: 'languages/UPDATE_DEFAULT_LANGUAGE_DE',
      updateShowWidgetSelection: 'languages/UPDATE_SHOW_WIDGET_SELECTION',
      updateSelectedWidget: 'languages/UPDATE_SELECTED_WIDGET',
      updateShowGptWidgetSelection: 'languages/UPDATE_SHOW_GPT_WIDGET_SELECTION',
      updateSelectedGptWidget: 'languages/UPDATE_SELECTED_GPT_WIDGET',
      updateShowQRWidgetSelection: 'languages/UPDATE_SHOW_QR_WIDGET_SELECTION',
      updateSelectedQRWidget: 'languages/UPDATE_SELECTED_QR_WIDGET',
      updateSelectedWidgetSavedTranslation: 'languages/UPDATE_SELECTED_WIDGET_SAVED_TRANSLATION',
      updateSelectedGptWidgetSavedTranslation: 'languages/UPDATE_SELECTED_GPT_WIDGET_SAVED_TRANSLATION',
      updateSelectedQRWidgetSavedTranslation: 'languages/UPDATE_SELECTED_QR_WIDGET_SAVED_TRANSLATION'
    }),
    $tp(key) {
      if (!this._translation) return this.$i18n.t(key, 'en')

      return this.resolve_ln(key) || this.$i18n.t(key, 'en')
    },
    resolve_ln(path) {
      try {
        return path.split('.').reduce(function (prev, curr) {
          return prev ? prev[curr] : null
        }, this._translation || self)
      } catch (error) {
        return null
      }
    },

    async getDefaultAppLanguages() {
      /* Get default language from database */
      const enDoc = await this.$db.collection('settings').doc('languages').collection('embed').doc('en').get()
      if (enDoc && enDoc.data()) {
        const data = enDoc.data()
        data.ln = 'en'
        data.lnText = 'ENG'
        data.lnFullText = 'Default'
        data.sort = 0
        this.updateDefaultLanguageEN(data)
      }
      const deDoc = await this.$db.collection('settings').doc('languages').collection('embed').doc('de').get()
      if (deDoc && deDoc.data()) {
        const data = deDoc.data()
        data.ln = 'de'
        data.lnText = 'DEU'
        data.lnFullText = 'German'
        data.sort = 1
        this.updateDefaultLanguageDE(data)
      }
    },

    async getSavedTranslationOfAccount(companyId, language) {
      if (companyId) {
        if (!language) language = 'en'
        const translationRef = await this.$db.collection('translations').doc(companyId).collection('languages').doc(language).get()
        const translation = translationRef.data()
        if (translation) {
          const lng = this.languages.find((x) => x.code === language)
          if (lng) {
            translation.lnText = lng.lnText
            translation.lnFullText = lng.lnFullText
          }
          this.loadTranslation(translation)
        }
      }
    },

    async updateMissingVegaTranslationWebConnect(current, defaultTranslation, language) {
      let shouldUpdate = false
      const keysToCheck = ['vegaText', 'vegaMessage']

      keysToCheck.forEach((key) => {
        if (!current.webConnect[key]) {
          current.webConnect[key] = defaultTranslation.webConnect[key]
          shouldUpdate = true
        }
      })

      const vega = {
        text: current.webConnect.vegaText,
        message: current.webConnect.vegaMessage
      }

      if (shouldUpdate && current.widgetId && language) {
        if (language === 'en') {
          await this.$db.collection('dialogs').doc(current.widgetId).set({ vega, dialogUpdated: dayjs().utc().toDate() }, { merge: true })
        } else {
          await this.$db
            .collection('translations')
            .doc(this.currentUserInfo.company)
            .collection('web-connect')
            .doc(current.widgetId)
            .collection('languages')
            .doc(language)
            .set(current, { merge: true })
        }
      }
    },

    async getSavedWebConnectTranslation() {
      const webconnect_translations = []
      const languagesRef = await this.$db
        .collection('translations')
        .doc(this.currentUserInfo.company)
        .collection('web-connect')
        .doc(this.selectedWidget)
        .collection('languages')
        .get()

      languagesRef.forEach(async (doc) => {
        const language = doc.data()
        language.ln = doc.id
        const lng = this.languages.find((x) => x.code === doc.id)
        if (lng) {
          language.lnText = lng.lnText
          language.lnFullText = lng.lnFullText
        }
        if (doc.id === 'en') {
          const obj = {
            webConnect: language.webConnect,
            widgetId: this.selectedWidget
          }
          await this.updateMissingVegaTranslationWebConnect(obj, this.default_en, 'en')
          this.updateSelectedWidgetSavedTranslation(obj)
        }

        if (doc.id === 'de') {
          const obj = {
            webConnect: language.webConnect,
            widgetId: this.selectedWidget
          }
          await this.updateMissingVegaTranslationWebConnect(obj, this.default_de, 'de')
        }

        /* Check if translation exists in main array */
        const exists = this.translations.find((x) => x.ln === language.ln)
        if (!exists) {
          const default_lang = this.default_en.webConnect
          if (default_lang) {
            for (const prop in default_lang) {
              if (language.webConnect && !Object.prototype.hasOwnProperty.call(language.webConnect, prop)) {
                language.webConnect[prop] = ''
              }
            }
          }
          this.loadTranslation(language)
        }

        webconnect_translations.push(language)
      })

      for (const translation of this.translations) {
        const default_lang = translation.ln === 'de' ? JSON.parse(JSON.stringify(this.default_de)) : JSON.parse(JSON.stringify(this.default_en))

        const wt = webconnect_translations.find((x) => x.ln === translation.ln)
        if (wt) {
          translation.webConnect = wt.webConnect
          if (default_lang && default_lang.webConnect) {
            // if (!translation.webConnect || Object.keys(translation.webConnect).length === 0) {
            //   translation.webConnect = {}
            // }
            for (const prop in default_lang.webConnect) {
              if (translation.webConnect && !Object.prototype.hasOwnProperty.call(translation.webConnect, prop)) {
                translation.webConnect[prop] = ''
              }
            }
          }
        } else if (translation.canBeDeleted) {
          translation.webConnect = null
        } else {
          const dialogRef = await this.$db.collection('dialogs').doc(this.selectedWidget).get()
          if (dialogRef && dialogRef.data()) {
            const dialog = dialogRef.data()
            const webConnect = {}
            webConnect.textStep2DefaultText = dialog.textStep2
            webConnect.waitingRoomDefaultMessage = dialog.waitingRoomMessage
            webConnect.contactFormText = dialog.contactForm && dialog.contactForm.message ? dialog.contactForm.message : ''
            webConnect.bookingSlotDefaultCopy = dialog.contactForm && dialog.contactForm.bookingSlotCopy ? dialog.contactForm.bookingSlotCopy : ''
            webConnect.bookingSlotDefaultTitle = dialog.contactForm && dialog.contactForm.bookingSlotTitle ? dialog.contactForm.bookingSlotTitle : ''
            webConnect.sendMessageDefaultCopy = dialog.contactForm && dialog.contactForm.sendMessageCopy ? dialog.contactForm.sendMessageCopy : ''
            webConnect.sendMessageDefaultTitle = dialog.contactForm && dialog.contactForm.sendMessageTitle ? dialog.contactForm.sendMessageTitle : ''
            webConnect.cancelButtonTextDefault = dialog.cancelButtonText
            webConnect.confirmButtonTextDefault = dialog.confirmButtonText
            webConnect.dialogTitleDefault = dialog.sloganTitle
            webConnect.feedbackFormQuestionFirst = dialog.feedbackFormQuestionFirst || null
            webConnect.feedbackFormQuestionSecond = dialog.feedbackFormQuestionSecond || null
            webConnect.feedbackFormQuestionThird = dialog.feedbackFormQuestionThird || null
            webConnect.moreInformation = dialog.moreInformationButtonText
            webConnect.popupTextDefault = dialog.text
            webConnect.sessionEndScreenDefaultCopy =
              dialog.sessionEndScreen && dialog.sessionEndScreen.copyClosingMessage ? dialog.sessionEndScreen.copyClosingMessage : ''
            webConnect.sessionEndScreenDefaultTitle =
              dialog.sessionEndScreen && dialog.sessionEndScreen.titleClosingMessage ? dialog.sessionEndScreen.titleClosingMessage : ''

            webConnect.privacyInformationHtml = dialog.contactForm && dialog.contactForm.privacyInformationHtml ? dialog.contactForm.privacyInformationHtml : ''
            webConnect.vegaText = dialog.vega && dialog.vega.text ? dialog.vega.text : ''
            webConnect.vegaMessage = dialog.vega && dialog.vega.message ? dialog.vega.message : ''
            webConnect.automatedChatResponse = dialog.automatedChatResponse || null

            translation.webConnect = webConnect
          } else {
            translation.webConnect = JSON.parse(JSON.stringify(default_lang.webConnect))
          }
        }

        if (webconnect_translations.filter((x) => x.ln === 'en').length === 0 && translation.ln === 'en' && this.myDialogs && this.myDialogs.length > 0) {
          const dialog = this.myDialogs.find((x) => x.id === this.selectedWidget)

          if (translation.webConnect && dialog && dialog.moreInformationButtonText) {
            translation.webConnect.moreInformation = dialog.moreInformationButtonText
          }

          if (translation.webConnect && dialog && dialog.feedbackFormQuestionFirst) {
            translation.webConnect.feedbackFormQuestionFirst = dialog.feedbackFormQuestionFirst
          }
          if (translation.webConnect && dialog && dialog.feedbackFormQuestionSecond) {
            translation.webConnect.feedbackFormQuestionSecond = dialog.feedbackFormQuestionSecond
          }
          if (translation.webConnect && dialog && dialog.feedbackFormQuestionThird) {
            translation.webConnect.feedbackFormQuestionThird = dialog.feedbackFormQuestionThird
          }

          if (translation.webConnect && dialog && dialog.automatedChatResponse) {
            translation.webConnect.automatedChatResponse = dialog.automatedChatResponse
          }
        }

        this.loadTranslation(translation)
      }
    },

    async getSavedGptConnectTranslation() {
      const gptconnect_translations = []
      const languagesRef = await this.$db
        .collection('translations')
        .doc(this.currentUserInfo.company)
        .collection('gpt-connect')
        .doc(this.selectedGptWidget)
        .collection('languages')
        .get()

      languagesRef.forEach(async (doc) => {
        const language = doc.data()
        language.ln = doc.id
        const lng = this.languages.find((x) => x.code === doc.id)
        if (lng) {
          language.lnText = lng.lnText
          language.lnFullText = lng.lnFullText
        }
        if (doc.id === 'en') {
          const obj = {
            gptConnect: language.gptConnect,
            widgetId: this.selectedGptWidget
          }
          this.updateSelectedGptWidgetSavedTranslation(obj)
        }

        /* Check if translation exists in main array */
        const exists = this.translations.find((x) => x.ln === language.ln)
        if (!exists) {
          const default_lang = this.default_en.gptConnect
          if (default_lang) {
            for (const prop in default_lang) {
              if (language.gptConnect && !Object.prototype.hasOwnProperty.call(language.gptConnect, prop)) {
                language.gptConnect[prop] = ''
              }
            }
          }
          this.loadTranslation(language)
        }

        gptconnect_translations.push(language)
      })

      for (const translation of this.translations) {
        const default_lang = translation.ln === 'de' ? JSON.parse(JSON.stringify(this.default_de)) : JSON.parse(JSON.stringify(this.default_en))

        const wt = gptconnect_translations.find((x) => x.ln === translation.ln)
        if (wt) {
          translation.gptConnect = wt.gptConnect
          if (default_lang && default_lang.gptConnect) {
            // if (!translation.gptConnect || Object.keys(translation.gptConnect).length === 0) {
            //   translation.gptConnect = {}
            // }
            for (const prop in default_lang.gptConnect) {
              if (translation.gptConnect && !Object.prototype.hasOwnProperty.call(translation.gptConnect, prop)) {
                translation.gptConnect[prop] = ''
              }
            }
          }
        } else if (translation.canBeDeleted) {
          translation.gptConnect = null
        } else {
          const dialogRef = await this.$db.collection('dialogs').doc(this.selectedGptWidget).get()
          if (dialogRef && dialogRef.data()) {
            const dialog = dialogRef.data()
            const gptConnect = {}
            gptConnect.gptSpeechBubbleText = dialog.gpt && dialog.gpt.speechBubbleText ? dialog.gpt.speechBubbleText : ''
            gptConnect.gptBotWelcomeMessage = dialog.gpt && dialog.gpt.botWelcomeMessage ? dialog.gpt.botWelcomeMessage : ''

            translation.gptConnect = gptConnect
          }
        }

        this.loadTranslation(translation)
      }
    },

    async getSavedQRConnectTranslation() {
      const qrconnect_translations = []

      const languagesRef = await this.$db
        .collection('translations')
        .doc(this.currentUserInfo.company)
        .collection('qr-connect')
        .doc(this.selectedQRWidget)
        .collection('languages')
        .get()

      languagesRef.forEach((doc) => {
        const language = doc.data()
        language.ln = doc.id
        const lng = this.languages.find((x) => x.code === doc.id)
        if (lng) {
          language.lnText = lng.lnText
          language.lnFullText = lng.lnFullText
        }
        if (doc.id === 'en') {
          const obj = {
            qrConnect: language.qrConnect,
            widgetId: this.selectedQRWidget
          }
          this.updateSelectedQRWidgetSavedTranslation(obj)
        }

        /* Check if translation exists in main array */
        const exists = this.translations.find((x) => x.ln === language.ln)
        if (!exists) {
          const default_lang = this.default_en.qrConnect
          if (default_lang) {
            for (const prop in default_lang) {
              if (language.qrConnect && !Object.prototype.hasOwnProperty.call(language.qrConnect, prop)) {
                language.qrConnect[prop] = ''
              }
            }
          }
          this.loadTranslation(language)
        }

        qrconnect_translations.push(language)
      })

      for (const translation of this.translations) {
        const default_lang = translation.ln === 'de' ? JSON.parse(JSON.stringify(this.default_de)) : JSON.parse(JSON.stringify(this.default_en))

        const wt = qrconnect_translations.find((x) => x.ln === translation.ln)
        if (wt) {
          translation.qrConnect = wt.qrConnect
          if (default_lang && default_lang.qrConnect) {
            // if (!translation.qrConnect || Object.keys(translation.qrConnect).length === 0) {
            //   translation.qrConnect = {}
            // }
            for (const prop in default_lang.qrConnect) {
              if (translation.qrConnect && !Object.prototype.hasOwnProperty.call(translation.qrConnect, prop)) {
                translation.qrConnect[prop] = ''
              }
            }
          }
        } else if (translation.canBeDeleted) {
          translation.qrConnect = null
        } else if (!translation.qrConnect && this.defaultTranslations.includes(translation.ln)) {
          translation.qrConnect = JSON.parse(JSON.stringify(default_lang.qrConnect))
        }

        if (qrconnect_translations.filter((x) => x.ln === 'en').length === 0 && translation.ln === 'en' && this.myCampaigns && this.myCampaigns.length > 0) {
          const dialog = this.myCampaigns.find((x) => x.id === this.selectedQRWidget)

          if (translation.qrConnect && dialog && dialog.moreInformationButtonText) {
            translation.qrConnect.moreInformation = dialog.moreInformationButtonText
          }

          if (translation.qrConnect && dialog && dialog.feedbackFormQuestionFirst) {
            translation.qrConnect.feedbackFormQuestionFirst = dialog.feedbackFormQuestionFirst
          }
          if (translation.qrConnect && dialog && dialog.feedbackFormQuestionSecond) {
            translation.qrConnect.feedbackFormQuestionSecond = dialog.feedbackFormQuestionSecond
          }
          if (translation.qrConnect && dialog && dialog.feedbackFormQuestionThird) {
            translation.qrConnect.feedbackFormQuestionThird = dialog.feedbackFormQuestionThird
          }
        }

        this.loadTranslation(translation)
      }
    }
  }
}
