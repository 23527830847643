export const mpayment = {
  data() {
    return {
      isLoading: false,
      isLoadingInProccess: false,
      loaderSpinnerText: ''
    }
  },
  methods: {
    loadCustomSpinner(enable, text) {
      if (enable) {
        this.loaderSpinnerText = text
        this.isLoading = enable
        this.isLoadingInProccess = enable
      } else {
        this.loaderSpinnerText = ''
        this.isLoading = enable
        this.isLoadingInProccess = enable
      }
    }
  }
}
