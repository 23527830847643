/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import moduleAuth from './auth/moduleAuth.js'
import moduleDashboard from './dashboard/moduleDashboard.js'
import moduleInvoice from './invoice/moduleInvoice.js'
import moduleMeetings from './meetings/moduleMeetings.js'
import moduleAbTests from './abtests/moduleAbTests.js'
import moduleLanguages from './languages/moduleLanguages.js'

import { webrtc } from './modules/webrtc'
import { chatFiles } from './modules/chat-files'
import { campaign } from './modules/campaign'
import { gpt } from './modules/gpt'
import { widgetTranslation } from './modules/widgetTranslation'

Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    dashboard: moduleDashboard,
    invoice: moduleInvoice,
    onlineMeetings: moduleMeetings,
    mAbTests: moduleAbTests,
    languages: moduleLanguages,
    webrtc,
    chatFiles,
    campaign,
    gpt,
    widgetTranslation
  },
  strict: process.env.NODE_ENV !== 'production'
})
