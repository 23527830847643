import { mapGetters, mapMutations } from 'vuex'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export const mvisitors = {
  data() {
    return {
      intervalInMS: 10000,
      maxNoActivityTimeInSeconds: 600,
      maxConnectionTimeInSeconds: 21600,
      graceTimeInSeconds: 10800
    }
  },
  computed: {
    ...mapGetters({
      incoming: 'incoming',
      staticIncoming: 'staticIncoming',
      chatVisitors: 'chatVisitors',
      activeUserInfo: 'activeUser'
    })
  },
  mounted() {
    if (window.startInterval) {
      window.clearInterval(window.startInterval)
    }
    this.checkConnectedVisitors()
    window.startInterval = setInterval(() => {
      this.checkConnectedVisitors()
    }, this.intervalInMS)
  },

  methods: {
    ...mapMutations({
      addChatVisitor: 'ADD_CHAT_VISITOR',
      removeChatVisitor: 'REMOVE_CHAT_VISITOR',
      clearIncomingRequests: 'CLEAR_INCOMING_REQUESTS',
      updateIncomingRequests: 'UPDATE_INCOMING_REQUESTS',
      updateStaticIncoming: 'UPDATE_STATIC_INCOMING',
      updateChatVisitorAgentChatStartDate: 'UPDATE_CHAT_VISITOR_AGENT_CHAT_START_DATE'
    }),

    async declineVisitorCall(id) {
      await this.$db
        .collection('visitors')
        .doc(id)
        .set({ declinedByAgents: this.$firebase.firestore.FieldValue.arrayUnion(this.activeUserInfo.uid) }, { merge: true })
    },

    async checkExistingUnendedCalls() {
      const existingCallsRef = await this.$db
        .collection('visitors')
        .where('company', '==', this.activeUserInfo.company)
        .where('connectedAgentId', '==', this.activeUserInfo.uid)
        .get()
      existingCallsRef.forEach((callRef) => {
        const existingCall = callRef.data()
        existingCall.id = callRef.id

        /* Disconnect unended calls older than 10 mins */
        const diffInMinutes = dayjs().utc().diff(dayjs.unix(existingCall.modified.seconds).utc(), 'second')
        if (diffInMinutes > 600) {
          this.disconnectVisitor(existingCall)
        }
      })
    },

    async disconnectVisitor(visitor) {
      const agentInfo = {
        agentRequest: false,
        declinedByAgents: this.$firebase.firestore.FieldValue.delete(),
        audioPlayed: false,
        end_time: dayjs().utc().toDate(),
        modified: dayjs().utc().toDate(),
        ttlDate: dayjs().utc().add(6, 'month').toDate(),
        connectedAgentId: this.$firebase.firestore.FieldValue.delete(),
        callCenterId: this.$firebase.firestore.FieldValue.delete(),
        callCenterName: this.$firebase.firestore.FieldValue.delete(),
        connectedAgent: this.$firebase.firestore.FieldValue.delete(),
        connectedAgentImage: this.$firebase.firestore.FieldValue.delete(),
        connectedAgentCompany: this.$firebase.firestore.FieldValue.delete(),
        connectedAgentDate: this.$firebase.firestore.FieldValue.delete(),
        connectedAgentChatDate: this.$firebase.firestore.FieldValue.delete(),
        //agentsToBeNotified: this.$firebase.firestore.FieldValue.delete(), DELETE AGENTS TO BE NOTIFIED
        clientSessions: this.$firebase.firestore.FieldValue.delete(),
        callTypeState: 'agent-chat-complete',
        cobrowse: this.$firebase.firestore.FieldValue.delete(),
        surfly: this.$firebase.firestore.FieldValue.delete(),
        agentRequestTypeUpdated: this.$firebase.firestore.FieldValue.delete(),

        sessionId: null,
        isCallStarted: this.$firebase.firestore.FieldValue.delete(),
        visitorMessages: this.$firebase.firestore.FieldValue.delete(),
        agentMessages: this.$firebase.firestore.FieldValue.delete(),
        initiatedBy: this.$firebase.firestore.FieldValue.delete()
      }
      //console.log(`Disconnected Agent after ${this.maxNoActivityTimeInSeconds} seconds of inactivity`)
      await this.$db.collection('visitors').doc(visitor.id).set(agentInfo, { merge: true })
    },

    disconnectAgentFromVisitorChat(visitor) {
      const _visitor = this.incoming.find((x) => x.id === visitor.id)
      if (
        _visitor &&
        _visitor.connectedAgentId &&
        _visitor.agentRequestType === 'chat' &&
        (!_visitor.agentRequestTypeUpdated || !['audio', 'video'].includes(_visitor.agentRequestTypeUpdated))
      ) {
        this.disconnectVisitor(visitor)
      }
      const index = this.chatVisitors.findIndex((x) => x.id === visitor.id)
      if (index > -1) {
        this.removeChatVisitor(index)
      }
    },

    checkConnectedVisitors() {
      for (const visitor of this.chatVisitors) {
        const chatStartDate = dayjs.unix(visitor.agentChatStartDate.seconds).utc()
        const today = dayjs.utc()
        const totalConnectedTimeInSeconds = today.diff(chatStartDate, 'seconds')
        const hasNoActivityTimeExceeded = totalConnectedTimeInSeconds > this.maxNoActivityTimeInSeconds

        this.$db.collection('visitors').doc(visitor.id).set({ connectedAgentChatDate: chatStartDate.toDate() }, { merge: true })

        const incoming = this.incoming.find((x) => x.id === visitor.id)
        if (
          !incoming ||
          !incoming.agentRequestType ||
          !incoming.connectedAgentId ||
          incoming.agentRequestType !== 'chat' ||
          (incoming.agentRequestTypeUpdated && ['audio', 'video'].includes(incoming.agentRequestTypeUpdated))
        ) {
          if (incoming.modified) {
            const chatStartDate = dayjs.unix(incoming.modified.seconds).utc()
            const today = dayjs.utc()
            const totalConnectedTimeInSeconds = today.diff(chatStartDate, 'seconds')
            const maxConnectionTimeInSeconds = !incoming.online ? this.maxConnectionTimeInSeconds : this.maxConnectionTimeInSeconds + this.graceTimeInSeconds
            const hasMaxConnectionTimeInSecondsExceeded = totalConnectedTimeInSeconds > maxConnectionTimeInSeconds
            if (hasMaxConnectionTimeInSecondsExceeded) {
              this.disconnectVisitor(visitor)
            }
          }

          const index = this.chatVisitors.findIndex((x) => x.id === visitor.id)
          if (index > -1) {
            this.removeChatVisitor(index)
            continue
          }
        }
        // console.log('Remaining ', this.maxNoActivityTimeInSeconds - totalConnectedTimeInSeconds, 'seconds for visitor', visitor.id, visitor)
        if (hasNoActivityTimeExceeded) {
          this.disconnectAgentFromVisitorChat(visitor)
        }
      }
    },

    updateAgentChatStartDateOnTyping(visitorId) {
      const index = this.chatVisitors.findIndex((x) => x.id === visitorId)
      if (index > -1) {
        this.updateChatVisitorAgentChatStartDate({ index, agentChatStartDate: { seconds: dayjs().unix() } })
      }
    },

    addToChatVisitorsIfRequired(visitor) {
      if (!visitor.connectedAgentChatDate || !visitor.connectedAgentDate || !visitor.connectedAgentId || !this.activeUserInfo || !this.activeUserInfo.uid) {
        return
      }
      if (visitor.connectedAgentId !== this.activeUserInfo.uid || !['chat', 'video', 'audio'].includes(visitor.agentRequestType)) {
        return
      }
      const chatVisitor = {
        id: visitor.id,
        agentChatStartDate: visitor.connectedAgentChatDate || visitor.connectedAgentDate
      }
      const index = this.chatVisitors.findIndex((x) => x.id === visitor.id)
      if (index === -1) {
        //const chatStartDate = dayjs.unix(chatVisitor.agentChatStartDate.seconds).utc()
        //const today = dayjs.utc()
        //const totalConnectedTimeInSeconds = today.diff(chatStartDate, 'seconds')
        //console.log('Remaining ', this.maxNoActivityTimeInSeconds - totalConnectedTimeInSeconds, 'seconds')
        this.addChatVisitor(chatVisitor)
      } else {
        this.updateChatVisitorAgentChatStartDate({ index, agentChatStartDate: chatVisitor.agentChatStartDate })
      }
    }
  }
}
