const state = {
  visitorInfo: null,
  deviceSelected: false,
  callStarted: false,
  communicationMode: null,
  clearWebRtc: false,
  viewType: 'connect-dialog',
  dialog: {
    type: ''
  },
  isMobile: false,
  agentInfo: {
    companyName: '',
    dialogCompanyName: '',
    connectedAgentImage: '',
    connectedAgent: ''
  },
  isWaiting: true,
  volumeLevel: 30,
  surflyURL: '',
  preShowAVSettings: false,
  isCobrowse: false,
  company: null,
  messages: []
}

const getters = {
  visitorInfo: (state) => state.visitorInfo,
  deviceSelected: (state) => state.deviceSelected,
  callStarted: (state) => state.callStarted,
  communicationMode: (state) => state.communicationMode,
  clearWebRtc: (state) => state.clearWebRtc,
  viewType: (state) => state.viewType,
  dialog: (state) => state.dialog,
  isMobile: (state) => state.isMobile,
  agentInfo: (state) => state.agentInfo,
  isWaiting: (state) => state.isWaiting,
  volumeLevel: (state) => state.volumeLevel,
  surflyURL: (state) => state.surflyURL,
  preShowAVSettings: (state) => state.preShowAVSettings,
  isCobrowse: (state) => state.isCobrowse,
  company: (state) => state.company,
  messages: (state) => state.messages
}

const mutations = {
  setVisitorInfo: (state, payload) => {
    state.visitorInfo = payload
  },
  setDeviceSelected: (state, payload) => {
    state.deviceSelected = payload
  },
  setCallStarted: (state, payload) => {
    state.callStarted = payload
  },
  setCommunicationMode: (state, payload) => {
    state.communicationMode = payload
  },
  setClearWebRtc: (state, payload) => {
    state.clearWebRtc = payload
  },
  setViewType: (state, payload) => {
    state.viewType = payload
  },
  setDialog: (state, payload) => {
    state.dialog = payload
  },
  setIsMobile: (state, payload) => {
    state.isMobile = payload
  },
  setAgentInfo: (state, payload) => {
    state.agentInfo = payload
  },
  setIsWaiting: (state, payload) => {
    state.isWaiting = payload
  },
  setVolumeLevel: (state, payload) => {
    state.volumeLevel = payload
  },
  setSurflyURL: (state, payload) => {
    state.surflyURL = payload
  },
  setPreShowAVSettings: (state, payload) => {
    state.preShowAVSettings = payload
  },
  setIsCobrowse: (state, payload) => {
    state.isCobrowse = payload
  },
  setCompany: (state, payload) => {
    state.company = payload
  },
  setMessages: (state, payload) => {
    state.messages = payload
  }
}

export const campaign = {
  namespaced: true,
  state,
  getters,
  mutations
}
