export default {
  en: (state) => {
    const en = JSON.parse(JSON.stringify(state.en))
    const _copy = Object.assign({}, en)
    return _copy
  },
  de: (state) => {
    const de = JSON.parse(JSON.stringify(state.de))
    const _copy = Object.assign({}, de)
    return _copy
  },
  translations: (state) => [...state.translations].sort((a, b) => a.sort - b.sort),
  tscroll: (state) => state.scrollListener,
  addKey: (state) => state.addKey,
  translationUpdateKey: (state) => state.translationUpdateKey,
  updateKey: (state) => state.updateKey,
  removedLanguage: (state) => state.removedLanguage,
  updatedField: (state) => state.updatedField,
  currentLanguage: (state) => state.currentLanguage,
  currentTab: (state) => state.currentTab,
  showWidgetSelection: (state) => state.showWidgetSelection,
  selectedWidget: (state) => state.selectedWidget,
  showQRWidgetSelection: (state) => state.showQRWidgetSelection,
  selectedQRWidget: (state) => state.selectedQRWidget,
  selectedGptWidget: (state) => state.selectedGptWidget,
  showGptWidgetSelection: (state) => state.showGptWidgetSelection,
  showDeleteTranslationModal: (state) => state.showDeleteTranslationModal,
  showResetTranslationModal: (state) => state.showResetTranslationModal,
  translationToBeDeleted: (state) => state.translationToBeDeleted,
  translationToBeReseted: (state) => state.translationToBeReseted,
  defaultTranslations: (state) => state.defaultTranslations,
  selectedWidgetSavedTranslation: (state) => state.selectedWidgetSavedTranslation,
  selectedGptWidgetSavedTranslation: (state) => state.selectedGptWidgetSavedTranslation,
  selectedQRWidgetSavedTranslation: (state) => state.selectedQRWidgetSavedTranslation
}
