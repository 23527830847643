var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vs-sidebar--item",
      class: [
        { "vs-sidebar-item-active": _vm.activeLink },
        {
          "disabled-item pointer-events-none":
            _vm.isDisabled || _vm.audioVideoStarted,
        },
      ],
    },
    [
      _vm.to
        ? _c(
            "router-link",
            {
              class: [{ "router-link-active": _vm.activeLink }],
              attrs: {
                tabindex: "-1",
                exact: "",
                to: _vm.to,
                target: _vm.target,
              },
            },
            [
              _vm.iconPack === "custom-icons"
                ? [
                    _c(
                      "span",
                      {
                        staticClass: "select-none relative",
                        staticStyle: { height: "20px" },
                      },
                      [
                        _vm.icon === "DashboardIcon"
                          ? _c("dashboard-icon", {
                              staticStyle: { "margin-right": "14px" },
                              attrs: { width: 20, height: 20 },
                            })
                          : _vm._e(),
                        _vm.icon === "GptConnectIcon"
                          ? _c("gpt-connect-icon", {
                              staticStyle: { "margin-right": "14px" },
                              attrs: { width: 22, height: 18 },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                : !_vm.featherIcon
                ? [
                    _c(
                      "span",
                      {
                        staticClass: "select-none relative",
                        staticStyle: { height: "20px" },
                      },
                      [
                        _vm.activeLink
                          ? _c(
                              "vs-icon",
                              {
                                staticStyle: {
                                  color: "white",
                                  "margin-right": "14px",
                                },
                                attrs: {
                                  size: "20px",
                                  "icon-pack": _vm.iconPack,
                                  icon: _vm.icon,
                                },
                              },
                              [_vm._v(_vm._s(_vm.icon))]
                            )
                          : _c(
                              "vs-icon",
                              {
                                staticStyle: { "margin-right": "14px" },
                                attrs: {
                                  size: "20px",
                                  "icon-pack": _vm.iconPack,
                                  icon: _vm.icon,
                                },
                              },
                              [_vm._v(_vm._s(_vm.icon))]
                            ),
                      ],
                      1
                    ),
                  ]
                : [
                    _c("feather-icon", {
                      class: { "w-3 h-3": _vm.iconSmall },
                      attrs: { icon: _vm.icon },
                    }),
                  ],
              _vm._t("default"),
            ],
            2
          )
        : _c(
            "a",
            { attrs: { target: _vm.target, href: _vm.href, tabindex: "-1" } },
            [
              !_vm.featherIcon
                ? _c(
                    "vs-icon",
                    {
                      attrs: {
                        size: "22px",
                        "icon-pack": _vm.iconPack,
                        icon: _vm.icon,
                      },
                    },
                    [_vm._v(_vm._s(_vm.icon))]
                  )
                : _c("feather-icon", {
                    class: { "w-3 h-3": _vm.iconSmall },
                    attrs: { icon: _vm.icon },
                  }),
              _vm._t("default"),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }