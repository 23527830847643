const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()
import { Validator } from 'vee-validate'

/* validate URL */
const isURLValid = (value) => {
  if (value && value.toLowerCase().startsWith('tel:')) {
    const index = value.toLowerCase().indexOf('tel:')
    const tel = value.substring(index + 4)
    return tel.length >= 10 && tel.length <= 20
  }

  if (value && value.toLowerCase().startsWith('mailto:')) {
    const index = value.toLowerCase().indexOf('mailto:')
    const email = value.substring(index + 7)
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const urlPattern = new RegExp(
    '^(http(s)?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // validate fragment locator
  return !!urlPattern.test(value)
}

function validatePhone(v) {
  if (!v.startsWith('+')) return false
  if (v.length < 7) return false
  let result = false
  try {
    const number = phoneUtil.parseAndKeepRawInput(v)
    const region = phoneUtil.getRegionCodeForNumber(number)
    result = phoneUtil.isValidNumberForRegion(number, region)
  } catch (error) {
    result = false
  }
  return result
}

function validateMobileForRegion(v) {
  try {
    let dialCodeFound = false
    const oneDigitDialCode = v.substring(0, 2)
    const oneDigitDialCodes = ['+1']
    let result = oneDigitDialCodes.includes(oneDigitDialCode)
    dialCodeFound = result
    result = result ? !v[2] === '0' : true
    if (!result) return false

    const twoDigitDialCode = v.substring(0, 3)
    const twoDigitDialCodes = ['+49', '+91', '+43', '+45', '+41', '+46', '+30', '+31', '+32', '+33', '+34', '+36', '+39', '+44', '+47', '+48', '+64', '+65']
    result = twoDigitDialCodes.includes(twoDigitDialCode)
    dialCodeFound = dialCodeFound ? dialCodeFound : result
    result = result ? !v[3] === '0' : true
    if (!result) return false

    const threeDigitDialCode = v.substring(0, 4)
    const threeDigitDialCodes = ['+423', '+971', '+973', '+974', '+965', '+852']
    result = threeDigitDialCodes.includes(threeDigitDialCode)
    dialCodeFound = dialCodeFound ? dialCodeFound : result
    result = result ? !v[4] === '0' : true

    if (!result) return false

    if (!dialCodeFound) return false

    return result
  } catch (error) {
    return false
  }
}

const validateMultipeEmails = async (value) => {
  const v = new Validator()
  const emails = value.split(',')
  let result = true
  for (const email of emails) {
    const res = await v.verify(email, 'email')
    if (!res.valid) {
      result = false
    }
  }
  return result
}

const isValidDialogName = async (value, args) => {
  const pArgs = JSON.parse(args)
  value = value.trim().toLowerCase()
  const oldValue = pArgs.oldVal.trim().toLowerCase()
  const dialogs = pArgs.dialogs

  let validated = false
  if (value === oldValue) {
    validated = dialogs.filter((x) => x.trim().toLowerCase() === value).length > 1
  } else {
    validated = !!dialogs.find((x) => x.trim().toLowerCase() === value)
  }

  return !validated
}

const isValidCampaignName = async (value, args) => {
  const pArgs = JSON.parse(args)
  value = value.trim().toLowerCase()
  const oldValue = pArgs.oldVal.trim().toLowerCase()
  const campaigns = pArgs.campaigns

  let validated = false
  if (value === oldValue) {
    validated = campaigns.filter((x) => x.trim().toLowerCase() === value).length > 1
  } else {
    validated = !!campaigns.find((x) => x.trim().toLowerCase() === value)
  }

  return !validated
}

const isValidABTestName = async (value, args) => {
  const pArgs = JSON.parse(args)
  value = value.trim().toLowerCase()
  const oldValue = pArgs.oldVal.trim().toLowerCase()
  const abtests = pArgs.abtests

  let validated = false
  if (value === oldValue) {
    validated = abtests.filter((x) => x.trim().toLowerCase() === value).length > 1
  } else {
    validated = !!abtests.find((x) => x.trim().toLowerCase() === value)
  }

  return !validated
}

const greaterThanEqualsZero = async (v) => {
  if (isNaN(v)) {
    return false
  }
  const result = parseInt(v)

  return result >= 0
}

const validateMobile = async (v) => {
  return !v || validatePhone(v)
}

const validatePhoneForRegion = async (v) => {
  return !v || validateMobileForRegion(v)
}

const validateEmail = async (v) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return re.test(String(v).toLowerCase())
}

const validateStringForSpecialCharacters = async (v) => {
  return !/[|\\/~^:,;?!&%$@*+]/.test(v)
}

const verifyPassword = async (v) => {
  const strongRegex = /^(?=.{6,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/
  return strongRegex.test(v)
}

const atLeastOneNumber = async (v) => {
  const regex = /\d/

  return regex.test(v)
}

const atLeastOneUppercase = async (v) => {
  const regex = /[A-Z]/

  return regex.test(v)
}

const atLeastOneSpecialCharacter = async (v) => {
  const regex = /[&@#%$*!?^]/

  return regex.test(v)
}

const requiredSelect = async (v) => {
  return (Number(v) !== -1 || (v && isNaN(v) && v.trim().length > 0)) && v !== '' && v !== null
}

const rules = {
  isURLValid,
  validateMultipeEmails,
  validateMobile,
  validatePhoneForRegion,
  validateEmail,
  validateStringForSpecialCharacters,
  verifyPassword,
  greaterThanEqualsZero,
  isValidDialogName,
  isValidABTestName,
  isValidCampaignName,
  atLeastOneNumber,
  atLeastOneUppercase,
  atLeastOneSpecialCharacter,
  requiredSelect
}

// export an 'install' function.
export default (Validator) => {
  // for every rule we defined above.
  Object.keys(rules).forEach((rule) => {
    //  add the rule.
    Validator.extend(rule, rules[rule])
  })
}
