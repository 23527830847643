/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import Vue from 'vue'
import _ from 'underscore'

const mutations = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
    state.isVerticalNavMenuActive = value
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val
  },
  UPDATE_MAIN_LAYOUT_TYPE(state, val) {
    state.mainLayoutType = val
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
    state.verticalNavMenuItemsMin = val
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
    state.verticalNavMenuWidth = width
  },

  // VxAutoSuggest

  UPDATE_STARRED_PAGE(state, payload) {
    // find item index in search list state
    const index = state.navbarSearchAndPinList['pages'].data.findIndex((item) => item.url === payload.url)

    // update the main list
    state.navbarSearchAndPinList['pages'].data[index].is_bookmarked = payload.val

    // if val is true add it to starred else remove
    if (payload.val) {
      state.starredPages.push(state.navbarSearchAndPinList['pages'].data[index])
    } else {
      // find item index from starred pages
      const index = state.starredPages.findIndex((item) => item.url === payload.url)

      // remove item using index
      state.starredPages.splice(index, 1)
    }
  },

  // Navbar-Vertical

  ARRANGE_STARRED_PAGES_LIMITED(state, list) {
    const starredPagesMore = state.starredPages.slice(10)
    state.starredPages = list.concat(starredPagesMore)
  },
  ARRANGE_STARRED_PAGES_MORE(state, list) {
    let downToUp = false
    const lastItemInStarredLimited = state.starredPages[10]
    const starredPagesLimited = state.starredPages.slice(0, 10)
    state.starredPages = starredPagesLimited.concat(list)

    state.starredPages.slice(0, 10).map((i) => {
      if (list.indexOf(i) > -1) downToUp = true
    })

    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited)
    }
  },

  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////

  TOGGLE_CONTENT_OVERLAY(state, val) {
    state.bodyOverlay = val
  },
  UPDATE_PRIMARY_COLOR(state, val) {
    state.themePrimaryColor = val
  },
  UPDATE_THEME(state, val) {
    state.theme = val
  },
  UPDATE_WINDOW_WIDTH(state, width) {
    state.windowWidth = width
  },
  UPDATE_WINDOW_SCROLL_Y(state, val) {
    state.scrollY = val
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  // Updates user info in state and localstorage
  UPDATE_USER_INFO(state, payload) {
    // Get state
    state.AppActiveUser = state.AppActiveUser ? { ...state.AppActiveUser } : {}
    // Get Data localStorage
    const userInfo = {} //JSON.parse(localStorage.getItem('userInfo')) || state.AppActiveUser
    for (const property of Object.keys(payload)) {
      if (payload[property] !== null && property !== 'closeAnimation') {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUser[property] = payload[property]

        // Update key in localStorage
        const userProps = ['impersonated', 'displayName', 'firstname', 'lastname', 'email', 'photo']
        if (userProps.includes(property)) {
          userInfo[property] = payload[property]
        }
      }
    }

    if (payload.closeAnimation) {
      payload.closeAnimation()
    }
    // Store data in localStorage
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
  },

  // Updates user info in state and localstorage
  REMOVE_USER_INFO(state, payload) {
    // Get Data localStorage
    localStorage.removeItem('userInfo')
    state.AppActiveUser = {}

    if (payload && payload.closeAnimation) {
      payload.closeAnimation()
    }
  },

  UPDATE_VISITORS(state, payload) {
    state.visitors.list = payload
  },
  CLEAR_INCOMING_REQUESTS(state) {
    state.visitors.incoming = []
  },
  UPDATE_INCOMING_REQUESTS(state, payload) {
    const unique = payload.filter((element) => {
      const currentTime = Math.max(...payload.filter((x) => x.id === element.id).map((o) => o.currentTime))
      if (currentTime === element.currentTime) {
        return true
      }
      return false
    })
    state.visitors.incoming = unique
  },
  UPDATE_STATIC_INCOMING(state, payload) {
    const unique = payload.filter((element) => {
      const currentTime = Math.max(...payload.filter((x) => x.id === element.id).map((o) => o.currentTime))
      if (currentTime === element.currentTime) {
        return true
      }
      return false
    })
    state.visitors.static = unique
  },
  UPDATE_VISITORS_B2B_ENABLED(state, payload) {
    state.visitors.b2bEnabled = payload
  },
  UPDATE_VISITORS_SHOW_ONLINE_VISITORS(state, payload) {
    state.visitors.showOnlineVisitors = payload
  },
  UPDATE_VISITORS_DATE_RANGE(state, payload) {
    state.visitors.dateRange = payload
  },
  UPDATE_VISITORS_DATE_RANGE_INCOMING(state, payload) {
    state.visitors.dateRangeIncoming = payload
  },
  NEW_VISITORS_ADD(state, payload) {
    const notification = {}
    notification.id = payload.id
    notification.type = 'visitor-request'
    notification.icon = 'MessageSquareIcon'
    notification.category = 'primary'
    notification.title = payload.title
    notification.msg = payload.companyName ? payload.companyName : payload.city || ''
    notification.date = payload.requestDate ? payload.requestDate : payload.created
    notification.surlfyURL = payload.surlfyURL
    notification.joinURL = payload.joinURL
    notification.surlfySessionId = payload.surlfySessionId
    notification.dialogId = payload.dialogId
    notification.abTestId = payload.abTestId
    notification.location = payload.location
    state.notifications.push(notification)
  },
  NOTIFICATIONS_REMOVE(state, payload) {
    const removeIndex = state.notifications.findIndex((x) => x.id === payload.id && x.type === payload.type)
    state.notifications.splice(removeIndex, 1)
  },
  NOTIFICATIONS_CLEAR(state) {
    state.notifications = []
  },
  UNREAD_VISITORS_CLEAR(state) {
    const visitors = state.notifications.filter((x) => x.type === 'visitor-request')
    for (const visitor of visitors) {
      const removeIndex = state.notifications.findIndex((x) => x.id === visitor.id)
      state.notifications.splice(removeIndex, 1)
    }
  },
  UPDATE_AUDIO_ACCESS(state, payload) {
    state.audioAccess = payload
  },

  UPDATE_DIALOGS(state, val) {
    state.dialogs = val
  },

  UPDATE_ABTESTS(state, val) {
    state.abtests = val
  },

  UPDATE_ALL_DIALOGS(state, val) {
    state.all_dialogs = val
  },

  UPDATE_COMPANY_USERS(state, val) {
    state.companyUsers = val
  },
  UPDATE_MEETINGS(state, val) {
    state.meetings = val
  },

  UPDATE_LEADS(state, val) {
    state.leads = val
  },

  UPDATE_VERSION(state, val) {
    state.version = val
  },
  UPDATE_CAMPAIGNS(state, val) {
    state.campaigns = val
  },
  UPDATE_USERS(state, val) {
    state.users = [...val]
  },
  ADD_UPDATE_USER(state, val) {
    const index = state.users.findIndex((x) => x.id === val.id)
    if (index > -1) {
      // Replace the object in the array using splice to maintain reactivity
      state.users.splice(index, 1, { ...val })
    } else {
      state.users.push(val)
    }
  },
  REMOVE_USER(state, val) {
    state.users = state.users.filter((x) => x.id !== val.id)
  },
  UPDATE_ASSIGNMENT_RULES(state, val) {
    state.assignment_rules = val
  },
  UPDATE_USER_GROUPS(state, val) {
    state.user_groups = val
  },
  UPDATE_INVOICES(state, val) {
    state.invoices = val
  },
  UPDATE_SUBSCRIPTION(state, val) {
    state.subscription = val
  },
  UPDATE_SUBSCRIPTION_PLANS(state, val) {
    state.subscription_plans = val
  },
  UPDATE_REFRESH_STATE(state, val) {
    state.refresh = val
  },
  UPDATE_COMPANY(state, val) {
    state.company = val
  },
  setPickedFiles(state, files) {
    if (state.pickedFiles && state.pickedFiles.length) {
      state.pickedFiles = [...state.pickedFiles, ...files]
    } else {
      state.pickedFiles = [...files]
    }
  },
  filterPickedFiles(state, index) {
    state.pickedFiles = state.pickedFiles.filter((el, idx) => idx !== index)
  },
  clearPickedFiles(state) {
    state.pickedFiles = []
  },
  setChatFilesUpload(state, { index, file }) {
    Vue.set(state.files, index, file)
  },
  filterChatFilesUpload(state, closeUrl) {
    state.files = state.files.filter(({ url }) => url !== closeUrl)
  },
  clearChatFilesUpload(state) {
    state.files = []
  },
  UPDATE_CALL_CENTER_SETTINGS(state, val) {
    state.call_center_settings = val
  },
  UPDATE_CALL_CENTER_USER_WORKTIME(state, val) {
    state.call_center_user_worktime = val
  },
  UPDATE_AGENCY_SETTINGS(state, val) {
    state.agency_settings = val
  },
  UPDATE_VISITOR_IS_TYPING(state, val) {
    state.visitorIsTyping = val
  },
  UPDATE_VISITOR_ID(state, val) {
    state.visitorId = val
  },
  UPDATE_VISITOR(state, val) {
    state.visitor = val
  },
  UPDATE_CALL_CENTER_AGENTS_TO_BE_NOTIFIED(state, val) {
    state.callCenterAgentsToBeNotified = val
  },
  ADD_ONLINE_USERS(state, val) {
    state.onlineUsers.push(val)
  },
  REMOVE_ONLINE_USERS(state, index) {
    state.onlineUsers.splice(index, 1)
  },
  UPDATE_BANNERS(state, val) {
    const bannersWithUpdatedPosition = val.map((el, index) => {
      const oldEl = el
      const newEl = {
        ...oldEl,
        position: index
      }
      return newEl
    })
    const bannersSortedByPosition = _.sortBy(bannersWithUpdatedPosition, 'position')
    state.banners = bannersSortedByPosition
  },
  UPDATE_BANNERS_CAROUSEL_SETTINGS(state, val) {
    state.swiperOptions = val
  },
  UPDATE_DIALOG(state, payload) {
    state.dialog = payload
  },
  UPDATE_DIALOG_PARTIAL(state, payload) {
    Object.assign(state.dialog, payload)
  },
  UPDATE_CAMPAIGN(state, val) {
    state.campaignWidget = val
  },
  UPDATE_CAMPAIGN_ID(state, val) {
    state.campaignId = val
  },
  UPDATE_IS_LOADING_REGISTER_FUNNEL(state, val) {
    state.isLoadingRegisterFunnel = val
  },
  UPDATE_LOCKED_UI(state, val) {
    state.lockedUI = val
  },
  UPDATE_SUBSCRIPTION_CANCELLED(state, val) {
    state.subscriptionCancelled = val
  },
  UPDATE_APPSUMO_SUBSCRIPTION_REFUNDED(state, val) {
    state.appSumoSubscriptionRefunded = val
  },
  UPDATE_APPSUMO_DELETE_USERS(state, val) {
    state.appSumoSubscriptionDeleteUsers = val
  },
  UPDATE_CURRENCY(state, val) {
    state.currency = val
  },
  ADD_CHAT_VISITOR(state, val) {
    state.chatVisitors.push(val)
  },
  REMOVE_CHAT_VISITOR(state, index) {
    state.chatVisitors.splice(index, 1)
  },
  UPDATE_CHAT_VISITOR_AGENT_CHAT_START_DATE(state, val) {
    state.chatVisitors[val.index].agentChatStartDate = val.agentChatStartDate
  },
  UPDATE_HAS_FILE_SIZE_ERROR(state, val) {
    state.hasFilesizeError = val
  },
  UPDATE_USER_INTERACTION_WITH_DOM(state, val) {
    state.hasUserInteractedWithDOM = val
  },
  UPDATE_RATING_SCREEN(state, val) {
    state.showRatingScreen = val
  },
  UPDATE_WIDGETS_FILTER_FOR_VISITOR(state, val) {
    state.widgetsFilterForVisitor = val
  },
  UPDATE_WIDGETS_ASSIGNED(state, val) {
    let updateWidgetAssignedKey = false
    if (state.widgetsAssigned && val) {
      const beforeString = JSON.stringify(state.widgetsAssigned.sort())
      const afterString = JSON.stringify(val.sort())
      if (beforeString !== afterString) {
        updateWidgetAssignedKey = true
      }
    }
    state.widgetsAssigned = val
    if (updateWidgetAssignedKey) {
      state.widgetsAssignedKey = Math.random().toString(36).substring(2, 15)
    }
  },
  UPDATE_VISITORS_LAST_UPDATED(state, val) {
    state.visitors.visitorsLastUpdated = val
  },
  UPDATE_API_KEYS(state, val) {
    state.apiKeys = val
    state.selectedAccountApiKeys = val
  },
  UDPATE_AUDIO_OUTPUT_DEVICES(state, val) {
    state.audioOutputDevices = val
  },
  UPDATE_SHOW_CHANGE_PLAN_UI(state, val) {
    state.showChangePlanUI = val
  },
  UPDATE_CHOSEN_PLAN(state, val) {
    state.chosenPlan = val
  },
  UPDATE_ACCOUNT_VATNUMBER(state, val) {
    state.accountVatNumber = val
  },
  UPDATE_APPLIED_PROMOCODE(state, val) {
    state.appliedPromoCode = val
  },
  SET_NEW_UID_FLOW_STEP(state, val) {
    state.newUidFlowStep = val
  },
  SET_PAYMENT_FAILED_UI(state, val) {
    state.showPaymentFailedUI = val
  },
  SET_PAYMENT_METHOD(state, val) {
    state.selectedPaymentMethod = val
  },
  SET_IS_SEARCH_VISITORS(state, val) {
    state.isSearchVisitorsOn = val
  },
  SET_IS_SEARCH_GPT_CHATS(state, val) {
    state.isSearchGptChatsOn = val
  },
  SET_SEARCH_VISITORS(state, val) {
    state.searchVisitors = val
  },
  PUSH_SEARCH_VISITOR(state, val) {
    state.searchVisitors.push(val)
  },
  SET_SEARCH_GPT_CHATS(state, val) {
    state.searchGptChats = val
  },
  SET_SELECTED_VEGA_WIDGET_ID(state, val) {
    state.selectedVegaWidgetId = val
  },
  SET_SELECTED_VEGA_WIDGET(state, val) {
    state.selectedVegaWidget = val
  },
  SET_VEGA_POPUP_TEXT(state, val) {
    state.vegaPopupText = val
  },
  SET_VEGA_POPUP_MESSAGE(state, val) {
    state.vegaPopupMessage = val
  },
  SET_VEGA_LOCKING_TIME(state, val) {
    state.vegaLockingTime = val
  },
  SET_VEGA_POPUP_POSITION(state, val) {
    state.vegaPopupPosition = val
  },
  SET_IS_VEGA_POPUP_WITHOUT_IMAGE(state, val) {
    state.isVegaPopupWithoutImage = val
  },
  SET_SHOW_MOLLIE_VEGA(state, val) {
    state.showMollieVega = val
  },
  SET_VEGA_HAS_DAILY_LIMIT(state, val) {
    state.vegaHasDailyLimit = val
  },
  SET_VEGA_DAILY_LIMIT(state, val) {
    state.vegaDailyLimit = val
  },
  SET_SELECTED_ACCOUNT(state, val) {
    state.selectedAccount = val
  },
  SET_ACCOUNT_LIST(state, val) {
    state.accountList = val
  },
  SET_SELECTED_ACCOUNT_APIKEYS(state, val) {
    state.selectedAccountApiKeys = val
  },

  SET_VEGA_CURRENT_VISITORS_FOR_THE_DAY(state, val) {
    state.vegaCurrentVisitorsForTheDay = val
  },

  SET_SELECTED_BILLING_COUNTRY(state, val) {
    state.selectedBillingCountry = val
  },

  UPDATE_GPT_CHATS(state, payload) {
    state.gptChats = payload
  },

  RESET_MAIN_STORE(state) {
    state.AppActiveUser = null
    state.bodyOverlay = false
    state.isVerticalNavMenuActive = true
    state.verticalNavMenuWidth = 'default'
    state.verticalNavMenuItemsMin = false
    state.scrollY = 0

    state.windowWidth = window.innerWidth
    state.visitors = {
      list: [],
      incoming: [],
      static: [],
      b2bEnabled: false,
      showOnlineVisitors: false,
      dateRange: {
        startDate: null,
        endDate: null
      },
      dateRangeIncoming: {
        startDate: null,
        endDate: null
      },
      visitorsLastUpdated: null
    }
    state.notifications = []
    state.audioAccess = false

    state.all_dialogs = []
    state.dialogs = []
    state.abtests = []
    state.companyUsers = []
    state.meetings = []
    state.version = null
    state.campaigns = []
    state.users = []
    state.leads = []
    state.assignment_rules = []
    state.user_groups = []

    state.pickedFiles = []
    state.files = []
    state.invoices = []
    state.subscription = null
    state.subscription_plans = []
    state.company = null
    state.refresh = {
      invoices: false,
      subscription: false,
      plans: false
    }
    state.call_center_settings = []
    state.call_center_user_worktime = null
    state.agency_settings = []
    state.visitor = null
    state.visitorIsTyping = false
    state.widgetsFilterForVisitor = []
    state.widgetsAssigned = []
    state.onlineUsers = []
    state.callCenterAgentsToBeNotified = []
    state.banners = []
    state.swiperOptions = null
    state.dialog = null
    state.campaignWidget = null
    state.campaignId = null
    state.isLoadingRegisterFunnel = false
    state.lockedUI = false
    state.subscriptionCancelled = false
    state.currency = {
      text: 'USD',
      symbol: '$'
    }
    state.chatVisitors = []
    state.appSumoSubscriptionRefunded = false
    state.appSumoSubscriptionDeleteUsers = false
    state.hasFilesizeError = false
    state.showRatingScreen = false
    state.apiKeys = null
    state.audioOutputDevices = []
    state.showChangePlanUI = false
    state.chosenPlan = null
    state.accountVatNumber = null
    state.appliedPromoCode = null
    state.selectedPaymentMethod = null
    state.newUidFlowStep = 1
    state.showPaymentFailedUI = false
    state.isSearchVisitorsOn = false
    state.isSearchGptChatsOn = false
    state.searchVisitors = []
    state.selectedVegaWidgetId = -1
    state.selectedVegaWidget = null
    state.vegaPopupText = ''
    state.vegaPopupMessage = ''
    state.vegaPopupPosition = 'centered'
    state.isVegaPopupWithoutImage = false
    state.showMollieVega = false
    state.vegaHasDailyLimit = false
    state.vegaDailyLimit = '20.00'
    state.selectedAccount = null
    state.accountList = []
    state.setSelectedAccountApiKeys = null
    state.selectedBillingCountry = null
    state.selectedLanguageForWebConnect = { code: 'en', lnFullText: 'English', lnText: 'ENG' }
  }
}

export default mutations
