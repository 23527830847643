var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mollie-checkout" }, [
    _vm.supportedPaymentMethods.length > 0
      ? _c("div", { staticClass: "mollie-checkout__checkout" }, [
          _c("div", { staticClass: "mollie-checkout__checkout__buttons" }, [
            _vm.supportedPaymentMethods.includes("card")
              ? _c(
                  "div",
                  {
                    staticClass: "mollie-checkout__checkout__button",
                    class: {
                      "mollie-checkout__checkout__button--active":
                        _vm.paymentType === "card",
                    },
                    on: {
                      click: function () {
                        return _vm.showPaymentType("card")
                      },
                    },
                  },
                  [_vm._m(0)]
                )
              : _vm._e(),
            _vm.supportedPaymentMethods.includes("paypal")
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mollie-checkout__checkout__button mollie-checkout__checkout__button--paypal",
                    on: {
                      click: function () {
                        return _vm.showPaymentType("paypal")
                      },
                    },
                  },
                  [_vm._m(1)]
                )
              : _vm._e(),
            _vm.supportedPaymentMethods.includes("invoice")
              ? _c(
                  "div",
                  {
                    staticClass: "mollie-checkout__checkout__button",
                    class: {
                      "mollie-checkout__checkout__button--active":
                        _vm.paymentType === "invoice",
                    },
                    on: {
                      click: function () {
                        return _vm.showPaymentType("invoice")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("vue.invoice")) + "\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm.supportedPaymentMethods.includes("sepa")
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mollie-checkout__checkout__button mollie-checkout__checkout__button--sepa",
                    on: {
                      click: function () {
                        return _vm.showPaymentType("sepa")
                      },
                    },
                  },
                  [_vm._m(2)]
                )
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "mollie-checkout__holder",
        class: {
          "mollie-checkout__holder--modal": _vm.isModal,
        },
      },
      [
        _vm.isLoading
          ? _c("div", { staticClass: "mollie-checkout__loading" }, [
              _vm.isLoadingInProccess
                ? _c(
                    "div",
                    { staticClass: "mollie-checkout__loading__loader" },
                    [
                      _c("span", {
                        staticClass:
                          "mollie-checkout__loading__loader__spinner",
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "mollie-checkout__loading__loader__text",
                        },
                        [_vm._v(_vm._s(_vm.loaderSpinnerText))]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.isLoadingSuccessful && !_vm.isLoadingInProccess
                ? _c(
                    "div",
                    { staticClass: "mollie-checkout__loading__loader" },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/register-funnel/check.png"),
                          alt: "Success",
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mollie-checkout__loading__loader__title",
                        },
                        [_vm._v(_vm._s(_vm.$t("gpt.subscription.thankYou")))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "mollie-checkout__loading__loader__text",
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("gpt.subscription.thankYouMessage"))
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.paymentType === "card" &&
                  _vm.mollie &&
                  _vm.areMollieComponentsReady,
                expression:
                  "paymentType === 'card' && mollie && areMollieComponentsReady",
              },
            ],
          },
          [
            _c("div", { staticClass: "wrapper" }, [
              _c(
                "form",
                {
                  ref: "mcForm",
                  staticClass: "form",
                  attrs: { id: "mcForm" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onMollieCreditCardPayment.apply(
                        null,
                        arguments
                      )
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mollie-checkout__fields-container form-fields relative overflow-hidden",
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "form-group form-group--card-holder" },
                          [
                            _c("div", {
                              ref: "card-holder",
                              attrs: { id: "card-holder" },
                            }),
                            _vm._m(3),
                            _c("div", {
                              ref: "card-holder-error",
                              staticClass: "field-error",
                              attrs: { id: "card-holder-error", role: "alert" },
                            }),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "form-group form-group--card-number" },
                          [
                            _c("div", {
                              ref: "card-number",
                              attrs: { id: "card-number" },
                            }),
                            _vm._m(4),
                            _c("div", {
                              ref: "card-number-error",
                              staticClass: "field-error",
                              attrs: { id: "card-number-error", role: "alert" },
                            }),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "form-group form-group--expiry-date" },
                          [
                            _vm._m(5),
                            _c("div", {
                              ref: "expiry-date",
                              attrs: { id: "expiry-date" },
                            }),
                            _c("div", {
                              ref: "expiry-date-error",
                              staticClass: "field-error",
                              attrs: { id: "expiry-date-error", role: "alert" },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "form-group form-group--verification-code",
                          },
                          [
                            _vm._m(6),
                            _c("div", {
                              ref: "verification-code",
                              attrs: { id: "verification-code" },
                            }),
                            _c("div", {
                              ref: "verification-code-error",
                              staticClass: "field-error",
                              attrs: {
                                id: "verification-code-error",
                                role: "alert",
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-center" },
                    [
                      _c(
                        "button",
                        {
                          ref: "submit-button",
                          staticClass: "mollie-checkout__next-button",
                          attrs: { id: "submit-button", type: "submit" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.btnTitle) +
                              "\n              "
                          ),
                          _c("img", {
                            staticClass: "mollie-checkout__next-button__icon",
                            attrs: {
                              src: require("@/assets/images/register-funnel/forward-arrow.png"),
                              alt: "Secure",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _c("div", {
                    ref: "form-error",
                    staticClass: "field-error",
                    attrs: { id: "form-error", role: "alert" },
                  }),
                ]
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.paymentType === "paypal",
                expression: "paymentType === 'paypal'",
              },
            ],
          },
          [
            _c("div", { staticClass: "mollie-checkout__paypal__wrapper" }, [
              _vm._v("Paypal"),
            ]),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.paymentType === "sepa",
                expression: "paymentType === 'sepa'",
              },
            ],
          },
          [_vm._v("Sepa")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "mollie-checkout__checkout__button__wrapper" },
      [
        _c("div", { staticClass: "flex flex-col" }, [
          _c("div", { staticClass: "flex" }, [
            _c("img", {
              staticClass: "m-px",
              attrs: {
                src: require("@/assets/images/register-funnel/VISA.svg"),
                alt: "VISA",
              },
            }),
            _c("img", {
              staticClass: "m-px",
              attrs: {
                src: require("@/assets/images/register-funnel/MASTERCARD.svg"),
                alt: "MASTERCARD",
              },
            }),
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("img", {
              staticClass: "m-px",
              attrs: {
                src: require("@/assets/images/register-funnel/AMERICAN EXPRESS.svg"),
                alt: "AMERICAN EXPRESS",
              },
            }),
            _c("img", {
              staticClass: "m-px",
              attrs: {
                src: require("@/assets/images/register-funnel/DISCOVER.svg"),
                alt: "DISCOVER",
              },
            }),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "mollie-checkout__checkout__button__wrapper" },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/images/register-funnel/paypal.png"),
            alt: "paypal",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "mollie-checkout__checkout__button__wrapper" },
      [
        _c("div", { staticClass: "flex flex-col" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/images/register-funnel/sepa.svg"),
              alt: "sepa",
            },
          }),
          _c("img", {
            attrs: {
              src: require("@/assets/images/register-funnel/Lastschrift.svg"),
              alt: "Lastschrift",
            },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "label", attrs: { for: "card-holder" } },
      [_c("div", [_vm._v("Card holder")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "label", attrs: { for: "card-number" } },
      [_c("div", [_vm._v("Card number")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "label", attrs: { for: "expiry-date" } },
      [_c("div", [_vm._v("Expiry date")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "label", attrs: { for: "verification-code" } },
      [_c("div", [_vm._v("CVC")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }