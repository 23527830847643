var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "32",
        height: "40",
        viewBox: "0 0 32 40",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M16.458 21.1328V36.9496C16.458 38.1833 15.0351 38.8734 14.0664 38.1095L2.99245 29.3771C2.99245 29.3771 5.72275 27.2909 9.57937 25.6003C13.436 23.9096 16.458 21.1328 16.458 21.1328Z",
          fill: "#3034F7",
        },
      }),
      _c(
        "mask",
        {
          staticStyle: { "mask-type": "luminance" },
          attrs: {
            id: "mask0_7988_1489",
            maskUnits: "userSpaceOnUse",
            x: "2",
            y: "21",
            width: "15",
            height: "18",
          },
        },
        [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d: "M16.458 21.1328V36.9496C16.458 38.1833 15.0351 38.8734 14.0664 38.1095L2.99245 29.3771C2.99245 29.3771 5.72275 27.2909 9.57937 25.6003C13.436 23.9096 16.458 21.1328 16.458 21.1328Z",
              fill: "white",
            },
          }),
        ]
      ),
      _c("g", { attrs: { mask: "url(#mask0_7988_1489)" } }, [
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M3.3738 16.9492L21.132 27.6063C21.132 27.6063 14.8016 34.6852 6.36106 33.9773C-2.07949 33.2694 3.3738 16.9492 3.3738 16.9492Z",
            fill: "#090DC2",
          },
        }),
      ]),
      _c("rect", {
        attrs: {
          width: "31.4196",
          height: "31.6891",
          rx: "8.27411",
          fill: "#3034F7",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11.9695 22.6357C8.91869 21.6473 6.73291 18.9802 6.73291 15.8452C6.73291 12.7102 8.91866 10.0431 11.9694 9.05469",
          stroke: "white",
          "stroke-width": "3.4212",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M19.4497 22.6357C22.5005 21.6473 24.6863 18.9802 24.6863 15.8452C24.6863 12.7102 22.5005 10.0431 19.4497 9.05469",
          stroke: "#275D73",
          "stroke-width": "3.4212",
          "stroke-linecap": "round",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M18.8974 15.086C19.3769 15.4789 19.3769 16.2124 18.8974 16.6054L15.4445 19.4349C14.8033 19.9603 13.8398 19.5042 13.8398 18.6752L13.8398 13.0161C13.8398 12.1872 14.8033 11.731 15.4445 12.2564L18.8974 15.086Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }