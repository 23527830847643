export default {
  updateDateRange({ commit }, value) {
    commit('UPDATE_DATE_RANGE', value)
  },
  addInvoice({ commit }, payload) {
    commit('ADD_INVOICE', payload)
  },
  updateInvoice({ commit }, payload) {
    commit('UPDATE_INVOICE', payload)
  },
  resetInvoices({ commit }) {
    commit('RESET_INVOICES')
  },
  addNotification({ commit }, payload) {
    commit('ADD_NOTIFICATION', payload)
  },
  updateNotification({ commit }, payload) {
    commit('UPDATE_NOTIFICATION', payload)
  },
  removeNotification({ commit }, payload) {
    commit('REMOVE_NOTIFICATION', payload)
  },
  clearNotifications({ commit }, payload) {
    commit('CLEAR_NOTIFICATIONS', payload)
  },
  resetInvoiceStore({ commit }, payload) {
    commit('RESET_INVOICE_STORE', payload)
  }
}
