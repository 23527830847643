export default {
  updateDefaultLanguageEN({ commit }, payload) {
    commit('UPDATE_DEFAULT_LANGUAGE_EN', payload)
  },
  updateDefaultLanguageDE({ commit }, payload) {
    commit('UPDATE_DEFAULT_LANGUAGE_DE', payload)
  },
  updateScrollListener({ commit }, payload) {
    commit('UPDATE_SCROLL_LISTENER', payload)
  },
  addTranslation({ commit }, payload) {
    commit('ADD_TRANSLATION', payload)
  }
}
