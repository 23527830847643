<template>
  <div class="mollie-checkout">
    <div class="mollie-checkout__checkout" v-if="supportedPaymentMethods.length > 0">
      <div class="mollie-checkout__checkout__buttons">
        <div
          v-if="supportedPaymentMethods.includes('card')"
          class="mollie-checkout__checkout__button"
          :class="{
            'mollie-checkout__checkout__button--active': paymentType === 'card'
          }"
          @click="() => showPaymentType('card')"
        >
          <div class="mollie-checkout__checkout__button__wrapper">
            <div class="flex flex-col">
              <div class="flex">
                <img class="m-px" src="@/assets/images/register-funnel/VISA.svg" alt="VISA" />
                <img class="m-px" src="@/assets/images/register-funnel/MASTERCARD.svg" alt="MASTERCARD" />
              </div>
              <div class="flex">
                <img class="m-px" src="@/assets/images/register-funnel/AMERICAN EXPRESS.svg" alt="AMERICAN EXPRESS" />
                <img class="m-px" src="@/assets/images/register-funnel/DISCOVER.svg" alt="DISCOVER" />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="supportedPaymentMethods.includes('paypal')"
          class="mollie-checkout__checkout__button mollie-checkout__checkout__button--paypal"
          @click="() => showPaymentType('paypal')"
        >
          <div class="mollie-checkout__checkout__button__wrapper">
            <img src="@/assets/images/register-funnel/paypal.png" alt="paypal" />
          </div>
        </div>

        <div
          v-if="supportedPaymentMethods.includes('invoice')"
          class="mollie-checkout__checkout__button"
          :class="{
            'mollie-checkout__checkout__button--active': paymentType === 'invoice'
          }"
          @click="() => showPaymentType('invoice')"
        >
          {{ $t('vue.invoice') }}
        </div>

        <div
          v-if="supportedPaymentMethods.includes('sepa')"
          class="mollie-checkout__checkout__button mollie-checkout__checkout__button--sepa"
          @click="() => showPaymentType('sepa')"
        >
          <div class="mollie-checkout__checkout__button__wrapper">
            <div class="flex flex-col">
              <img src="@/assets/images/register-funnel/sepa.svg" alt="sepa" />
              <img src="@/assets/images/register-funnel/Lastschrift.svg" alt="Lastschrift" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="mollie-checkout__holder"
      :class="{
        'mollie-checkout__holder--modal': isModal
      }"
    >
      <div v-if="isLoading" class="mollie-checkout__loading">
        <div v-if="isLoadingInProccess" class="mollie-checkout__loading__loader">
          <span class="mollie-checkout__loading__loader__spinner" />
          <div class="mollie-checkout__loading__loader__text">{{ loaderSpinnerText }}</div>
        </div>
        <div v-if="isLoadingSuccessful && !isLoadingInProccess" class="mollie-checkout__loading__loader">
          <img src="@/assets/images/register-funnel/check.png" alt="Success" />
          <div class="mollie-checkout__loading__loader__title">{{ $t('vega.subscription.thankYou') }}</div>
          <div class="mollie-checkout__loading__loader__text">{{ $t('vega.subscription.thankYouMessage') }}</div>
        </div>
      </div>
      <div v-show="paymentType === 'card' && mollie && areMollieComponentsReady">
        <div class="wrapper">
          <form class="form" id="mcForm" ref="mcForm" @submit.prevent="onMollieCreditCardPayment">
            <div class="mollie-checkout__fields-container form-fields relative overflow-hidden">
              <div class="row">
                <div class="form-group form-group--card-holder">
                  <div id="card-holder" ref="card-holder"></div>
                  <label class="label" for="card-holder"><div>Card holder</div></label>
                  <div id="card-holder-error" class="field-error" role="alert" ref="card-holder-error"></div>
                </div>
              </div>
              <div class="row">
                <div class="form-group form-group--card-number">
                  <div id="card-number" ref="card-number"></div>
                  <label class="label" for="card-number"><div>Card number</div></label>
                  <div id="card-number-error" class="field-error" role="alert" ref="card-number-error"></div>
                </div>
              </div>
              <div class="row">
                <div class="form-group form-group--expiry-date">
                  <label class="label" for="expiry-date"> <div>Expiry date</div></label>
                  <div id="expiry-date" ref="expiry-date"></div>
                  <div id="expiry-date-error" class="field-error" role="alert" ref="expiry-date-error"></div>
                </div>

                <div class="form-group form-group--verification-code">
                  <label class="label" for="verification-code"><div>CVC</div></label>
                  <div id="verification-code" ref="verification-code"></div>
                  <div id="verification-code-error" class="field-error" role="alert" ref="verification-code-error"></div>
                </div>
              </div>
            </div>

            <div class="flex items-center justify-center">
              <button class="mollie-checkout__next-button" id="submit-button" type="submit" ref="submit-button">
                {{ btnTitle }}
                <img src="@/assets/images/register-funnel/forward-arrow.png" alt="Secure" class="mollie-checkout__next-button__icon" />
              </button>
            </div>
            <div id="form-error" class="field-error" role="alert" ref="form-error"></div>
          </form>
        </div>
      </div>

      <div v-show="paymentType === 'paypal'">
        <div class="mollie-checkout__paypal__wrapper">Paypal</div>
      </div>

      <div v-show="paymentType === 'sepa'">Sepa</div>
    </div>
  </div>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
require('dayjs/locale/de')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

// VeeValidate
import { Validator } from 'vee-validate'
// Merge all defined languages in the dictionary and sets the current locale.
import validationDictionary from '@/validations/validationDictionary'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'MollieVega',
  props: {
    supportedPaymentMethods: {
      type: Array,
      required: false,
      default() {
        return ['card', 'paypal']
      }
    },
    isModal: {
      type: Boolean,
      default: false
    },
    updatePaymentMethod: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isLoading: false,
    isLoadingInProccess: false,
    isLoadingSuccessful: false,
    loaderSpinnerText: '',
    paymentType: null,
    mollie: null,
    areMollieComponentsReady: false,
    mollieCardComponents: {
      cardNumber: null,
      cardHolder: null,
      cardExpiryDate: null,
      cardVerificationCode: null
    }
  }),
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      vegaHasDailyLimit: 'vegaHasDailyLimit',
      vegaDailyLimit: 'vegaDailyLimit'
    }),
    btnTitle() {
      return this.updatePaymentMethod ? this.$i18n.t('vue.updatePaymentMethod') : this.$i18n.t('vega.activateVega')
    }
  },
  created() {
    /* Localize validator */
    Validator.localize(this.$i18n.locale, validationDictionary[this.$i18n.locale])
  },
  async mounted() {
    this.$serverBus.$on('setup-mollie-components', (paymentType) => {
      if (paymentType === 'card') {
        //this.setupMollieComponents()
      }
    })

    this.$serverBus.$on('clear-payment-type', () => {
      this.paymentType = null
    })
    this.setupMollieComponents()
    setTimeout(() => {
      this.isLoadingInProccess = false
      this.isLoadingSuccessful = false
      this.isLoading = false
    }, 2000)
  },

  beforeDestroy() {
    if (this.mollie) {
      /**
       * Remove event listeners and unmount components
       */

      this.mollieCardComponents.cardHolder.removeEventListener('change')
      this.mollieCardComponents.cardHolder.unmount()
      this.mollieCardComponents.cardHolder.removeEventListener('focus')
      this.mollieCardComponents.cardHolder.removeEventListener('blur')

      this.mollieCardComponents.cardNumber.removeEventListener('change')
      this.mollieCardComponents.cardNumber.unmount()
      this.mollieCardComponents.cardNumber.removeEventListener('focus')
      this.mollieCardComponents.cardNumber.removeEventListener('blur')

      this.mollieCardComponents.cardExpiryDate.removeEventListener('change')
      this.mollieCardComponents.cardExpiryDate.unmount()
      this.mollieCardComponents.cardExpiryDate.removeEventListener('focus')
      this.mollieCardComponents.cardExpiryDate.removeEventListener('blur')

      this.mollieCardComponents.cardVerificationCode.removeEventListener('change')
      this.mollieCardComponents.cardVerificationCode.unmount()
      this.mollieCardComponents.cardVerificationCode.removeEventListener('focus')
      this.mollieCardComponents.cardVerificationCode.removeEventListener('blur')

      /**
       * Clean up mollie components
       */
      this.mollieCardComponents.cardHolder = null
      this.mollieCardComponents.cardNumber = null
      this.mollieCardComponents.cardExpiryDate = null
      this.mollieCardComponents.cardVerificationCode = null

      /**
       * Clean up mollie instance
       */
      this.mollie = null
    }
    this.$serverBus.$off('setup-mollie-components')
    this.$serverBus.$off('clear-payment-type')
  },

  watch: {
    paymentType() {
      if (this.paymentType !== 'card') {
        this.$nextTick(() => {
          this.errors.clear()
          this.$validator.reset()
        })
      }
    }
  },

  methods: {
    ...mapMutations({
      setPaymentMethod: 'SET_PAYMENT_METHOD',
      updateShowChangePlanUI: 'UPDATE_SHOW_CHANGE_PLAN_UI',
      setShowMollieVega: 'SET_SHOW_MOLLIE_VEGA'
    }),

    hasNumber(code) {
      return /\d/.test(code)
    },

    showPaymentType(type) {
      if (this.paymentType === type) {
        return
      }
      this.paymentType = type
      this.setPaymentMethod(type)

      switch (this.paymentType) {
        case 'paypal':
          this.loaderSpinnerText = `Redirecting you to ${this.paymentType}...`
          this.isLoading = true
          this.isLoadingInProccess = true
          this.onMolliePayPal()
          break

        default:
          break
      }
    },

    onDisplayError(message) {
      this.$vs.notify({
        title: this.$i18n.t('vue.error'),
        text: this.$i18n.t(message),
        color: 'danger',
        time: 10000
      })
    },
    initializeMollie() {
      const mollieLocale = this.getMollieLocale()
      this.mollie = window.Mollie(this.$mollieProfileId, { locale: mollieLocale, testmode: this.$mollieTestMode })
    },
    getMollieLocale() {
      const locale = this.$i18n.locale
      let mollieLocale = 'en_US'
      switch (locale) {
        case 'en':
          mollieLocale = 'en_US'
          break

        case 'de':
          mollieLocale = 'de_DE'
          break
      }
      return mollieLocale
    },
    async onMolliePayPal() {
      const mollieCreateNewVegaPayment = this.$functions.httpsCallable('mollieCreateNewVegaPayment')
      const payment = {
        customerName: this.activeUserInfo.displayName || `${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}`,
        customerEmail: this.activeUserInfo.email,
        locale: this.getMollieLocale(),
        metadata: {
          companyId: this.activeUserInfo.company,
          userId: this.activeUserInfo.uid,
          type: this.updatePaymentMethod ? 'update_payment_method' : 'new'
        },
        redirectUrl: `${location.protocol}//${location.host}${location.pathname}?source=vega`,
        sequenceType: 'first',
        method: 'paypal',
        description: 'Vega 0$ payment'
      }

      if (this.company.mollieCustomerId) {
        payment.mollieCustomerId = this.company.mollieCustomerId
        payment.metadata.customerId = this.company.mollieCustomerId
      }

      await this.$vs.loading()
      const transaction = await mollieCreateNewVegaPayment(payment)

      if (transaction && transaction.data && transaction.data.error) {
        await this.$vs.loading.close()
        this.onDisplayError(transaction.data.message)
        return
      }

      if (transaction && transaction.data && transaction.data._links && transaction.data._links.checkout && transaction.data._links.checkout.href) {
        await this.$vs.loading.close()
        this.updateShowChangePlanUI(false)
        this.setShowMollieVega(false)
        window.open(transaction.data._links.checkout.href, '_self')
      }

      //this.$serverBus.$emit('close-mollie-form')
    },

    async onMollieCreditCardPayment() {
      this.loaderSpinnerText = 'Processing...'
      this.isLoading = true
      this.isLoadingInProccess = true
      const { token, error } = await this.mollie.createToken()
      if (error) {
        this.onDisplayError(error.message)

        setTimeout(() => {
          this.isLoadingInProccess = false
          this.isLoadingSuccessful = false
          this.isLoading = false
        }, 100)

        return
      }

      const mollieCreateNewVegaPayment = this.$functions.httpsCallable('mollieCreateNewVegaPayment')
      const payment = {
        customerName: this.activeUserInfo.displayName || `${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}`,
        customerEmail: this.activeUserInfo.email,
        locale: this.getMollieLocale(),
        metadata: {
          companyId: this.activeUserInfo.company,
          userId: this.activeUserInfo.uid,
          type: this.updatePaymentMethod ? 'update_payment_method' : 'new',
          vegaDailyLimit: this.vegaDailyLimit,
          vegaHasDailyLimit: this.vegaHasDailyLimit
        },
        redirectUrl: location.href,
        sequenceType: 'first',
        method: 'creditcard',
        cardToken: token,
        description: 'Vega 0$ payment'
      }

      if (this.company.mollieCustomerId) {
        payment.mollieCustomerId = this.company.mollieCustomerId
        payment.metadata.customerId = this.company.mollieCustomerId
      }

      const transaction = await mollieCreateNewVegaPayment(payment)
      if (transaction && transaction.data && transaction.data._links && transaction.data._links.checkout && transaction.data._links.checkout.href) {
        window.open(transaction.data._links.checkout.href, '_self')
        return
      }

      if (transaction && transaction.data && transaction.data.status === 'paid') {
        if (!this.updatePaymentMethod) {
          this.$serverBus.$emit('vega-funnel-paying-process', 'successful-card-payment')
        }

        if (this.isModal) {
          this.$serverBus.$emit('close-mollie-form', {
            transaction
          })
        }

        setTimeout(() => {
          this.isLoadingInProccess = false
          this.isLoadingSuccessful = true
        }, 2000)

        setTimeout(() => {
          this.isLoading = false
          this.isLoadingSuccessful = false
          this.loaderSpinnerText = ''
          this.updateShowChangePlanUI(false)
          this.setShowMollieVega(false)
        }, 4000)
      } else {
        const message = JSON.stringify(transaction.data)
        this.$serverBus.$emit('vega-funnel-paying-process', `failure-card-payment (${message})`)

        this.$vs.notify({
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('info.somethingWentWrong'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
      }
    },
    toggleFieldClass(elementClassObj) {
      const element = document.getElementById(elementClassObj.elementId)

      Object.keys(elementClassObj.toggleClassesObject).forEach((key) => {
        if (typeof elementClassObj.toggleClassesObject[key] !== 'boolean') {
          return
        }

        if (elementClassObj.toggleClassesObject[key]) {
          element.parentNode.classList.add(`is-${key}`)
        } else {
          element.parentNode.classList.remove(`is-${key}`)
        }
      })
    },
    async setupMollieComponents() {
      this.areMollieComponentsReady = false
      await this.initializeMollie()
      setTimeout(() => {
        if (this.mollie) {
          /**
           * Get elements
           */

          const options = {
            styles: {
              base: {
                color: '#878894',
                fontSize: '18px',
                '::placeholder': {
                  color: 'rgba(68, 68, 68, 0.2)'
                }
              },
              valid: {
                color: '#42b5ff'
              }
            }
          }

          /**
           * Create card holder input
           */
          this.mollieCardComponents.cardHolder = this.mollie.createComponent('cardHolder', options)
          this.mollieCardComponents.cardHolder.mount(this.$refs['card-holder'])
          const cardHolderError = this.$refs['card-holder-error']

          this.mollieCardComponents.cardHolder.addEventListener('change', (event) => {
            this.toggleFieldClass({
              elementId: 'card-holder',
              toggleClassesObject: event
            })
            if (event.error && event.touched) {
              cardHolderError.textContent = event.error
            } else {
              cardHolderError.textContent = ''
            }
          })

          /**
           * Create card number input
           */
          this.mollieCardComponents.cardNumber = this.mollie.createComponent('cardNumber', options)
          this.mollieCardComponents.cardNumber.mount(this.$refs['card-number'])

          const cardNumberError = this.$refs['card-number-error']

          this.mollieCardComponents.cardNumber.addEventListener('change', (event) => {
            this.toggleFieldClass({
              elementId: 'card-number',
              toggleClassesObject: event
            })
            if (event.error && event.touched) {
              cardNumberError.textContent = event.error
            } else {
              cardNumberError.textContent = ''
            }
          })

          /**
           * Create expiry date input
           */
          this.mollieCardComponents.cardExpiryDate = this.mollie.createComponent('expiryDate', options)
          this.mollieCardComponents.cardExpiryDate.mount(this.$refs['expiry-date'])

          const expiryDateError = this.$refs['expiry-date-error']

          this.mollieCardComponents.cardExpiryDate.addEventListener('change', (event) => {
            this.toggleFieldClass({
              elementId: 'expiry-date',
              toggleClassesObject: event
            })
            if (event.error && event.touched) {
              expiryDateError.textContent = event.error
            } else {
              expiryDateError.textContent = ''
            }
          })

          /**
           * Create verification code input
           */
          this.mollieCardComponents.cardVerificationCode = this.mollie.createComponent('verificationCode', options)
          this.mollieCardComponents.cardVerificationCode.mount(this.$refs['verification-code'])

          const verificationCodeError = this.$refs['verification-code-error']

          this.mollieCardComponents.cardVerificationCode.addEventListener('change', (event) => {
            this.toggleFieldClass({
              elementId: 'verification-code',
              toggleClassesObject: event
            })
            if (event.error && event.touched) {
              verificationCodeError.textContent = event.error
            } else {
              verificationCodeError.textContent = ''
            }
          })
          // Some extra listeners for styling purposes
          this.mollieCardComponents.cardHolder.addEventListener('focus', () => {
            this.toggleFieldClass({
              elementId: 'card-holder',
              toggleClassesObject: { focussed: true }
            })
          })

          this.mollieCardComponents.cardHolder.addEventListener('blur', () => {
            this.toggleFieldClass({
              elementId: 'card-holder',
              toggleClassesObject: { focussed: false }
            })
          })

          this.mollieCardComponents.cardNumber.addEventListener('focus', () => {
            this.toggleFieldClass({
              elementId: 'card-number',
              toggleClassesObject: { focussed: true }
            })
          })

          this.mollieCardComponents.cardNumber.addEventListener('blur', () => {
            this.toggleFieldClass({
              elementId: 'card-number',
              toggleClassesObject: { focussed: false }
            })
          })

          this.mollieCardComponents.cardExpiryDate.addEventListener('focus', () => {
            this.toggleFieldClass({
              elementId: 'expiry-date',
              toggleClassesObject: { focussed: true }
            })
          })
          this.mollieCardComponents.cardExpiryDate.addEventListener('blur', () => {
            this.toggleFieldClass({
              elementId: 'expiry-date',
              toggleClassesObject: { focussed: false }
            })
          })
          this.mollieCardComponents.cardVerificationCode.addEventListener('focus', () => {
            this.toggleFieldClass({
              elementId: 'verification-code',
              toggleClassesObject: { focussed: true }
            })
          })

          this.mollieCardComponents.cardVerificationCode.addEventListener('blur', () => {
            this.toggleFieldClass({
              elementId: 'verification-code',
              toggleClassesObject: { focussed: false }
            })
          })
        }
        this.isLoadingInProccess = false
        this.isLoadingSuccessful = true
        this.isLoading = false
        this.areMollieComponentsReady = true
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'Larsseit';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Regular.woff');
}
@font-face {
  font-family: 'Larsseit-Medium';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Medium.woff');
}
@font-face {
  font-family: 'Larsseit-Bold';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Bold.woff');
}
</style>
<style lang="scss">
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.mollie-checkout {
  font-feature-settings: 'ss01' on;
  font-family: 'Larsseit-Regular';

  @media (min-width: 576px) {
    min-width: 480px;
  }

  &__fields-container {
    padding: 33px 20px 26px 20px !important;
    margin: 0 !important;
    // margin-bottom: 50px !important;
    border: 1px solid #a3cde8;
    border-radius: 8px;
  }

  &_charging_info {
    padding: 30px;
    text-align: center;
    font-size: 1.1rem;
  }

  &__holder {
    margin-bottom: 32px;
    //padding: 33px 20px 26px 20px;
    // border: 1px solid #a3cde8;
    // border-radius: 8px;
    min-height: 370px;
    height: 100%;

    &--modal {
      overflow: hidden auto;
      max-height: 450px;
    }
  }
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.95);
    z-index: 99;

    &__loader {
      text-align: center;

      &__spinner {
        display: inline-block;
        width: 70px;
        height: 70px;
        border: 10px solid rgb(222, 241, 254);
        border-bottom-color: rgb(66, 181, 255);
        border-radius: 50%;
        animation: rotation 1s linear infinite;
      }

      &__title {
        margin-top: 28px;
        color: rgb(38, 38, 41);
        font-family: 'Larsseit-Bold';
        font-size: 28px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.155556px;
      }

      &__text {
        margin-top: 6px;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.111111px;
        color: rgb(83, 83, 91);
      }
    }
  }

  &__checkout {
    margin-bottom: 13px;
    &__caption {
      color: rgb(83, 83, 91);
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.1px;
      text-align: center;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 28px;

      @media (max-width: 575px) {
        flex-wrap: wrap;
        margin-top: 18px;
      }
    }

    &__button {
      flex: 1 1 auto;
      margin-left: 5px;
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      color: rgb(38, 38, 41);
      font-family: 'Larsseit-Bold';
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.0888889px;
      background: rgb(255, 255, 255);
      border: 1px solid rgb(217, 219, 221);
      box-shadow: 0px 2px 1px rgba(52, 53, 110, 0.0800422);
      border-radius: 8px;
      font-weight: bold;
      position: relative;
      overflow: hidden;
      @media (max-width: 575px) {
        margin-top: 10px;
        width: calc(50% - 5px);
      }
      &__wrapper {
        /* opacity: 0.5; */
        &.mollie-checkout__checkout__button__wrapper--not-active {
          opacity: 0.5;
        }
      }
      &__soon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        &__wrapper {
          background: rgba(130, 206, 255, 0.2);
          backdrop-filter: blur(1px);
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: 1;
        }
        span {
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: #262629;
          position: relative;
          z-index: 2;
        }
      }
      &.mollie-checkout__checkout__button--active {
        border-color: #a3cde8;
        background: #f1f9ff;
      }

      &:hover {
        cursor: pointer;
        background: #f1f9ff;
      }
      &--paypal {
        img {
          width: 75px;
          height: 20px;
        }
      }
      &--sepa {
        img:first-child {
          margin-bottom: 2.5px;
        }
      }
    }
  }
  &__next-button {
    display: flex;
    align-items: center;
    color: white;
    font-family: 'Larsseit-Bold';
    font-size: 20px;
    line-height: 18px;
    letter-spacing: -0.111111px;
    padding: 20px 43px;
    background: #3034f7;
    border: none;
    border-radius: 8px;
    margin-top: 50px;

    &:hover {
      cursor: pointer;
      background: rgba(48, 52, 247, 0.9);
    }

    &-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 50px;
    }

    &__icon {
      margin-left: 17px;
      width: 20px;
    }
  }
}

.wrapper {
  display: flex;
}

.wrapper form {
  width: 100%;
}

.row {
  display: flex;
  width: 100%;
  margin: 1px 0;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

/* form group */
.form-group {
  width: 100%;
  position: relative;
  margin: 0 8px;
  flex: 1;
}
.form-group.form-group--expiry-date {
  width: 100%;
  max-width: 263px;
}
.form-group.form-group--verification-code {
  width: 100%;
  max-width: 158px;
}

.row .form-group:first-child {
  margin-left: 0;
}

.row .form-group:nth-last-child(1) {
  margin-right: 0;
}

.form-group label {
  position: absolute;
  left: 20px;
  top: 0;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #a3b2c6;
  height: 60px;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 5;
  div {
    display: inline-flex;
  }
}

input:not(:placeholder-shown) ~ label,
input:focus ~ label,
.form-group--expiry-date label,
.form-group.is-dirty label,
.form-group.is-focussed label {
  font-size: 12px;
  transform: translateY(-50%);
  div {
    background: white;
    border-radius: 8px;
    padding: 0 5px;
  }
}

.form-input {
  outline: none;
  background-color: transparent;
  font-size: 18px;
}

.form-input,
.mollie-component {
  background: #eff1f3;
  min-height: 60px;
  border-radius: 8px;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.mollie-component::after {
  transition: 0.5s width cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #3a4;
  content: '';
  position: absolute;
  bottom: -1px;
  width: 0%;
  left: 0;
  border-bottom: 1px solid #ce0000;
}

.mollie-component.is-invalid::after {
  width: 100%;
}

.form-group.is-touched.is-valid .field-error {
  padding: 0;
}

.form-group.is-touched .field-error {
  color: #ce0000;
  font-weight: 400;
  padding: 1px 0;
  font-size: 10px;
}

button.submit-button {
  width: 100%;
  padding: 14px;
  color: #162941;
  font-weight: 600;
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  outline: 0;
  transition: 0.3s opacity cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid #162941;
  background-color: transparent;
}

.form-fields {
  margin-bottom: 32px;
  padding: 33px 0 0 0;
  margin-top: -33px;
}
</style>
