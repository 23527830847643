<template>
  <transition name="fade">
    <div class="company-details">
      <div v-if="isLoading" class="mollie-checkout__loading">
        <div v-if="isLoadingInProccess" class="mollie-checkout__loading__loader">
          <span class="mollie-checkout__loading__loader__spinner" />
          <div class="mollie-checkout__loading__loader__text">{{ loaderSpinnerText }}</div>
        </div>
      </div>

      <div class="company-details-heading">
        {{ $t('choosePricingPlan.enterCompanyDetails') }}
      </div>
      <div class="company-details-form">
        <div v-if="showVatNumber" class="company-details-form-vatinfo">
          <div class="company-details-form-label">{{ $t('inputs.vatNumber') }}*:</div>
          <div>
            <input
              type="text"
              v-validate="{ rules: { required: this.isVatNumberRequired, checkvatvalid: this.checkIfVatValid } }"
              name="vatnumber"
              class="company-details-form-textinput company-details-form-textinput--full"
              v-model.lazy="account.vatNumber"
              @change="onVatNumberChanged"
            />
            <span v-if="errors.first('vatnumber')" class="company-details-form-error">
              {{ errors.first('vatnumber') }}
            </span>
          </div>
          <div class="company-details-form-mt7">
            <input type="checkbox" v-model="account.doNotHaveVatNumber" @change="onDonotHaveVatNumberChanged" class="company-details-form-checkbox" />
            <span class="company-details-form-ml7"> {{ $t('choosePricingPlan.doNotHaveVatNumber') }}</span>
          </div>
        </div>
        <div class="company-details-form-inputs">
          <scroll-view style="max-height: 450px">
            <div class="company-details-form-label">{{ $t('inputs.title') }}*:</div>
            <div>
              <select
                v-validate="'requiredSelect'"
                class="company-details-form-select company-details-form-select--title"
                :class="{ 'company-details-form-select--disabled': account.title === -1 }"
                name="title"
                v-model="account.title"
                @change="onValidate"
              >
                <option disabled :value="-1" selected>{{ $t('choosePricingPlan.pleaseSelect') }}</option>
                <option v-for="option in titles" :value="option.value" :key="option.value">{{ option.text }}</option>
              </select>
            </div>

            <div class="company-details-form-label">{{ $t('inputs.academicTitle') }}</div>
            <div>
              <input type="text" class="company-details-form-textinput company-details-form-textinput--full" v-model="account.academicTitle" />
            </div>

            <div class="company-details-form-group">
              <div class="company-details-form-fitcontent">
                <div class="company-details-form-label">{{ $t('inputs.firstName') }}*:</div>
                <div>
                  <input
                    type="text"
                    v-validate="'required'"
                    name="fname"
                    class="company-details-form-textinput"
                    v-model="account.firstname"
                    @change="onValidate"
                  />
                </div>
              </div>
              <div class="company-details-form-full company-details-form-spacebtw">
                <div class="company-details-form-label">{{ $t('inputs.lastName') }}*:</div>
                <div>
                  <input
                    type="text"
                    v-validate="'required'"
                    class="company-details-form-textinput company-details-form-textinput--full"
                    v-model="account.lastname"
                    name="lname"
                    @change="onValidate"
                  />
                </div>
              </div>
            </div>

            <div class="company-details-form-label">{{ $t('inputs.companyName') }}*:</div>
            <div>
              <input
                type="text"
                v-validate="'required'"
                name="cname"
                class="company-details-form-textinput company-details-form-textinput--full"
                v-model="account.name"
                @change="onValidate"
              />
            </div>

            <div class="company-details-form-label">{{ $t('inputs.streetAddress') }}*</div>
            <div>
              <input
                type="text"
                v-validate="'required'"
                name="street"
                class="company-details-form-textinput company-details-form-textinput--full"
                v-model="account.street"
                @change="onValidate"
              />
            </div>
            <div class="company-details-form-group company-details-form-pb7">
              <div class="company-details-form-50">
                <div class="company-details-form-label">{{ $t('inputs.city') }}*:</div>
                <div>
                  <input
                    type="text"
                    v-validate="'required'"
                    name="city"
                    class="company-details-form-textinput company-details-form-textinput--full"
                    v-model="account.city"
                    @change="onValidate"
                  />
                </div>
              </div>
              <div class="company-details-form-50 company-details-form-spacebtw">
                <div class="company-details-form-label">{{ $t('inputs.zipcode') }}*:</div>
                <div>
                  <input
                    type="text"
                    v-validate="'required'"
                    name="zipcode"
                    class="company-details-form-textinput company-details-form-textinput--full"
                    v-model="account.zipcode"
                    @change="onValidate"
                  />
                </div>
              </div>
            </div>
          </scroll-view>
        </div>
      </div>

      <div class="company-details-action" :class="{ 'company-details-action--disabled': !formValidated }" @click="onClickNextStep">
        {{ $t('vue.nextStep') }}

        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.4223 0.440632L19.5795 7.94073C19.8613 8.23136 20 8.61574 20 9.00012C20 9.3845 19.8602 9.76794 19.5806 10.0604L12.4234 17.5605C11.8642 18.1465 10.9584 18.1465 10.3992 17.5605C9.84008 16.9746 9.84008 16.0254 10.3992 15.4394L15.1152 10.5001H1.39119C0.599866 10.5001 0 9.82841 0 9.04231C0 8.2562 0.599866 7.5001 1.39119 7.5001L15.1152 7.5001L10.4004 2.55941C9.8412 1.97347 9.8412 1.02423 10.4004 0.438288C10.9595 -0.147657 11.8631 -0.145313 12.4223 0.440632Z"
            :fill="actionButtonIconColor"
          />
        </svg>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { mpayment } from '@/mixins/mpayment'
import { Validator } from 'vee-validate'
// Merge all defined languages in the dictionary and sets the current locale.
import validationDictionary from '@/validations/validationDictionary'
import ScrollView from '@blackbp/vue-smooth-scrollbar'
import VerticalNavMenuGroupVue from '../../layouts/components/vertical-nav-menu/VerticalNavMenuGroup.vue'

export default {
  name: 'EnterCompanyDetails',
  mixins: [mpayment],
  components: {
    ScrollView
  },

  data() {
    return {
      account: { title: -1, vatNumber: '', doNotHaveVatNumber: false },
      formValidated: true,
      countryCodes: require('@/assets/countryCodes.json'),
      hasUpdatedCompanyFromVatValidation: false
    }
  },
  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser'
    }),

    showVatNumber() {
      const countryCode = this.company && this.company.country && this.company.country.code ? this.company.country.code : null
      if (!countryCode) return false
      const search = this.countryCodes.find((x) => x.code === countryCode)

      if (!search || typeof search.vatPercent === 'undefined') return false
      return true
    },

    isVatNumberRequired() {
      if (this.account.doNotHaveVatNumber) {
        return false
      }
      return true
    },

    actionButtonIconColor() {
      return this.formValidated ? '#42B5FF' : '#000000'
    },
    titles() {
      return [
        {
          text: this.$i18n.t('vue.mr'),
          value: 'mr'
        },
        {
          text: this.$i18n.t('vue.mrs'),
          value: 'mrs'
        }
      ]
    }
  },

  watch: {
    async 'account.doNotHaveVatNumber'() {
      if (this.account.doNotHaveVatNumber) {
        this.account.vatNumber = ''
        this.updateAccountVatNumber(null)
      }
      setTimeout(() => {
        this.onValidate()
      }, 20)
    },

    'account.vatNumber'() {
      if (this.account && this.account.vatNumber && this.account.vatNumber.trim().length > 0) {
        this.account.doNotHaveVatNumber = false
      }
    }
  },

  created() {
    /* Localize validator */
    Validator.localize(this.$i18n.locale, validationDictionary[this.$i18n.locale])
  },
  mounted() {
    if (this.company && this.activeUserInfo) {
      this.account.vatNumber = this.company.vatNumber || null
      this.updateAccountVatNumber(this.account.vatNumber)
      this.account.doNotHaveVatNumber = this.company.doNotHaveVatNumber || false

      this.account.title = this.activeUserInfo.title || -1
      this.account.academicTitle = this.activeUserInfo.academicTitle || null
      this.account.firstname = this.activeUserInfo.firstname || null
      this.account.lastname = this.activeUserInfo.lastname || null
      this.account.name = this.company.name || null
      this.account.street = this.company.street || null
      this.account.city = this.company.city || null
      this.account.zipcode = this.company.zipcode || null
    }

    Validator.extend(
      'checkvatvalid',
      {
        validate: this.checkIfVatValid,
        getMessage: (field, params, data) => data.message
      },
      {
        immediate: false
      }
    )
    this.$nextTick(() => {
      this.errors.clear()
      this.$validator.reset()
    })
  },

  methods: {
    ...mapMutations({
      updateAccountVatNumber: 'UPDATE_ACCOUNT_VATNUMBER',
      setPaymentMethod: 'SET_PAYMENT_METHOD'
    }),
    hasNumber(code) {
      return /\d/.test(code)
    },
    vatHasCountryCode() {
      if (!this.account || !this.account.vatNumber || this.account.vatNumber.length < 2) return false
      const vat = this.account.vatNumber.toLowerCase()
      const countryCodeVat = vat.substr(0, 2)
      /* Return false if country code has digits */
      if (this.hasNumber(countryCodeVat)) return false

      const countryCode = this.countryCodes.find((x) => x.code === countryCodeVat)

      if (!countryCode) {
        return false
      }
      return true
    },

    async checkIfVatValid() {
      const vm = this
      vm.loadCustomSpinner(true, this.$i18n.t('vue.validatingVAT'))
      vm.formValidated = await this.$validator.validateAll(['title', 'fname', 'lname', 'cname', 'street', 'city', 'zipcode'])

      return new Promise((resolve) => {
        if (this.account.doNotHaveVatNumber) {
          vm.loadCustomSpinner(false)
          return resolve({
            valid: true
          })
        }

        if (!this.account.doNotHaveVatNumber && (!this.account.vatNumber || this.account.vatNumber.trim().length === 0)) {
          vm.loadCustomSpinner(false)
          vm.formValidated = false
          return resolve({
            valid: false,
            data: {
              message: vm.$i18n.t('error.VV_003')
            }
          })
        }

        if (!vm.vatHasCountryCode()) {
          vm.loadCustomSpinner(false)
          vm.formValidated = false
          return resolve({
            valid: false,
            data: {
              message: vm.$i18n.t('error.VV_002')
            }
          })
        }

        if (!vm.company || (vm.company && vm.company.name && vm.company.name.trim().length === 0)) {
          vm.loadCustomSpinner(false)
          vm.formValidated = false
          return resolve({
            valid: false,
            data: {
              message: vm.$i18n.t('error.VV_004')
            }
          })
        }

        if (!vm.company.country || !vm.company.country.code) {
          vm.loadCustomSpinner(false)
          vm.formValidated = false
          return resolve({
            valid: false,
            data: {
              message: vm.$i18n.t('error.VV_001')
            }
          })
        }

        if (!vm.account.vatNumber || vm.account.vatNumber.length <= 2) {
          vm.loadCustomSpinner(false)
          vm.formValidated = false
          return resolve({
            valid: false,
            data: {
              message: vm.$i18n.t('error.VV_003')
            }
          })
        }

        const axios = require('axios')
        const vatNumber = vm.account.vatNumber.substring(2)
        const companyName = encodeURI(vm.company.name)

        axios
          .get(`${vm.$functionsURL}/validateVat?cc=${vm.company.country.code}&vat=${vatNumber}&cp=${companyName}`)
          .then(async function (response) {
            if (response && response.data && response.data.success) {
              if (!vm.hasUpdatedCompanyFromVatValidation) {
                if (response.data.companyName && response.data.companyName !== '---') {
                  vm.account.name = response.data.companyName
                } else {
                  vm.account.name = ''
                }
                if (response.data.address && response.data.address !== '---') {
                  const comma = response.data.address.indexOf(',')
                  if (comma > -1) {
                    vm.account.street = response.data.address.substring(0, comma)
                    const remainAddress = response.data.address.substring(comma).trim()
                    if (remainAddress) {
                      const zipcodeIndex = remainAddress.indexOf('-')
                      const zipcodeSpaceIndex = remainAddress.indexOf(' ', zipcodeIndex)
                      if (zipcodeIndex > -1 && zipcodeSpaceIndex > -1) {
                        vm.account.zipcode = remainAddress.substring(zipcodeIndex + 1, zipcodeSpaceIndex)
                      }
                    }
                  }
                  const cityIndex = response.data.address.lastIndexOf(' ')
                  if (cityIndex > -1) {
                    vm.account.city = response.data.address.substring(cityIndex).trim()
                  }
                }
              }
              vm.hasUpdatedCompanyFromVatValidation = true
              vm.loadCustomSpinner(false)
              vm.formValidated = await vm.$validator.validateAll(['title', 'fname', 'lname', 'cname', 'street', 'city', 'zipcode'])
              return resolve({
                valid: true
              })
            }
            if (response && response.data && response.data.error) {
              vm.loadCustomSpinner(false)
              if (!vm.isVatNumberRequired) {
                vm.formValidated = await vm.$validator.validateAll(['title', 'fname', 'lname', 'cname', 'street', 'city', 'zipcode'])
                return resolve({
                  valid: true
                })
              }
              vm.formValidated = false
              return resolve({
                valid: false,
                data: {
                  message: vm.$i18n.t(response.data.error)
                }
              })
            }
            vm.loadCustomSpinner(false)
          })
          .catch(async function (error) {
            vm.loadCustomSpinner(false)
            if (!vm.isVatNumberRequired) {
              vm.formValidated = await VerticalNavMenuGroupVue.$validator.validateAll(['title', 'fname', 'lname', 'cname', 'street', 'city', 'zipcode'])
              return resolve({
                valid: true
              })
            }
            vm.formValidated = false
            return resolve({
              valid: false,
              data: {
                message: error.message
              }
            })
          })
      })
    },
    async onVatNumberChanged() {
      this.account.vatNumber = this.account.vatNumber.replace(/\s/g, '')
      this.updateAccountVatNumber(this.account.vatNumber)
    },
    async onDonotHaveVatNumberChanged() {
      this.formValidated = await this.$validator.validateAll(['title', 'fname', 'lname', 'cname', 'street', 'city', 'zipcode'])
    },
    async onValidate() {
      this.formValidated = await this.$validator.validateAll(['title', 'fname', 'lname', 'cname', 'street', 'city', 'zipcode'])
    },
    async onClickNextStep() {
      const result = await this.$validator.validateAll(['title', 'fname', 'lname', 'cname', 'street', 'city', 'zipcode', 'vatnumber'])
      this.formValidated = result
      if (result) {
        this.setPaymentMethod(null)
        this.loadCustomSpinner(true, this.$i18n.t('promocode.processing'))

        const _user = {
          firstname: this.account.firstname,
          lastname: this.account.lastname,
          title: this.account.title
        }
        if (this.account.academicTitle) {
          _user.academicTitle = this.account.academicTitle
        }

        const _account = {
          name: this.account.name,
          street: this.account.street,
          city: this.account.city,
          zipcode: this.account.zipcode
        }
        if (this.account.vatNumber && !this.account.doNotHaveVatNumber) {
          _account.vatNumber = this.account.vatNumber
          _account.doNotHaveVatNumber = this.account.doNotHaveVatNumber
        }
        if (!this.account.vatNumber && this.account.doNotHaveVatNumber) {
          _account.vatNumber = this.$firebase.firestore.FieldValue.delete()
          _account.doNotHaveVatNumber = this.account.doNotHaveVatNumber
        }
        await this.$db.collection('users').doc(this.activeUserInfo.uid).set(_user, { merge: true })
        await this.$db.collection('company').doc(this.activeUserInfo.company).set(_account, { merge: true })
        this.loadCustomSpinner(false)
        this.$emit('go-to-next-step')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.company-details {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-bottom: 50px;

  &-heading {
    margin-top: 45px;
    font-family: 'Larsseit';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.177778px;
    color: #262629;
  }

  &-form {
    background: #ffffff;
    border: 1px solid #a3cde8;
    box-shadow: 0px 2px 1px rgba(66, 181, 255, 0.263221);
    border-radius: 8px;
    width: 480px;
    margin-top: 40px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &-error {
      color: rgb(240, 85, 65);
      font-size: 11px;
    }

    &-mt7 {
      margin-top: 7px;
    }
    &-ml7 {
      margin-left: 7px;
    }
    &-spacebtw {
      margin-left: 16px;
    }
    &-pb7 {
      padding-bottom: 7px;
    }

    &-group {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    &-fitcontent {
      width: fit-content;
    }

    &-50 {
      width: 50%;
    }

    &-full {
      width: 100%;
    }

    &-select {
      padding: 10px;
      background: #eff1f3;
      border-radius: 8px;

      &--title {
        width: 135px;
      }

      &--disabled {
        color: #8b8b8b;
      }
    }

    &-label {
      font-family: 'Larsseit';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.0777778px;
      color: #878894;
      margin-top: 7px;
    }

    &-textinput {
      height: 50px;
      border: none;
      background: #eff1f3;
      border-radius: 8px;
      padding: 10px 20px 10px 20px;

      &--full {
        width: 100%;
      }
      &--40 {
        width: 40%;
      }
      &--60 {
        width: 60%;
      }
    }

    &-vatinfo {
      border-bottom: 1px solid #a3cde8;
      display: flex;
      flex-direction: column;
      padding: 20px 17px 18px 20px;
      width: 100%;
    }

    &-inputs {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 18px 17px 20px 20px;
    }
  }

  &-action {
    margin-top: 60px;
    background: #3034f7;
    border-radius: 8px;
    width: 193px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Larsseit';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 19px;
    letter-spacing: -0.111111px;
    color: #ffffff;

    cursor: pointer;

    &--disabled {
      background: #d3d3d3;
      cursor: default;
    }

    svg {
      margin-left: 20px;
    }
  }

  @media only screen and (max-width: 800px) {
    &-form {
      width: 100%;
      height: 100%;
    }
    &-heading {
      margin-top: 45px;
    }
    &-hidden {
      display: none;
    }
    &-action {
      width: 100%;
    }

    &-form {
      &-group {
        flex-direction: column;
      }
      &-fitcontent {
        width: 100%;
      }
      &-textinput {
        width: 100%;
      }
      &-spacebtw {
        margin-left: 0px;
      }
      &-50 {
        width: 100%;
      }
    }
  }
}
</style>
