/* eslint-disable */
import i18n from '../../../i18n/i18n'
i18n.locale = navigator.language.slice(0, 2)

export default {
  pages: {
    key: 'title',
    data: [
      { title: i18n.t('routes.home'), url: '/dashboard', icon: 'HomeIcon', is_bookmarked: false },
      { title: i18n.t('routes.visitors'), url: '/visitors', icon: 'MonitorIcon', is_bookmarked: false },
      { title: i18n.t('routes.onlineMeetings'), url: '/online-meeting', icon: 'MousePointerIcon', is_bookmarked: false },
      { title: i18n.t('routes.dialog'), url: '/web-connect', icon: 'CodeIcon', is_bookmarked: false },
      { title: i18n.t('routes.companies'), url: '/companies', icon: 'GridIcon', is_bookmarked: false },
      //{ title: i18n.t('routes.abtesting'), url: '/ab-testing', icon: 'SlidersIcon', is_bookmarked: false },
      { title: i18n.t('routes.leadoverview'), url: '/lead-overview', icon: 'TrendingUpIcon', is_bookmarked: false }
    ]
  }
}
/* eslint-enable */
