import Vue from 'vue'
import VueI18n from 'vue-i18n'
const messages = {
  en: {
    ...require('@/locales/en.json')
  },
  de: {
    ...require('@/locales/de.json')
  }
}

Vue.use(VueI18n)

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'de',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
  messages,
  silentTranslationWarn: true
})
