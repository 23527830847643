var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c("div", { staticClass: "company-details" }, [
      _vm.isLoading
        ? _c("div", { staticClass: "mollie-checkout__loading" }, [
            _vm.isLoadingInProccess
              ? _c("div", { staticClass: "mollie-checkout__loading__loader" }, [
                  _c("span", {
                    staticClass: "mollie-checkout__loading__loader__spinner",
                  }),
                  _c(
                    "div",
                    { staticClass: "mollie-checkout__loading__loader__text" },
                    [_vm._v(_vm._s(_vm.loaderSpinnerText))]
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _c("div", { staticClass: "company-details-heading" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("choosePricingPlan.enterCompanyDetails")) +
            "\n    "
        ),
      ]),
      _c("div", { staticClass: "company-details-form" }, [
        _vm.showVatNumber
          ? _c("div", { staticClass: "company-details-form-vatinfo" }, [
              _c("div", { staticClass: "company-details-form-label" }, [
                _vm._v(_vm._s(_vm.$t("inputs.vatNumber")) + "*:"),
              ]),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        rules: {
                          required: this.isVatNumberRequired,
                          checkvatvalid: this.checkIfVatValid,
                        },
                      },
                      expression:
                        "{ rules: { required: this.isVatNumberRequired, checkvatvalid: this.checkIfVatValid } }",
                    },
                    {
                      name: "model",
                      rawName: "v-model.lazy",
                      value: _vm.account.vatNumber,
                      expression: "account.vatNumber",
                      modifiers: { lazy: true },
                    },
                  ],
                  staticClass:
                    "company-details-form-textinput company-details-form-textinput--full",
                  attrs: { type: "text", name: "vatnumber" },
                  domProps: { value: _vm.account.vatNumber },
                  on: {
                    change: [
                      function ($event) {
                        return _vm.$set(
                          _vm.account,
                          "vatNumber",
                          $event.target.value
                        )
                      },
                      _vm.onVatNumberChanged,
                    ],
                  },
                }),
                _vm.errors.first("vatnumber")
                  ? _c("span", { staticClass: "company-details-form-error" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.errors.first("vatnumber")) +
                          "\n          "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "company-details-form-mt7" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.account.doNotHaveVatNumber,
                      expression: "account.doNotHaveVatNumber",
                    },
                  ],
                  staticClass: "company-details-form-checkbox",
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.account.doNotHaveVatNumber)
                      ? _vm._i(_vm.account.doNotHaveVatNumber, null) > -1
                      : _vm.account.doNotHaveVatNumber,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.account.doNotHaveVatNumber,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.account,
                                "doNotHaveVatNumber",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.account,
                                "doNotHaveVatNumber",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.account, "doNotHaveVatNumber", $$c)
                        }
                      },
                      _vm.onDonotHaveVatNumberChanged,
                    ],
                  },
                }),
                _c("span", { staticClass: "company-details-form-ml7" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("choosePricingPlan.doNotHaveVatNumber"))
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "company-details-form-inputs" },
          [
            _c("scroll-view", { staticStyle: { "max-height": "450px" } }, [
              _c("div", { staticClass: "company-details-form-label" }, [
                _vm._v(_vm._s(_vm.$t("inputs.title")) + "*:"),
              ]),
              _c("div", [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "requiredSelect",
                        expression: "'requiredSelect'",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.account.title,
                        expression: "account.title",
                      },
                    ],
                    staticClass:
                      "company-details-form-select company-details-form-select--title",
                    class: {
                      "company-details-form-select--disabled":
                        _vm.account.title === -1,
                    },
                    attrs: { name: "title" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.account,
                            "title",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                        _vm.onValidate,
                      ],
                    },
                  },
                  [
                    _c(
                      "option",
                      {
                        attrs: { disabled: "", selected: "" },
                        domProps: { value: -1 },
                      },
                      [_vm._v(_vm._s(_vm.$t("choosePricingPlan.pleaseSelect")))]
                    ),
                    _vm._l(_vm.titles, function (option) {
                      return _c(
                        "option",
                        {
                          key: option.value,
                          domProps: { value: option.value },
                        },
                        [_vm._v(_vm._s(option.text))]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "company-details-form-label" }, [
                _vm._v(_vm._s(_vm.$t("inputs.academicTitle"))),
              ]),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.account.academicTitle,
                      expression: "account.academicTitle",
                    },
                  ],
                  staticClass:
                    "company-details-form-textinput company-details-form-textinput--full",
                  attrs: { type: "text" },
                  domProps: { value: _vm.account.academicTitle },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.account,
                        "academicTitle",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "company-details-form-group" }, [
                _c("div", { staticClass: "company-details-form-fitcontent" }, [
                  _c("div", { staticClass: "company-details-form-label" }, [
                    _vm._v(_vm._s(_vm.$t("inputs.firstName")) + "*:"),
                  ]),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.account.firstname,
                          expression: "account.firstname",
                        },
                      ],
                      staticClass: "company-details-form-textinput",
                      attrs: { type: "text", name: "fname" },
                      domProps: { value: _vm.account.firstname },
                      on: {
                        change: _vm.onValidate,
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.account,
                            "firstname",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "company-details-form-full company-details-form-spacebtw",
                  },
                  [
                    _c("div", { staticClass: "company-details-form-label" }, [
                      _vm._v(_vm._s(_vm.$t("inputs.lastName")) + "*:"),
                    ]),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.account.lastname,
                            expression: "account.lastname",
                          },
                        ],
                        staticClass:
                          "company-details-form-textinput company-details-form-textinput--full",
                        attrs: { type: "text", name: "lname" },
                        domProps: { value: _vm.account.lastname },
                        on: {
                          change: _vm.onValidate,
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.account,
                              "lastname",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "company-details-form-label" }, [
                _vm._v(_vm._s(_vm.$t("inputs.companyName")) + "*:"),
              ]),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.account.name,
                      expression: "account.name",
                    },
                  ],
                  staticClass:
                    "company-details-form-textinput company-details-form-textinput--full",
                  attrs: { type: "text", name: "cname" },
                  domProps: { value: _vm.account.name },
                  on: {
                    change: _vm.onValidate,
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.account, "name", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "company-details-form-label" }, [
                _vm._v(_vm._s(_vm.$t("inputs.streetAddress")) + "*"),
              ]),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.account.street,
                      expression: "account.street",
                    },
                  ],
                  staticClass:
                    "company-details-form-textinput company-details-form-textinput--full",
                  attrs: { type: "text", name: "street" },
                  domProps: { value: _vm.account.street },
                  on: {
                    change: _vm.onValidate,
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.account, "street", $event.target.value)
                    },
                  },
                }),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "company-details-form-group company-details-form-pb7",
                },
                [
                  _c("div", { staticClass: "company-details-form-50" }, [
                    _c("div", { staticClass: "company-details-form-label" }, [
                      _vm._v(_vm._s(_vm.$t("inputs.city")) + "*:"),
                    ]),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.account.city,
                            expression: "account.city",
                          },
                        ],
                        staticClass:
                          "company-details-form-textinput company-details-form-textinput--full",
                        attrs: { type: "text", name: "city" },
                        domProps: { value: _vm.account.city },
                        on: {
                          change: _vm.onValidate,
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.account, "city", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "company-details-form-50 company-details-form-spacebtw",
                    },
                    [
                      _c("div", { staticClass: "company-details-form-label" }, [
                        _vm._v(_vm._s(_vm.$t("inputs.zipcode")) + "*:"),
                      ]),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.account.zipcode,
                              expression: "account.zipcode",
                            },
                          ],
                          staticClass:
                            "company-details-form-textinput company-details-form-textinput--full",
                          attrs: { type: "text", name: "zipcode" },
                          domProps: { value: _vm.account.zipcode },
                          on: {
                            change: _vm.onValidate,
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.account,
                                "zipcode",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "company-details-action",
          class: { "company-details-action--disabled": !_vm.formValidated },
          on: { click: _vm.onClickNextStep },
        },
        [
          _vm._v("\n      " + _vm._s(_vm.$t("vue.nextStep")) + "\n\n      "),
          _c(
            "svg",
            {
              attrs: {
                width: "20",
                height: "18",
                viewBox: "0 0 20 18",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M12.4223 0.440632L19.5795 7.94073C19.8613 8.23136 20 8.61574 20 9.00012C20 9.3845 19.8602 9.76794 19.5806 10.0604L12.4234 17.5605C11.8642 18.1465 10.9584 18.1465 10.3992 17.5605C9.84008 16.9746 9.84008 16.0254 10.3992 15.4394L15.1152 10.5001H1.39119C0.599866 10.5001 0 9.82841 0 9.04231C0 8.2562 0.599866 7.5001 1.39119 7.5001L15.1152 7.5001L10.4004 2.55941C9.8412 1.97347 9.8412 1.02423 10.4004 0.438288C10.9595 -0.147657 11.8631 -0.145313 12.4223 0.440632Z",
                  fill: _vm.actionButtonIconColor,
                },
              }),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }