var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "order-details" }, [
    _c("div", { staticClass: "order-details--heading" }, [
      _vm._v(_vm._s(_vm.$t("choosePricingPlan.orderDetails"))),
    ]),
    _c("div", { staticClass: "order-details__summary-container" }, [
      _c(
        "div",
        { staticClass: "order-details__summary-container--logo" },
        [_c("Logo")],
        1
      ),
      _c("div", { staticClass: "order-details__summary-container__price" }, [
        _c(
          "div",
          { staticClass: "order-details__summary-container__price--title" },
          [_vm._v(_vm._s(_vm.planName))]
        ),
        _c(
          "div",
          { staticClass: "order-details__summary-container__price--price" },
          [_vm._v(_vm._s(_vm.totalAmountWithSymbol))]
        ),
      ]),
    ]),
    _c("div", { staticClass: "order-details__price_container" }, [
      _c("div", { staticClass: "order-details__price" }, [
        _c("div", { staticClass: "order-details__price--title" }, [
          _vm._v(_vm._s(_vm.$t("choosePricingPlan.subtotal"))),
        ]),
        _c("div", { staticClass: "order-details__price--amount" }, [
          _vm._v(_vm._s(_vm.planPriceWithSymbol)),
        ]),
      ]),
      _vm.discountedAmount > 0
        ? _c("div", { staticClass: "order-details__price" }, [
            _c("div", { staticClass: "order-details__price--title" }, [
              _vm._v(_vm._s(_vm.$t("choosePricingPlan.couponDiscount"))),
            ]),
            _c("div", { staticClass: "order-details__price--amount" }, [
              _vm._v(_vm._s(_vm.discountedAmountWithSymbol)),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "order-details__price" }, [
        _c("div", { staticClass: "order-details__price--title" }, [
          _vm._v(
            _vm._s(_vm.$t("choosePricingPlan.vat")) +
              " " +
              _vm._s(_vm.vatPercent) +
              "%"
          ),
        ]),
        _c("div", { staticClass: "order-details__price--amount" }, [
          _vm._v(_vm._s(_vm.vatAmountWithSymbol)),
        ]),
      ]),
      _c("div", { staticClass: "order-details__total" }, [
        _c("div", { staticClass: "order-details__total--title" }, [
          _vm._v(_vm._s(_vm.$t("choosePricingPlan.total"))),
        ]),
        _c("div", { staticClass: "order-details__total--amount" }, [
          _vm._v(_vm._s(_vm.totalAmountWithSymbol)),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }