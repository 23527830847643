/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
/* eslint-disable */

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
const functions = firebase.app().functions('europe-west1')
import router from '@/router'
import moment from 'moment'
// i18n
import i18n from '../../i18n/i18n'
i18n.locale = navigator.language.slice(0, 2)

import { Device } from '@capacitor/device'
import { PushNotifications } from '@capacitor/push-notifications'
import { Preferences } from '@capacitor/preferences'

const errorTimeMS = 8800

export default {
  async sendSignInLink({ dispatch }, payload) {
    if (!payload.password) {
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be whitelisted in the Firebase Console.
        url: `${window.location.protocol}//${window.location.host}/pages/login`,
        // This must be true.
        handleCodeInApp: true
      }
      await firebase.auth().sendSignInLinkToEmail(payload.email, actionCodeSettings)

      if (payload.notify) {
        payload.notify({
          time: 10000,
          title: i18n.t('vue.emailLinkSent'),
          text: i18n.t('vue.emailLinkSentMessage'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'green'
        })
      }

      if (payload.closeAnimation) payload.closeAnimation()
    } else {
      dispatch('login', payload)
    }
  },
  async loginAttempt({ dispatch }, payload) {
    // New payload for login action
    const newPayload = {
      userDetails: payload.userDetails,
      notify: payload.notify,
      closeAnimation: payload.closeAnimation,
      origin: payload.origin ? payload.origin : ''
    }

    // If remember_me is enabled change firebase Persistence
    if (!payload.checkbox_remember_me) {
      // Change firebase Persistence
      try {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        await dispatch('login', newPayload)

        if (payload.userDetails && payload.userDetails.user && payload.userDetails.user.uid) {
          await dispatch('setMobileNotifications', { uid: payload.userDetails.user.uid })
        }
      } catch (err) {
        if (payload.closeAnimation) payload.closeAnimation()
        payload.notify({
          time: errorTimeMS,
          title: i18n.t('vue.error'),
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    } else {
      // Try to login
      await dispatch('login', newPayload)

      if (payload.userDetails && payload.userDetails.user && payload.userDetails.user.uid) {
        await dispatch('setMobileNotifications', { uid: payload.userDetails.user.uid })
      }
    }
  },
  async checkLoggedInUser({ dispatch }, loggedInUser) {
    const userRef = await firebase.firestore().collection('users').doc(loggedInUser.uid).get()
    const firebaseUser = userRef.data()
    if (firebaseUser) {
      const user = { ...firebaseUser }
      user.uid = userRef.id
      user.company = loggedInUser.company
      user.displayName = firebaseUser.firstname ? `${firebaseUser.firstname} ${firebaseUser.lastname}` : firebaseUser.displayName
      user.photoURL = user.photoURL ? user.photoURL : '/user.png'

      if (user.company) {
        const companyRef = await firebase.firestore().collection('company').doc(user.company).get()
        user.companyInfo = companyRef.data()

        if (
          user.companyInfo &&
          user.companyInfo.created &&
          (!user.companyInfo.paymentStatus || !['on-trial', 'canceled', 'failed', 'expired'].includes(user.companyInfo.paymentStatus)) &&
          !user.companyInfo.currentInvoiceId &&
          !user.companyInfo.subscriptionId &&
          !user.companyInfo.shopifyStoreId &&
          !user.companyInfo.appsumoUUID &&
          !user.companyInfo.subscriptionPlanId &&
          user.companyInfo.planType !== 'partner'
        ) {
          user.companyInfo.id = user.company
          dispatch('updateUserInfo', user)
          return router.push({ name: 'page-register', query: { step: 3, lang: i18n.locale } })
        }

        if (
          user.companyInfo &&
          user.companyInfo.paymentStatus &&
          user.companyInfo.expiryDate &&
          moment(user.companyInfo.expiryDate).format('MM/DD/yyyy') > moment().format('MM/DD/yyyy') &&
          (user.companyInfo.paymentStatus === 'expired' || user.companyInfo.paymentStatus === 'deleted')
        ) {
          if (router && router.currentRoute && router.currentRoute.name !== 'payment') {
            return router.push({ name: 'payment' }).catch(() => {})
          }
        }
      }

      dispatch('setMobileNotifications', { uid: user.uid })
      dispatch('updateUserInfo', user)
      if (!loggedInUser.displayName || !loggedInUser.photoURL || !loggedInUser.phoneNumber) {
        await loggedInUser.updateProfile({
          displayName: firebaseUser.displayName ? firebaseUser.displayName : `${firebaseUser.firstname} ${firebaseUser.lastname}`,
          photoURL: firebaseUser.photoURL ? firebaseUser.photoURL : '/user.png',
          phoneNumber: firebaseUser.mobile
        })
      }
    }
  },

  async identifyUser({ dispatch }, payload) {
    const loggedInUser = payload
    const idTokenResult = await firebase.auth().currentUser.getIdTokenResult(true)
    const user = {}

    if (idTokenResult && idTokenResult.claims && idTokenResult.claims.name) {
      user.displayName = idTokenResult.claims.name
    }
    if (idTokenResult && idTokenResult.claims && idTokenResult.claims.picture) {
      user.photoURL = idTokenResult.claims.picture
    } else {
      user.photoURL = '/user.png'
    }

    if (idTokenResult && idTokenResult.claims && idTokenResult.claims.user_id) {
      user.uid = idTokenResult.claims.user_id
    }

    if (idTokenResult && idTokenResult.claims && idTokenResult.claims.company) {
      user.company = idTokenResult.claims.company
    }

    if (idTokenResult && idTokenResult.claims && idTokenResult.claims.allowedCompanies) {
      user.allowedCompanies = idTokenResult.claims.allowedCompanies
    }

    if (idTokenResult && idTokenResult.claims && idTokenResult.claims.superadmin) {
      user.superadmin = true
    }
    if (idTokenResult && idTokenResult.claims && idTokenResult.claims.role) {
      user.role = idTokenResult.claims.role
    }

    if (idTokenResult && idTokenResult.claims && idTokenResult.claims.email) {
      user.email = idTokenResult.claims.email
    }
    if (idTokenResult && idTokenResult.claims && idTokenResult.claims.email_verified) {
      user.emailVerified = idTokenResult.claims.email_verified
    }
    dispatch('checkLoggedInUser', loggedInUser)
    dispatch('updateUserInfo', user)
    return true
  },
  async login({ state, dispatch, commit }, payload) {
    try {
      // If user is already logged in notify and exit
      if (state.isUserLoggedIn()) {
        payload.notify({
          title: i18n.t('vue.loginAttempt'),
          text: i18n.t('vue.youAreAlreadyLoggedIn'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        if (payload.origin === 'sign-in-token') {
          return router.push({ name: 'dialog-after-successfull-registration' })
        }
        if (payload.userDetails.user && payload.userDetails.user.company) {
          if (payload.userDetails.user.role && payload.userDetails.user.role === 'financial') {
            return router.push({ name: 'invoices' })
          }

          const companyRef = await firebase.firestore().collection('company').doc(payload.userDetails.user.company).get()
          const companyData = companyRef.data()

          if (
            companyData &&
            companyData.created &&
            (!companyData.paymentStatus || !['on-trial', 'canceled', 'failed', 'expired'].includes(companyData.paymentStatus)) &&
            !companyData.currentInvoiceId &&
            !companyData.subscriptionId &&
            !companyData.subscriptionPlanId &&
            !companyData.shopifyStoreId &&
            !companyData.appsumoUUID &&
            companyData.planType !== 'partner'
          ) {
            return router.push({ name: 'page-register', query: { step: 3, lang: i18n.locale } })
          } else {
            const idTokenResult = await firebase.auth().currentUser.getIdTokenResult(true)
            if (idTokenResult && idTokenResult.claims && idTokenResult.claims.role === 'view-only') {
              return router.push({ name: 'home', params: { from: 'page-login' } })
            }

            return router.push({ name: 'incoming-requests', params: { from: 'page-login' } })
          }
        }
      }

      if (payload && payload.userDetails.email && payload.userDetails.password) {
        const result = await firebase.auth().signInWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password)
        const idTokenResult = await firebase.auth().currentUser.getIdTokenResult(true)
        const company = idTokenResult && idTokenResult.claims && idTokenResult.claims.company ? idTokenResult.claims.company : null

        if (idTokenResult && idTokenResult.claims && idTokenResult.claims.role && idTokenResult.claims.role === 'financial') {
          return router.push({ name: 'invoices' })
        }

        if (!company) {
          return router.push({ name: 'company-setup' })
        }

        if (company) {
          firebase.firestore().collection('company').doc(company).set({ lastLogin: new Date() }, { merge: true })
        }
        if (result && result.user && result.user.uid) {
          const userInfo = {
            hasSetPassword: true,
            displayedSetPassword: true
          }
          firebase.firestore().collection('users').doc(result.user.uid).set(userInfo, { merge: true })

          dispatch('setMobileNotifications', { uid: result.user.uid })
        }
      }
      if (payload && payload.userDetails && payload.userDetails.user && payload.userDetails.user.uid) {
        setTimeout(async () => {
          const userRef = await firebase.firestore().collection('users').doc(payload.userDetails.user.uid).get()
          const user = userRef.data()
          user.uid = userRef.id
          const idTokenResult = await firebase.auth().currentUser.getIdTokenResult(true)
          if (idTokenResult && idTokenResult.claims && idTokenResult.claims.role && idTokenResult.claims.role === 'financial') {
            return router.push({ name: 'invoices' })
          }

          if (idTokenResult && idTokenResult.claims && idTokenResult.claims.superadmin) {
            user.superadmin = true
          }
          user.role = user.isAgent ? 'agent' : 'admin'
          if (user.company) {
            await firebase.firestore().collection('company').doc(user.company).set({ lastLogin: new Date() }, { merge: true })
            const companyRef = await firebase.firestore().collection('company').doc(user.company).get()
            user.companyInfo = companyRef.data()
            if (user.companyInfo) {
              user.companyInfo.id = user.company
            }
          }
          if (typeof user.isActive !== 'undefined' && user.isActive === true) {
            dispatch('updateUserInfo', user)
          } else {
            payload.notify({
              time: errorTimeMS,
              title: i18n.t('vue.error'),
              text: i18n.t('vue.userDisabled'),
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
            const firebaseCurrentUser = firebase.auth().currentUser
            if (firebaseCurrentUser) {
              await firebase.auth().signOut()
            }
            commit('REMOVE_USER_INFO', null, { root: true })
            return false
          }
          if (
            user &&
            typeof user.isActive !== 'undefined' &&
            user.isActive === true &&
            typeof user.hasSetPassword !== 'undefined' &&
            user.hasSetPassword === false &&
            (typeof user.displayedSetPassword === 'undefined' || user.displayedSetPassword === false)
          ) {
            if (payload.closeAnimation) payload.closeAnimation()
            return router.push({ name: 'page-set-password' })
          } else {
            if (payload.closeAnimation) payload.closeAnimation()
            if (payload.origin === 'sign-in-token') {
              return router.push({ name: 'dialog-after-successfull-registration' })
            }
            if (payload.userDetails.user.role) {
              if (payload.userDetails.user.role !== 'financial') {
                const idTokenResult = await firebase.auth().currentUser.getIdTokenResult(true)
                if (idTokenResult && idTokenResult.claims && idTokenResult.claims.role === 'view-only') {
                  return router.push(router.currentRoute.query.to || { name: 'home' })
                }
                return router.push(router.currentRoute.query.to || { name: 'incoming-requests' })
              } else {
                return router.push({ name: 'invoices' })
              }
            }
            const idTokenResult = await firebase.auth().currentUser.getIdTokenResult(true)
            if (idTokenResult && idTokenResult.claims && idTokenResult.claims.role === 'view-only') {
              return router.push(router.currentRoute.query.to || { name: 'home' })
            }
            return router.push(router.currentRoute.query.to || { name: 'incoming-requests' })
          }
        }, 500)
      }

      const idTokenResult = await firebase.auth().currentUser.getIdTokenResult(true)
      if (idTokenResult && idTokenResult.claims && idTokenResult.claims.role === 'view-only') {
        return router.push(router.currentRoute.query.to || { name: 'home' })
      }

      return router.push(router.currentRoute.query.to || { name: 'incoming-requests' })
    } catch (err) {
      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation()

      if (err.code === 'auth/wrong-password') {
        payload.notify({
          time: errorTimeMS,
          title: i18n.t('vue.error'),
          text: i18n.t('vue.invalidPassword'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      } else if (err.code === 'auth/too-many-requests') {
        payload.notify({
          time: errorTimeMS,
          title: i18n.t('vue.error'),
          text: i18n.t('vue.tooManyLoginRequests'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      } else if (err.code === 'auth/user-disabled') {
        payload.notify({
          time: errorTimeMS,
          title: i18n.t('vue.error'),
          text: i18n.t('vue.userDisabled'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      } else {
        payload.notify({
          time: errorTimeMS,
          title: i18n.t('vue.error'),
          text: i18n.t('vue.registrationRequired'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
      const firebaseCurrentUser = firebase.auth().currentUser
      if (firebaseCurrentUser) {
        await firebase.auth().signOut()
      }
      commit('REMOVE_USER_INFO', null, { root: true })
      return false
    }
  },

  async mergeUser({ dispatch }, payload) {
    const user = {
      uid: payload.uid,
      email: payload.email
    }
    if (payload.displayName) {
      user.displayName = payload.displayName
    }

    await firebase.firestore().collection('users').doc(user.uid).set(user, { merge: true })
    await payload.updateProfile({
      displayName: payload.displayName
    })

    const newUserData = Object.assign({}, payload.providerData[0])
    newUserData.displayName = payload.displayName
    dispatch('updateUserInfo', newUserData)
  },
  updateUserInfo({ commit }, payload) {
    commit('UPDATE_USER_INFO', payload, { root: true })
    if (payload.companyInfo && payload.companyInfo.id) {
      commit('UPDATE_COMPANY', payload.companyInfo, { root: true })
    }
  },
  /* eslint-disable no-unused-vars */
  async registerUser({ dispatch, commit }, payload) {
    try {
      let result = null
      let shouldEmailVerificationCode = true

      if (payload.result) {
        result = payload.result
        shouldEmailVerificationCode = false
      } else {
        result = await firebase.auth().createUserWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password)
      }
      const emailVerificationCode = Math.random().toString(36).substring(2, 15)

      if (result && result.user && result.user.uid) {
        const user = {
          created: new Date(),
          email: payload.userDetails.email,
          displayName: payload.userDetails.displayName,
          hasSetPassword: typeof payload.userDetails.hasSetPassword === 'undefined' ? true : payload.userDetails.hasSetPassword,
          firstname: payload.userDetails.firstname,
          lastname: payload.userDetails.lastname,
          language: payload.userDetails.language,
          provider: payload.userDetails.provider || null,
          isNewsLetterAccepted: payload.userDetails.isNewsLetterAccepted || false,
          origin: `${window.location.protocol}//${window.location.host}/pages/register`
        }
        if (shouldEmailVerificationCode) {
          user.email_verification_code = emailVerificationCode
        }
        if (payload.userDetails.sxid) {
          user.sxid = payload.userDetails.sxid
        }

        if (result && result.user && !result.user.displayName) {
          await result.user.updateProfile({
            displayName: payload.userDetails.displayName
          })
        }

        const company = {
          name: '',
          created: new Date(),
          createdBy: result.user.uid,
          createdByName: payload.userDetails.displayName,
          previewUrl: null,
          url: '',
          phone: '',
          zipcode: '',
          street: '',
          city: '',
          country: '',
          countryCode: '',
          houseNumber: '',
          companyDisplayName: '',
          vatNumber: '',
          sources: payload.sources
        }
        if (payload.tokenID) {
          company.tokenID = payload.tokenID
        }
        const claim = {
          company,
          user: {
            uid: result.user.uid
          }
        }
        if (payload.afid) {
          claim.afid = payload.afid
        }
        if (payload.cpid) {
          claim.cpid = payload.cpid
        }

        if (payload.papAffiliateID) {
          claim.papAffiliateID = payload.papAffiliateID
        }

        if (payload.papVisitorID) {
          claim.papVisitorID = payload.papVisitorID
        }

        claim.adminClaim = {
          userId: result.user.uid,
          role: 'admin',
          user
        }

        if (payload && payload.userDetails && payload.userDetails.IS_LOTTERY_WINNER) {
          claim.company.IS_LOTTERY_WINNER = true
        }

        if (shouldEmailVerificationCode) {
          claim.emailConfirmation = {
            userId: result.user.uid,
            email: user.email,
            link: `${window.location.protocol}//${window.location.host}/pages/login?userId=${result.user.uid}&email=${payload.userDetails.email}&emailVerificationCode=${emailVerificationCode}`,
            language: user.language
          }
        }

        const setCompanyClaim = functions.httpsCallable('setCompanyClaim')
        const response = await setCompanyClaim(claim)

        // Get the latest/refresh token
        await result.user.getIdTokenResult(true)

        const companyRef = await firebase.firestore().collection('company').doc(response.data.company).get()
        const updatedCompany = { companyInfo: companyRef.data(), id: companyRef.id }
        dispatch('updateUserInfo', { ...updatedCompany, ...user, company: companyRef.id })

        const step = {
          step: 3,
          lang: i18n.locale
        }

        await firebase.auth().currentUser.getIdTokenResult(true)

        commit('UPDATE_IS_LOADING_REGISTER_FUNNEL', false, { root: true })

        if (payload.closeAnimation) payload.closeAnimation()

        router.push({ name: 'page-register', query: step })

        return step
      } else {
        payload.notify({
          time: errorTimeMS,
          title: i18n.t('vue.error'),
          text: i18n.t('vue.couldNotCreateAccount'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    } catch (err) {
      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation()
      payload.notify({
        time: errorTimeMS,
        title: i18n.t('vue.error'),
        text: err.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    }
  },
  async updateUsername({ dispatch }, payload) {
    try {
      await payload.user.updateProfile({
        displayName: payload.displayName
      })

      // If username update is success
      // update in localstorage
      const newUserData = Object.assign({}, payload.user.providerData[0])
      newUserData.displayName = payload.displayName
      dispatch('updateUserInfo', newUserData)

      // If reload is required to get fresh data after update
      // Reload current page
      if (payload.isReloadRequired) {
        const idTokenResult = await firebase.auth().currentUser.getIdTokenResult(true)
        if (idTokenResult && idTokenResult.claims && idTokenResult.claims.role === 'view-only') {
          router.push(router.currentRoute.query.to || { name: 'home' })
        } else {
          router.push(router.currentRoute.query.to || { name: 'incoming-requests' })
        }
      }
    } catch (err) {
      payload.notify({
        time: errorTimeMS,
        title: i18n.t('vue.error'),
        text: err.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    }
  },

  async setMobileNotifications(_, payload) {
    try {
      const { platform } = await Device.getInfo()

      const setValueToDataStorage = async (key, value) => {
        try {
          await Preferences.set({
            key,
            value
          })
        } catch (error) {
          console.log(error)
        }
      }

      const registerNotificationsToken = async (token) => {
        const { value } = token

        if (value === null) {
          return
        }

        // Save it to Firestore
        try {
          const { platform } = await Device.getInfo()
          const { uuid } = await Device.getId()

          let tokens = []

          const userRef = await firebase.firestore().collection('users').doc(payload.uid).get()
          if (userRef && userRef.data() && userRef.data().tokens && userRef.data().tokens.length > 0) {
            tokens = userRef.data().tokens
          }

          const newToken = {
            uuid,
            platform,
            token: value,
            createdAt: firebase.firestore.Timestamp.now()
          }

          const filteredTokens = tokens.filter((item) => item.uuid !== uuid && item.token !== value)

          filteredTokens.push(newToken)

          await firebase.firestore().collection('users').doc(payload.uid).set({ tokens: filteredTokens }, { merge: true })

          await setValueToDataStorage('notificationsToken', value)
        } catch (error) {
          console.log(error)
        }
      }

      const registerNotifications = async () => {
        let permStatus = await PushNotifications.checkPermissions()

        if (permStatus.receive === 'prompt') {
          permStatus = await PushNotifications.requestPermissions()
        }

        if (permStatus.receive !== 'granted') {
          throw new Error('User denied permissions!')
        }

        // Register with Apple / Google to receive push via APNS/FCM
        await PushNotifications.register()
      }

      if (['ios', 'android'].includes(platform)) {
        await registerNotifications()

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration', registerNotificationsToken)
      }
    } catch (error) {
      console.log(error)
    }
  }
}
