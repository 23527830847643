export default {
  UPDATE_DIALOG_ABTESTS(state, val) {
    const dialog = state.dialogAbTests.find((x) => x.dialogId === val.dialogId)
    if (!dialog) {
      state.dialogAbTests.push(val)
    } else {
      dialog.abtests = [...val.abtests]
    }
  }
}
