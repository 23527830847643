<template>
  <transition name="fade">
    <div class="payment-details">
      <div v-if="!selectedPaymentMethod" class="payment-details--heading">{{ $t('choosePricingPlan.choosePaymentMethod') }}</div>
      <div v-else class="payment-details--heading">{{ $t('choosePricingPlan.addPaymentDetails') }}</div>

      <Mollie
        v-if="selectedPlan && subscriptionPlanId && !showMollieVega && newUidFlowStep === 3"
        :btnTitle="$t('vue.finish')"
        :subscriptionPlanId="subscriptionPlanId"
        :selectedPlan="selectedPlan"
        :supportedPaymentMethods="supportedPaymentMethods"
        :redirectUrl="mollieRedirectUrl"
      />

      <MollieVega v-if="showMollieVega && newUidFlowStep === 3" />
      <MollieGpt v-if="showMollieGpt && newUidFlowStep === 3" />
    </div>
  </transition>
</template>
<script>
import { mapGetters } from 'vuex'

import Mollie from '@/views/pages/plans/Mollie.vue'
import MollieVega from '@/views/vega/subscription/MollieVega.vue'
import MollieGpt from '@/views/gpt-connect/subscription/MollieGpt.vue'

export default {
  name: 'EnterPaymentDetails',
  components: { Mollie, MollieVega, MollieGpt },
  data() {
    return {
      paymentMethods: ['invoice', 'card', 'paypal', 'sepa']
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      selectedPlan: 'chosenPlan',
      selectedPaymentMethod: 'selectedPaymentMethod',
      newUidFlowStep: 'newUidFlowStep',
      showMollieVega: 'showMollieVega',
      showMollieGpt: 'gpt/showMollieGpt'
    }),
    subscriptionPlanId() {
      return this.selectedPlan && this.selectedPlan.id ? this.selectedPlan.id : null
    },
    supportedPaymentMethods() {
      return this.paymentMethods.filter((x) => x === 'card' || x === 'paypal')
    },
    mollieRedirectUrl() {
      return `${location.origin}/processing-payment`
    }
  }
}
</script>
<style lang="scss" scoped>
.payment-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  &--heading {
    font-family: 'Larsseit';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.177778px;
    color: #262629;
  }
}
</style>
