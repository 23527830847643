import ct from 'countries-and-timezones'
const dayjs = require('dayjs')

import { mapGetters, mapMutations } from 'vuex'
export const currency = {
  mounted() {
    if (this.$route && this.$route.name === 'page-register') {
      this.checkAndPreloadImages()
    }
    if (this.company && this.company.IS_LOTTERY_WINNER) {
      this.updateCurrency({ text: 'EUR', symbol: '€' })
    }
  },
  data() {
    return {
      symbols: {
        EUR: '€',
        AUD: '$',
        USD: '$',
        GBP: '£'
      },

      monthlyBasicPrice: {
        EUR: 49,
        AUD: 73,
        USD: 54,
        GBP: 41
      },

      annualBasicPrice: {
        EUR: 45,
        AUD: 67,
        USD: 49,
        GBP: 38
      },

      monthlyProPrice: {
        EUR: 279,
        AUD: 415,
        USD: 307,
        GBP: 233
      },

      monthlyProPriceHasDeal: {
        active: false,
        EUR: 199,
        EUR_IMG: 'https://storage.googleapis.com/pathadvice-app.appspot.com/images/deals/SPECIAL/EUR_MONTHLY.png',
        AUD: 296,
        AUD_IMG: 'https://storage.googleapis.com/pathadvice-app.appspot.com/images/deals/SPECIAL/AUD_MONTHLY.png',
        USD: 209,
        USD_IMG: 'https://storage.googleapis.com/pathadvice-app.appspot.com/images/deals/SPECIAL/US_MONTHLY.png',
        GBP: 169,
        GBP_IMG: 'https://storage.googleapis.com/pathadvice-app.appspot.com/images/deals/SPECIAL/GBP_MONTHLY.png'
      },

      annualProPrice: {
        EUR: 249,
        AUD: 368,
        USD: 275,
        GBP: 208
      },

      annualProPriceHasDeal: {
        active: false,
        EUR: 179,
        EUR_IMG: 'https://storage.googleapis.com/pathadvice-app.appspot.com/images/deals/SPECIAL/EUR_ANNUAL.png',
        AUD: 266,
        AUD_IMG: 'https://storage.googleapis.com/pathadvice-app.appspot.com/images/deals/SPECIAL/AUD_ANNUAL.png',
        USD: 189,
        USD_IMG: 'https://storage.googleapis.com/pathadvice-app.appspot.com/images/deals/SPECIAL/US_ANNUAL.png',
        GBP: 155,
        GBP_IMG: 'https://storage.googleapis.com/pathadvice-app.appspot.com/images/deals/SPECIAL/GBP_ANNUAL.png'
      },

      annualProLotteryPrice: {
        EUR: '27.90',
        EUR_IMG: 'https://storage.googleapis.com/pathadvice-app.appspot.com/images/lottery/LOTTERY_ANNUAL.png'
      },

      monthlyEnterpriseProPrice: {
        EUR: 1495,
        AUD: 2208,
        USD: 1648,
        GBP: 1245
      },

      annualEnterprisePrice: {
        EUR: 1345,
        AUD: 1987,
        USD: 1485,
        GBP: 1119
      }
    }
  },
  computed: {
    ...mapGetters({
      currency: 'currency',
      company: 'company'
    }),
    basicPriceMonthly() {
      return this.monthlyBasicPrice[this.currency.text]
    },

    basicPriceAnnual() {
      return this.annualBasicPrice[this.currency.text]
    },

    proPriceMonthly() {
      if (this.monthlyProPriceHasDeal && this.monthlyProPriceHasDeal.active) {
        return this.monthlyProPriceHasDeal[this.currency.text]
      }
      return this.monthlyProPrice[this.currency.text]
    },

    proPriceMonthlyDealImage() {
      if (this.monthlyProPriceHasDeal && this.monthlyProPriceHasDeal.active && this.monthlyProPriceHasDeal[`${this.currency.text}_IMG`]) {
        return this.monthlyProPriceHasDeal[`${this.currency.text}_IMG`]
      }
      return null
    },

    proPriceAnnualDealImage() {
      if (this.company && this.company.IS_LOTTERY_WINNER) {
        return this.annualProLotteryPrice.EUR_IMG
      }
      if (this.annualProPriceHasDeal && this.annualProPriceHasDeal.active && this.annualProPriceHasDeal[`${this.currency.text}_IMG`]) {
        return this.annualProPriceHasDeal[`${this.currency.text}_IMG`]
      }
      return null
    },

    proPriceAnnual() {
      if (this.annualProPriceHasDeal && this.annualProPriceHasDeal.active) {
        return this.annualProPriceHasDeal[this.currency.text]
      }
      return this.annualProPrice[this.currency.text]
    },
    enterprisePriceMonthly() {
      return this.monthlyEnterpriseProPrice[this.currency.text]
    },
    enterprisePriceAnnual() {
      return this.annualEnterprisePrice[this.currency.text]
    },
    currentCurrencySymbol() {
      return this.currency.symbol
    }
  },
  methods: {
    ...mapMutations({
      updateCurrency: 'UPDATE_CURRENCY'
    }),
    checkAndPreloadImages() {
      const images = []
      if (this.annualProPriceHasDeal && this.annualProPriceHasDeal.active) {
        images.push(this.annualProPriceHasDeal.AUD_IMG)
        images.push(this.annualProPriceHasDeal.USD_IMG)
        images.push(this.annualProPriceHasDeal.EUR_IMG)
        images.push(this.annualProPriceHasDeal.GBP_IMG)
      }

      if (this.monthlyProPriceHasDeal && this.monthlyProPriceHasDeal.active) {
        images.push(this.monthlyProPriceHasDeal.AUD_IMG)
        images.push(this.monthlyProPriceHasDeal.USD_IMG)
        images.push(this.monthlyProPriceHasDeal.EUR_IMG)
        images.push(this.monthlyProPriceHasDeal.GBP_IMG)
      }

      if (images.length > 1) {
        this.preloadImages(images)
      }
    },
    preloadImages(images) {
      const list = []
      for (let i = 0; i < images.length; i++) {
        const img = new Image()
        img.onload = function () {
          const index = list.indexOf(this)
          if (index !== -1) {
            // remove image from the array once it's loaded
            // for memory consumption reasons
            list.splice(index, 1)
          }
        }
        list.push(img)
        img.src = images[i]
      }
    },
    getCurrencySymbol(currency) {
      return this.symbols[currency]
    },
    detectCurrency() {
      const timezoneInfo = ct.getCountryForTimezone(dayjs.tz.guess())
      const countryFromTimezone = timezoneInfo && timezoneInfo.id ? timezoneInfo.id.toLowerCase() : ''

      /* Check for European Currency */
      const euroCurrenciesCountry = ['at', 'de', 'ch']
      if (navigator.language.toLowerCase().startsWith('de') || euroCurrenciesCountry.includes(countryFromTimezone)) {
        this.updateCurrency({ text: 'EUR', symbol: '€' })
      }

      /* Check for United Kingdom Currency */
      if (navigator.language.toLowerCase() === 'en-gb' || countryFromTimezone === 'gb') {
        this.updateCurrency({ text: 'GBP', symbol: '£' })
      }

      /* Check for Australian Dollar Currency */
      if (navigator.language.toLowerCase() === 'en-au' || countryFromTimezone === 'au') {
        this.updateCurrency({ text: 'AUD', symbol: '$' })
      }

      //console.log(`detect Currency : ${this.currency.text}, ${JSON.stringify(countryFromTimezone)}, ${navigator.language}`)
    }
  }
}
