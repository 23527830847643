var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ui-locked-status" }, [
    _c("div", { staticClass: "ui-locked-status__overlay" }),
    _c("div", { staticClass: "ui-locked-status__content" }, [
      _c(
        "div",
        { staticClass: "ui-locked-status__wrapper" },
        [
          _c("img", {
            staticClass: "ui-locked-status__icon",
            attrs: {
              src: require("@/assets/images/elements/lock-icon.svg"),
              alt: "Secure",
            },
          }),
          _c("div", { staticClass: "ui-locked-status__title" }, [
            _vm._v(_vm._s(_vm.$t("locked.title"))),
          ]),
          _c("div", { staticClass: "ui-locked-status__text" }, [
            _vm._v(_vm._s(_vm.$t("locked.text"))),
          ]),
          _vm.company && _vm.company.paymentStatus === "unpaid"
            ? [
                _c(
                  "vs-button",
                  {
                    staticClass: "mb-5",
                    attrs: { color: "primary", type: "filled" },
                    on: { click: _vm.goToInvoices },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("locked.buttonMainText")))]
                ),
                _c(
                  "div",
                  {
                    staticClass: "ui-locked-status__secondary-button",
                    on: { click: _vm.updatePaymentMethod },
                  },
                  [_vm._v(_vm._s(_vm.$t("locked.buttonSecondaryText")))]
                ),
              ]
            : [
                _c(
                  "vs-button",
                  {
                    staticClass: "mb-5",
                    attrs: { color: "primary", type: "filled" },
                    on: { click: _vm.updatePaymentMethod },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("locked.buttonSecondaryText")))]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "mb-5",
                    attrs: { color: "primary", type: "filled" },
                    on: { click: _vm.USER_LOGOUT },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("vue.logout")))]
                ),
              ],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }