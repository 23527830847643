import state from './moduleAbTestsState.js'
import mutations from './moduleAbTestsMutations.js'
import actions from './moduleAbTestsActions.js'
import getters from './moduleAbTestsGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
