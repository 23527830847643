<!-- =========================================================================================
    File Name: VerticalNavMenuItem.vue
    Description: Vertical NavMenu item component. Extends vuesax framework's 'vs-sidebar-item' component
    Component Name: VerticalNavMenuItem
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="vs-sidebar--item" :class="[{ 'vs-sidebar-item-active': activeLink }, { 'disabled-item pointer-events-none': isDisabled || audioVideoStarted }]">
    <router-link tabindex="-1" v-if="to" exact :class="[{ 'router-link-active': activeLink }]" :to="to" :target="target">
      <template v-if="iconPack === 'custom-icons'">
        <span class="select-none relative" style="height: 20px">
          <dashboard-icon v-if="icon === 'DashboardIcon'" :width="20" :height="20" style="margin-right: 14px" />
          <gpt-connect-icon v-if="icon === 'GptConnectIcon'" :width="22" :height="18" style="margin-right: 14px" />
        </span>
      </template>
      <template v-else-if="!featherIcon">
        <span class="select-none relative" style="height: 20px">
          <vs-icon size="20px" v-if="activeLink" :icon-pack="iconPack" :icon="icon" style="color: white; margin-right: 14px">{{ icon }}</vs-icon>
          <vs-icon size="20px" v-else :icon-pack="iconPack" :icon="icon" style="margin-right: 14px">{{ icon }}</vs-icon>
        </span>
      </template>
      <template v-else>
        <feather-icon :class="{ 'w-3 h-3': iconSmall }" :icon="icon" />
      </template>
      <slot />
    </router-link>

    <a v-else :target="target" :href="href" tabindex="-1">
      <vs-icon v-if="!featherIcon" size="22px" :icon-pack="iconPack" :icon="icon">{{ icon }}</vs-icon>
      <feather-icon v-else :class="{ 'w-3 h-3': iconSmall }" :icon="icon" />
      <slot />
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DashboardIcon from '@/components/icons/DashboardIcon.vue'
import GptConnectIcon from '@/components/icons/gpt/GptConnectIcon.vue'

export default {
  name: 'v-nav-menu-item',
  props: {
    icon: { type: String, default: '' },
    iconSmall: { type: Boolean, default: false },
    iconPack: { type: String, default: 'material-icons' },
    href: { type: [String, null], default: '#' },
    to: { type: [String, Object, null], default: null },
    slug: { type: String, default: null },
    index: { type: [String, Number], default: null },
    featherIcon: { type: Boolean, default: true },
    target: { type: String, default: '_self' },
    isDisabled: { type: Boolean, default: false }
  },
  components: {
    DashboardIcon,
    GptConnectIcon
  },
  computed: {
    ...mapGetters({
      audioVideoStarted: 'webrtc/audioVideoStarted'
    }),
    activeLink() {
      return !!((this.to === this.$route.path || this.$route.meta.parent === this.slug) && this.to)
    }
  }
}
</script>

<style scoped>
.v-nav-menu .vs-sidebar--item .router-link-active .material-icons {
  color: #fff;
}
</style>
