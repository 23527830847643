export default {
  en: null,
  de: null,
  translations: [],
  scrollListener: {
    name: null,
    scrollTop: null
  },
  addKey: Math.random().toString(36).substring(2, 15),
  updateKey: {
    key: Math.random().toString(36).substring(2, 15),
    field: null
  },
  removedLanguage: null,
  updatedField: null,
  currentLanguage: 'en',
  currentTab: 'button-and-hints',
  showDeleteTranslationModal: false,
  showResetTranslationModal: false,
  translationToBeDeleted: null,
  translationToBeReseted: null,

  defaultTranslations: ['en'],

  translationUpdateKey: Math.random().toString(36).substring(2, 15),

  /*  Web-connect  */
  showWidgetSelection: false,
  selectedWidget: null,
  selectedGptWidget: null,
  showGptWidgetSelection: false,
  selectedWidgetSavedTranslation: null,
  selectedGptWidgetSavedTranslation: null,
  selectedQRWidgetSavedTranslation: null,

  /* Qr-Connect */
  showQRWidgetSelection: false,
  selectedQRWidget: null
}
