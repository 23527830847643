const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

import { mapGetters, mapMutations } from 'vuex'
let plans = null
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'pathadvice-app') {
  const plansModule = require('@/assets/plans/prod_plans.js')
  plans = plansModule.plans
} else {
  const plansModule = require('@/assets/plans/stage_plans.js')
  plans = plansModule.plans
}
const gptPlansModule = require('@/assets/plans/gpt_plans.js')
const gptPlans = gptPlansModule.plans

export const msubscription = {
  data() {
    return {
      isGettingGptSubscription: false,
      isGettingInvoiceSubscription: false,
      isGettingMollieSubscription: false,
      isGettingPaddleSubscription: false
    }
  },
  computed: {
    ...mapGetters({
      currentUserInfo: 'activeUser',
      company: 'company',
      subscription: 'subscription',
      fetchedSubscriptionPlans: 'subscription_plans',
      gptSubscription: 'gpt/gptSubscription'
    }),
    allPlans() {
      let mergedPlans = []

      if (plans && plans.length) {
        mergedPlans = [...mergedPlans, ...plans]
      }

      if (gptPlans && gptPlans.length) {
        mergedPlans = [...mergedPlans, ...gptPlans]
      }
      return mergedPlans
    },
    supportedPaymentMethods() {
      return ['card', 'paypal']
    }
  },
  methods: {
    ...mapMutations({
      updateSubscription: 'UPDATE_SUBSCRIPTION',
      setGptSubscription: 'gpt/SET_GPT_SUBSCRIPTION',
      updateRefreshState: 'UPDATE_REFRESH_STATE'
    }),
    async getSubscriptionInfo() {
      if (this.company) {
        this.getGptSubscription()

        if (this.company.mollieSubscriptionId) {
          this.getMollieSubscription()
        } else if (this.company.currentInvoiceId) {
          this.getPathadviceInvoiceSuscriptionInfo()
        } else {
          this.getPaddleSubscription()
        }
      }
    },

    async getPaddleSubscription() {
      if (this.isGettingPaddleSubscription) return
      this.isGettingPaddleSubscription = true
      await this.$vs.loading()
      try {
        let subscriptionId = null
        let subscriptionState = 'active'
        if (this.company && this.company.subscriptionId && this.company.paymentStatus === 'subscription') {
          subscriptionId = this.company.subscriptionId
        }
        /* If the subscription is cancelled then get the subscriptionId from oldSubscriptionId */
        if (this.company && this.company.oldSubscriptionId && this.company.paymentStatus === 'deleted') {
          subscriptionId = this.company.oldSubscriptionId
          subscriptionState = 'deleted'
        }

        /* If the subscription is cancelled then get the subscriptionId from oldSubscriptionId */
        if (this.company && !this.company.oldSubscriptionId && this.company.subscriptionId && this.company.paymentStatus === 'deleted') {
          subscriptionId = this.company.subscriptionId
          subscriptionState = 'deleted'
        }

        if (subscriptionId) {
          const getCurrentSubscription = this.$functions.httpsCallable('getCurrentSubscription')
          const res = await getCurrentSubscription({
            subscription_id: subscriptionId,
            state: subscriptionState
          })

          if (res && res.data && res.data.success) {
            const currentSubscription = res.data.response[0]
            const currentSubscriptionPlanId = currentSubscription ? currentSubscription.plan_id || currentSubscription.id : 0

            const plans = this.getBaseSubscriptionPlans()
            const currentPlan = plans.find((x) => x.id === currentSubscriptionPlanId || x.ids.includes(currentSubscriptionPlanId.toString()))

            if (currentSubscription && currentPlan) {
              const unitPriceJson = currentPlan.recurring_price

              for (const key in unitPriceJson) {
                currentPlan.unitPrice = `${unitPriceJson[key]} ${key}`
              }
              currentSubscription.planDetails = currentPlan

              this.updateSubscription(currentSubscription)

              /* Update the refresh state so that the method is not called on created()  */
              const refreshState = { ...this.refresh }
              refreshState.subscription = false
              this.updateRefreshState(refreshState)
            } else {
              this.updateSubscription(null)
            }
          } else {
            this.updateSubscription(null)
          }
        }
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
      } finally {
        await this.$vs.loading.close()
        this.isGettingPaddleSubscription = false
      }
    },

    async getMollieSubscription() {
      if (this.isGettingMollieSubscription) return
      this.isGettingMollieSubscription = true
      try {
        if (this.company.mollieCustomerId && this.company.mollieSubscriptionId && !this.subscription) {
          await this.$vs.loading()
          const mollieGetSubscription = this.$functions.httpsCallable('mollieGetSubscription')
          const res = await mollieGetSubscription({
            mollieCustomerId: this.company.mollieCustomerId,
            mollieSubscriptionId: this.company.mollieSubscriptionId
          })
          if (res && res.data && res.data.resource === 'subscription') {
            const currentSubscription = res.data
            currentSubscription.quantity = currentSubscription.metadata.quantity || 1
            const nextPayment = {
              amount: currentSubscription.amount.value,
              currency: currentSubscription.amount.currency,
              date: currentSubscription.nextPaymentDate
            }
            currentSubscription.next_payment = nextPayment

            const currentSubscriptionPlanId = currentSubscription ? currentSubscription.metadata.planId : 0
            const currentPlan = this.fetchedSubscriptionPlans.find(
              (x) => x.id === currentSubscriptionPlanId || x.ids.includes(currentSubscriptionPlanId.toString())
            )

            if (currentSubscription && currentPlan) {
              const unitPriceJson = this.company.IS_LOTTERY_WINNER && currentPlan.lottery_price ? currentPlan.lottery_price : currentPlan.recurring_price
              const currency = this.company && this.company.currency ? this.company.currency.text : this.currency.text || 'EUR'
              currentPlan.unitPrice = `${unitPriceJson[currency]} ${currency}`
              currentSubscription.planDetails = currentPlan

              this.updateSubscription(currentSubscription)

              /* Update the refresh state so that the method is not called on created()  */
              const refreshState = { ...this.refresh }
              refreshState.subscription = false
              this.updateRefreshState(refreshState)
            } else {
              this.updateSubscription(null)
            }
          } else {
            this.updateSubscription(null)
          }
        }
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
      } finally {
        await this.$vs.loading.close()
        this.isGettingMollieSubscription = false
      }
    },

    async getGptSubscription() {
      if (this.isGettingGptSubscription) return
      this.isGettingGptSubscription = true
      if (this.company.mollieCustomerId && this.company.mollieGptSubscriptionId && this.company.gptSubscriptionPlanId) {
        await this.$vs.loading()
        try {
          const gptSubRef = await this.$db
            .collection('company')
            .doc(this.currentUserInfo.company)
            .collection('mollie-subscriptions')
            .doc(this.company.mollieGptSubscriptionId)
            .get()

          const res = gptSubRef.data()

          if (res && res.resource === 'subscription') {
            const currentSubscription = res
            currentSubscription.quantity = currentSubscription.metadata.quantity || 1
            const nextPayment = {
              amount: currentSubscription.amount.value,
              currency: currentSubscription.amount.currency,
              date: currentSubscription.nextPaymentDate
            }

            currentSubscription.next_payment = nextPayment

            const currentPlan = this.allPlans.find((x) => x.id === this.company.gptSubscriptionPlanId)

            if (currentSubscription && currentPlan) {
              currentSubscription.planDetails = currentPlan

              this.setGptSubscription(currentSubscription)
            } else {
              this.setGptSubscription(null)
            }
          } else {
            this.setGptSubscription(null)
          }
        } catch (error) {
          /* eslint-disable no-console */
          console.log(error.message)
        } finally {
          await this.$vs.loading.close()
          this.isGettingGptSubscription = false
        }
      }
    },

    async getPathadviceInvoiceSuscriptionInfo() {
      if (this.isGettingInvoiceSubscription) return
      try {
        await this.$vs.loading()
        this.isGettingInvoiceSubscription = true

        /* Get Lets Connect Invoice Subscription */
        if (this.company && this.company.currentInvoiceId) {
          const invoiceRef = await this.$db.collection('pathadvice-invoices').doc(this.company.currentInvoiceId).get()
          const invoiceData = invoiceRef.data()
          if (this.allPlans && this.allPlans.length) {
            const selectedPlan = this.allPlans.find((item) => item.id === invoiceData.selectedPlanId)

            this.currentPathadviceInvoiceSubscription = {
              ...invoiceData,
              planDetails: selectedPlan,
              lastPayment: invoiceData.lastPayment ? invoiceData.lastPayment : '',
              lastPaymentDate: invoiceData.lastPaymentDate ? invoiceData.lastPaymentDate : '',
              nextPayment: invoiceData.nextPayment ? invoiceData.nextPayment : ''
            }

            this.updateSubscription(this.currentPathadviceInvoiceSubscription)
          }
        }

        /* Get Gpt Invoice Subscription */
        if (this.company && this.company.gptCurrentInvoiceId) {
          const invoiceRef = await this.$db.collection('pathadvice-invoices').doc(this.company.gptCurrentInvoiceId).get()
          const invoiceData = invoiceRef.data()
          if (this.allPlans && this.allPlans.length) {
            const selectedPlan = this.allPlans.find((item) => item.id === invoiceData.selectedPlanId)

            const nextPaymentDate = invoiceData.nextPaymentDate ? dayjs.unix(invoiceData.nextPaymentDate.seconds).format('YYYY-MM-DD') : ''
            const next_payment = invoiceData.nextPayment && invoiceData.nextPayment['EUR'] ? { amount: invoiceData.nextPayment['EUR'] } : null

            const currentPathadviceInvoiceSubscription = {
              ...invoiceData,
              planDetails: selectedPlan,
              lastPayment: invoiceData.lastPayment ? invoiceData.lastPayment : '',
              lastPaymentDate: invoiceData.lastPaymentDate ? invoiceData.lastPaymentDate : '',
              nextPayment: invoiceData.nextPayment ? invoiceData.nextPayment : '',
              nextPaymentDate,
              next_payment
            }

            this.setGptSubscription(currentPathadviceInvoiceSubscription)
          }
        }
      } catch (error) {
        console.log(error.message)
      } finally {
        await this.$vs.loading.close()
      }

      this.isGettingInvoiceSubscription = false
    }
  }
}
