import { mapGetters, mapMutations } from 'vuex'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export const mvega = {
  data() {
    return {
      intervalInMS: 10000,
      maxNoActivityTimeInSeconds: 600,
      maxConnectionTimeInSeconds: 21600,
      graceTimeInSeconds: 10800
    }
  },
  computed: {
    ...mapGetters({
      vegaCompany: 'company',
      currentActiveUser: 'activeUser',
      vegaCurrentVisitorsForTheDay: 'vegaCurrentVisitorsForTheDay'
    }),

    vegaCurrentMonth() {
      const now = dayjs.utc()

      return {
        text: now.format('MMMM YYYY'),
        month: now.format('MM'),
        date: now.date().toString(),
        year: now.year().toString(),
        monthName: now.format('MMMM')
      }
    },

    isVegaPaused() {
      if (!this.vegaCompany || this.vegaCompany.vegaIsPaused) return true
      return false
    },

    hasVegaDailyLimit() {
      return Boolean(this.vegaCompany && this.vegaCompany.vegaHasDailyLimit)
    },

    currentVegaDailyLimit() {
      return this.hasVegaDailyLimit ? Number(this.vegaCompany.vegaDailyLimit) : null
    },

    isVegaActivated() {
      if (this.vegaCompany && this.vegaCompany.vegaIsActivated && this.vegaCompany.vegaExpiryDate && !this.vegaCompany.vegaIsOnTrial) {
        const now = dayjs.utc()
        const vegaExpiryDayjs = dayjs.unix(this.vegaCompany.vegaExpiryDate.seconds).utc()
        return now.isBefore(vegaExpiryDayjs)
      }

      if (this.vegaCompany && this.vegaCompany.vegaExpiryDate && this.vegaCompany.vegaIsOnTrial) {
        const now = dayjs.utc()
        const vegaExpiryDayjs = dayjs.unix(this.vegaCompany.vegaExpiryDate.seconds).utc()
        return now.isBefore(vegaExpiryDayjs)
      }
      return false
    },

    vegaTotalCurrentCost() {
      const vegaCostPerVisitor = this.vegaCompany && this.vegaCompany.vegaCostPerVisitor ? this.vegaCompany.vegaCostPerVisitor : this.$vegaCostPerVisitor
      const totalVisitorsCost = this.vegaCurrentVisitorsForTheDay * vegaCostPerVisitor
      const cost = totalVisitorsCost
      return Number(cost.toFixed(2))
    },

    vegaWillExceedDailyLimit() {
      if (this.hasVegaDailyLimit && this.currentVegaDailyLimit) {
        const vegaCostPerVisitor = this.vegaCompany && this.vegaCompany.vegaCostPerVisitor ? this.vegaCompany.vegaCostPerVisitor : this.$vegaCostPerVisitor
        return Number((this.vegaTotalCurrentCost + vegaCostPerVisitor).toFixed(2)) > this.currentVegaDailyLimit
      }
      return false
    }
  },
  methods: {
    ...mapMutations({
      setVegaCurrentVisitorsForTheDay: 'SET_VEGA_CURRENT_VISITORS_FOR_THE_DAY'
    }),
    vegaGetMonthAbbreviation(month, year) {
      // Create a dayjs object with the month and a fixed year (since we only care about the month)
      const monthDate = dayjs(`${year}-${month}-01`, 'YYYY-MM-DD')

      // Format the month as a three-letter abbreviation
      const monthAbbreviation = monthDate.format('MMM').toUpperCase()

      return monthAbbreviation
    },
    vegaFetchTotalVisitors() {
      if (!this.currentActiveUser || !this.currentActiveUser.company) return
      if (!this.vegaCurrentMonth) return
      const companyId = this.currentActiveUser.company
      const { year, month, date } = this.vegaCurrentMonth
      const monthAbbreviation = this.vegaGetMonthAbbreviation(month, year)
      const docRef = this.$db
        .collection('vega-visitors-count')
        .doc(companyId)
        .collection('year')
        .doc(year)
        .collection('month')
        .doc(monthAbbreviation)
        .collection('day')
        .doc(date)

      // Set up a real-time listener
      this.unsubscribeSnapshot = docRef.onSnapshot(
        (doc) => {
          if (doc.exists) {
            const vegaTotalVisitorsForTheDay = doc.data().totalVisitors || 0
            this.setVegaCurrentVisitorsForTheDay(vegaTotalVisitorsForTheDay)
          }
        },
        (error) => {
          this.setVegaCurrentVisitorsForTheDay(0)
          /* eslint-disable no-console */
          console.error('Error fetching data:', error)
        }
      )
    }
  }
}
