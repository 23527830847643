export default {
  UPDATE_DATE_RANGE(state, value) {
    state.dateRange = value
  },
  ADD_INVOICE(state, val) {
    state.pathadviceInvoices.push(val)
  },
  UPDATE_INVOICE(state, val) {
    state.pathadviceInvoices = [...state.pathadviceInvoices.map((item) => (item.id !== val.id ? item : { ...item, ...val }))]
  },
  RESET_INVOICES(state) {
    state.pathadviceInvoices = []
  },
  ADD_NOTIFICATION(state, val) {
    state.notifications.push(val)
  },
  UPDATE_NOTIFICATION(state, val) {
    state.notifications = [...state.notifications.map((item) => (item.id !== val.id ? item : { ...item, ...val }))]
  },
  REMOVE_NOTIFICATION(state, val) {
    state.notifications = state.notifications.filter((x) => x.id !== val)
  },
  CLEAR_NOTIFICATIONS(state) {
    state.notifications = []
  },
  RESET_INVOICE_STORE(state) {
    state.dateRange = {
      endDate: null,
      startDate: null
    }
    state.pathadviceInvoices = []
    state.notifications = []
  }
}
