import Vue from 'vue'

const state = {
  // Stores chat message files
  files: [],
  loadingIds: []
}

const getters = {
  loadingIds: (state) => state.loadingIds,
  chatFilesList: (state) => state.files,
  chatFilesListCurrent: (state, getters) => (index) => getters.chatFilesList[index]
}

const mutations = {
  addLoadingId(state, payload) {
    state.loadingIds.push({ loaderId: payload })
  },
  removeLoadingId(state, payload) {
    const removeIndex = state.loadingIds.findIndex((x) => x.loaderId === payload)
    state.loadingIds.splice(removeIndex, 1)
  },

  setChatFilesUpload(state, { index, file }) {
    Vue.set(state.files, index, file)
  },
  filterChatFilesUpload(state, closeUrl) {
    state.files = state.files.filter(({ url }) => url !== closeUrl)
  },
  clearChatFilesUpload(state) {
    state.files = []
  }
}

const actions = {
  updateChatFilesUpload({ commit }, payload) {
    commit('setChatFilesUpload', payload)
  }
}

export const chatFiles = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
