import state from './moduleLanguagesState.js'
import mutations from './moduleLanguagesMutations.js'
import actions from './moduleLanguagesActions.js'
import getters from './moduleLanguagesGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
