<!-- =========================================================================================
    File Name: VxBreadcrumb.vue
    Description: Breadcrumb component
    Component Name: VxBreadcrumb
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template >
  <div class="vx-breadcrumb" :class="staticClass">
    <ul class="flex flex-wrap items-center">
      <li class="inline-flex items-end">
        <router-link to="/">
          <feather-icon icon="HomeIcon" svgClasses="h-5 w-5 mb-1 stroke-current text-primary" />
        </router-link>
        <span class="breadcrumb-separator mx-2">
          <feather-icon :icon="isRTL ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'" svgClasses="w-4 h-4" /></span>
      </li>
      <li v-for="(link, index) in route.meta.breadcrumb.slice(1,-1)" :key="index" class="inline-flex items-center">
        <router-link :to="link.url" v-if="link.url">{{ link.title }}</router-link>
        <span class="text-primary cursor-default" v-else>{{ link.title }}</span>
        <span class="breadcrumb-separator mx-2 flex items-start">
          <feather-icon :icon="isRTL ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'" svgClasses="w-4 h-4" /></span>
      </li>
      <li class="inline-flex">
        <span v-if="route.meta.breadcrumb.slice(-1)[0].active" class="cursor-default">{{ breadcrumbTitle }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'vx-breadcrumb',
  props: ['isRTL', 'route'],
  data: () => ({
    staticClass: 'ml-4 md:block hidden',
    title: ''
  }),
  computed: {
    breadcrumbTitle: {
      // getter
      get() {
        return this.$i18n.t(this.title)
      },
      // setter
      set(title) {
        this.title = title
      }
    },
  },
  created() {
    const vm = this
    vm.title = this.route.meta.breadcrumb.slice(-1)[0].title
    this.$serverBus.$on('route-title-update', () => {
      if (vm.route.meta.breadcrumb && vm.route.meta.breadcrumb.length > 0) {
        this.breadcrumbTitle = vm.route.meta.breadcrumb.slice(-1)[0].title
      }
    })
  },
  beforeDestroy() {
    this.$serverBus.$off('route-title-update')
  }
}
</script>
