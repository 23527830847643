export default {
  UPDATE_DEFAULT_LANGUAGE_EN(state, val) {
    const value = JSON.parse(JSON.stringify(val))
    value.updateInDb = false
    value.sort = 0
    value.canBeDeleted = false
    state.en = value

    if (!state.translations.find((x) => x.ln === value.ln)) {
      state.translations = state.translations.filter((x) => x.ln !== value.ln) || []
      state.translations.push(JSON.parse(JSON.stringify(value)))
    }
  },
  UPDATE_DEFAULT_LANGUAGE_DE(state, val) {
    const value = JSON.parse(JSON.stringify(val))
    value.updateInDb = false
    value.sort = 1
    value.canBeDeleted = true
    state.de = value
  },
  ADD_TRANSLATION(state, val) {
    state.removedLanguage = null
    val.updateInDb = true
    val.canBeDeleted = true
    val.sort = state.translations.length
    state.translations.push(val)
    state.addKey = Math.random().toString(36).substring(2, 15)
  },
  RESET_TRANSLATION(state, val) {
    if (val !== 'de') {
      return
    }
    const translation = state.translations.find((x) => x.ln === val)
    if (translation) {
      if (state.currentTab === 'button-and-hints') {
        const default_lang = state.de.buttonAndHints
        translation.buttonAndHints = JSON.parse(JSON.stringify(default_lang))
      }

      if (state.currentTab === 'appointment-booking') {
        const default_lang = state.de.appointmentBooking
        translation.appointmentBooking = JSON.parse(JSON.stringify(default_lang))
      }

      if (state.currentTab === 'email-translations') {
        const default_lang = state.de.emailTranslations
        translation.emailTranslations = JSON.parse(JSON.stringify(default_lang))
      }

      if (state.currentTab === 'web-connect') {
        const default_lang = state.de.webConnect
        translation.webConnect = JSON.parse(JSON.stringify(default_lang))
      }
      if (state.currentTab === 'qr-connect') {
        const default_lang = state.de.qrConnect
        translation.qrConnect = JSON.parse(JSON.stringify(default_lang))
      }

      translation.updateInDb = true

      state.translationUpdateKey = Math.random().toString(36).substring(2, 15)
      state.updateKey = {
        key: Math.random().toString(36).substring(2, 15),
        updatedField: null,
        tab: state.currentTab
      }
    }
  },
  REMOVE_TRANSLATION(state, val) {
    const translation = state.translations.find((x) => x.ln === val)

    if (translation) {
      if (state.currentTab === 'button-and-hints') {
        translation.buttonAndHints = null
        state.removedLanguage = `${val}_${Math.random().toString(36).substring(2, 15)}`
      }

      if (state.currentTab === 'appointment-booking') {
        translation.appointmentBooking = null
        state.removedLanguage = `${val}_${Math.random().toString(36).substring(2, 15)}`
      }

      if (state.currentTab === 'email-translations') {
        translation.emailTranslations = null
        state.removedLanguage = `${val}_${Math.random().toString(36).substring(2, 15)}`
      }

      if (state.currentTab === 'web-connect') {
        if (state.defaultTranslations.includes(translation.ln)) {
          const default_lang = state.en.webConnect
          translation.webConnect = JSON.parse(JSON.stringify(default_lang))
        } else {
          translation.webConnect = null
        }
      }
      if (state.currentTab === 'qr-connect') {
        if (state.defaultTranslations.includes(translation.ln)) {
          const default_lang = state.en.qrConnect
          translation.qrConnect = JSON.parse(JSON.stringify(default_lang))
        } else {
          translation.qrConnect = null
        }
      }

      if (!translation.appointmentBooking && !translation.buttonAndHints) {
        state.translations = state.translations.filter((x) => x.ln !== val)
      }

      state.translationUpdateKey = Math.random().toString(36).substring(2, 15)
    }
  },
  UPDATE_TRANSLATION(state, t) {
    state.removedLanguage = null
    state.translations = state.translations.filter((x) => x.ln !== t.translation.ln) || []
    t.translation.updateInDb = true
    state.translations.push(t.translation)
    state.updateKey = {
      key: Math.random().toString(36).substring(2, 15),
      updatedField: t.key,
      tab: t.tab
    }
  },
  UPDATE_FIELD(state, name) {
    state.updatedField = name
  },
  LOAD_TRANSLATION(state, val) {
    state.translations = state.translations.filter((x) => x.ln !== val.ln) || []
    if (val.ln === 'en') {
      val.sort = 0
    }
    if (val.ln === 'de') {
      val.sort = 1
    }

    val.canBeDeleted = !state.defaultTranslations.includes(val.ln)
    state.translations.push(val)
    state.translationUpdateKey = Math.random().toString(36).substring(2, 15)
  },
  UPDATE_SCROLL_LISTENER(state, val) {
    state.scrollListener = val
  },
  UPDATE_CURRENT_LANGUAGE(state, val) {
    state.currentLanguage = val
  },
  UPDATE_CURRENT_TAB(state, val) {
    state.currentTab = val
  },

  UPDATE_TRANSLATIONUPDATEKEY(state) {
    state.translationUpdateKey = Math.random().toString(36).substring(2, 15)
  },

  /* Web Connect */
  UPDATE_SHOW_WIDGET_SELECTION(state, val) {
    state.showWidgetSelection = val
  },
  UPDATE_SELECTED_WIDGET(state, val) {
    state.selectedWidget = val

    /* Remove webConnect property */
    if (!val) {
      state.translations.forEach((object) => {
        if (!state.defaultTranslations.includes(object.ln)) object['webConnect'] = {}
      })
    }
  },

  /* Gpt Connect */
  UPDATE_SHOW_GPT_WIDGET_SELECTION(state, val) {
    state.showGptWidgetSelection = val
  },
  UPDATE_SELECTED_GPT_WIDGET(state, val) {
    state.selectedGptWidget = val

    /* Remove webConnect property */
    if (!val) {
      state.translations.forEach((object) => {
        if (!state.defaultTranslations.includes(object.ln)) object['gptConnect'] = {}
      })
    }
  },

  UPDATE_SELECTED_WIDGET_SAVED_TRANSLATION(state, val) {
    state.selectedWidgetSavedTranslation = val
  },

  UPDATE_SELECTED_GPT_WIDGET_SAVED_TRANSLATION(state, val) {
    state.selectedGptWidgetSavedTranslation = val
  },
  UPDATE_SELECTED_QR_WIDGET_SAVED_TRANSLATION(state, val) {
    state.selectedQRWidgetSavedTranslation = val
  },

  UPDATE_SHOW_DELETE_TRANSLATION_MODAL(state, val) {
    state.showDeleteTranslationModal = val
  },
  UPDATE_SHOW_RESET_TRANSLATION_MODAL(state, val) {
    state.showResetTranslationModal = val
  },

  UPDATE_TRANSLATION_TO_BE_DELETED(state, val) {
    state.translationToBeDeleted = val
  },

  UPDATE_TRANSLATION_TO_BE_RESETED(state, val) {
    state.translationToBeReseted = val
  },

  /* QR Connect */
  UPDATE_SHOW_QR_WIDGET_SELECTION(state, val) {
    state.showQRWidgetSelection = val
  },
  UPDATE_SELECTED_QR_WIDGET(state, val) {
    state.selectedQRWidget = val

    /* Remove qrConnect property */
    if (!val) {
      state.translations.forEach((object) => {
        if (!state.defaultTranslations.includes(object.ln)) object['qrConnect'] = {}
      })
    }
  }
}
