/* eslint-disable implicit-arrow-linebreak */
const validationDictionary = {
  en: {
    fields: {
      displayName: 'Your name'
    },
    custom: {
      moreInformationLink: {
        required: 'Please add a More information link to continue'
      },
      moreInformationButtonText: {
        required: 'Please add button text to continue'
      }
    },
    messages: {
      required: (field) => {
        switch (field) {
          case 'choose-plan':
            return 'Please choose a plan'
          case 'widget-use':
            return 'Please select what you are using the widget for'
          default:
            return `${field} is required`
        }
      },
      email: () => 'E-mail address is badly formatted',
      min: (field, min) => `The ${field} must be at least ${min} characters`,
      min_value: (_, min) => `The field must be ${min} or more`,
      max_value: (_, max) => `The field must be ${max} or less`,
      max: (field, max) => `The ${field} may not be greater than ${max} characters`,
      confirmed: (field) => `The ${field} confirmation does not match`,
      isURLValid: () => 'URL must be valid',
      validateMultipeEmails: () => 'One or more e-mail address is badly formatted',
      validateMobile: () => 'Mobile phone must be valid',
      validatePhoneForRegion: () => 'Country dial-in code is required',
      validateEmail: () => 'Email must be valid',
      validateStringForSpecialCharacters: (field) => `The ${field} must not contain special characters like |\\/~^:,;?!&%$@*+`,
      verifyPassword: (field) =>
        `The ${field} must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,
      isValidDialogName: () => 'Dialog name is not valid or already exists',
      isValidABTestName: () => 'Test name is not valid or already exists',
      isValidCampaignName: () => 'The QR-Connect name is not valid or already exists'
    }
  },
  de: {
    names: {
      displayName: 'Vorname und Nachname'
    },
    custom: {
      moreInformationLink: {
        required: 'Bitte fügen Sie einen Link für mehr Informationen hinzu, um fortzufahren'
      },
      moreInformationButtonText: {
        required: 'Bitte fügen Sie den Text der Schaltfläche hinzu, um fortzufahren'
      }
    },
    messages: {
      required: (field) => {
        switch (field) {
          case 'widget-use':
            return 'Bitte wählen Sie aus, wofür Sie das Widget verwenden möchten'
          case 'email':
            return 'E-Mail-Adresse ist erforderlich'
          case 'name':
            return 'Name ist erforderlich'
          case 'phone':
            return 'Telefon ist erforderlich'
          case 'message':
            return 'Nachricht ist erforderlich'
          case 'privacy-agreement':
            return 'Datenschutzvereinbarung ist erforderlich'
          case 'date':
            return 'Datum ist erforderlich'
          case 'choose-plan':
            return 'Bitte wählen Sie einen Plan'
          default:
            return `${field} ist erforderlich`
        }
      },
      email: () => 'Bitte überprüfe deine eingegebene E-Mail-Adresse',
      min: (field, min) => `Das ${field} muss mindestens ${min} Zeichen lang sein`,
      max: (field, max) => `Das ${field} darf nicht länger als ${max} Zeichen sein`,
      min_value: (_, min) => `Das Feld muss ${min} oder mehr sein`,
      max_value: (_, max) => `Das Feld muss ${max} oder weniger sein`,
      confirmed: (field) => `Die ${field} Bestätigung stimmt nicht überein`,
      isURLValid: () => 'URL muss gültig sein',
      validateMultipeEmails: () => 'Eine oder mehrere E-Mail-Adressen sind schlecht formatiert',
      validateMobile: () => 'Handy muss gültig sein',
      validatePhoneForRegion: () => 'Die Einwahlnummer des Landes ist erforderlich',
      validateEmail: () => 'E-Mail muss gültig sein',
      validateStringForSpecialCharacters: (field) => `Das ${field} darf keine Sonderzeichen wie |\\/~^:,;?!&%$@*+ enthalten`,
      verifyPassword: (field) =>
        `Das ${field} muss mindestens enthalten: 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Ziffer und ein Sonderzeichen (z. B. , . _ & ? usw.)`,
      isValidDialogName: () => 'Dialogname ist ungültig oder existiert bereits',
      isValidABTestName: () => 'Testname ist nicht gültig oder existiert bereits',
      isValidCampaignName: () => 'Der QR-Connect-Name ist ungültig oder existiert bereits'
    }
  }
}

module.exports = validationDictionary
