var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lc-choose-pricing-plan" },
    [
      _vm.showPaymentFailedUI ? _c("PaymentFailed") : _vm._e(),
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "lc-choose-pricing-plan-close",
          on: { click: _vm.onClose },
        },
        [_c("div", { staticClass: "lc-choose-pricing-plan-close-icon" })]
      ),
      _c(
        "div",
        {
          staticClass: "lc-choose-pricing-plan-detail",
          class: { "lc-choose-pricing-plan-detail--full": _vm.showMollieVega },
        },
        [
          _c("div", { staticClass: "lc-choose-pricing-plan-detail-section" }, [
            _vm.newUidFlowStep > 1
              ? _c(
                  "div",
                  {
                    staticClass: "lc-choose-pricing-plan-detail-section--back",
                    on: { click: _vm.onUidFlowStepBack },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "lc-choose-pricing-plan-detail-section--back-icon",
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "16",
                              height: "15",
                              viewBox: "0 0 16 15",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M6.18329 0.842714L0.815366 6.67612C0.604004 6.90217 0.5 7.20113 0.5 7.50009C0.5 7.79905 0.604842 8.09729 0.814527 8.32479L6.18245 14.1582C6.60182 14.6139 7.2812 14.6139 7.70057 14.1582C8.11994 13.7025 8.11994 12.9642 7.70057 12.5084L4.16361 8.66677H14.4566C15.0501 8.66677 15.5 8.14432 15.5 7.5329C15.5 6.92149 15.0501 6.33341 14.4566 6.33341H4.16361L7.69973 2.49065C8.1191 2.03492 8.1191 1.29663 7.69973 0.840891C7.28036 0.385156 6.60266 0.386979 6.18329 0.842714Z",
                                fill: "#878893",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "lc-choose-pricing-plan-detail-section--back-text",
                      },
                      [_vm._v(_vm._s(_vm.$t("vue.back")))]
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass:
                  "lc-choose-pricing-plan-detail__progress-bar-wrapper",
              },
              [
                _c("div", {
                  staticClass: "lc-choose-pricing-plan-detail__progress-bar",
                  style: {
                    width:
                      (_vm.newUidFlowStep / _vm.totalCountOfSteps) * 100 + "%",
                  },
                }),
              ]
            ),
            _c(
              "div",
              { staticClass: "w-full" },
              [
                _c("ChooseCountry", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.newUidFlowStep === 1,
                      expression: "newUidFlowStep === 1",
                    },
                  ],
                  on: { "go-to-next-step": _vm.onUidFlowStepForward },
                }),
                _c("CompanyDetails", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.newUidFlowStep === 2,
                      expression: "newUidFlowStep === 2",
                    },
                  ],
                  on: { "go-to-next-step": _vm.onUidFlowStepForward },
                }),
                _c("PaymentDetails", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.newUidFlowStep === 3,
                      expression: "newUidFlowStep === 3",
                    },
                  ],
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      !_vm.showMollieVega && !_vm.showMollieGpt
        ? _c(
            "div",
            { staticClass: "lc-choose-pricing-plan-order" },
            [_c("OrderDetails")],
            1
          )
        : _vm._e(),
      _vm.showMollieGpt
        ? _c(
            "div",
            { staticClass: "lc-choose-pricing-plan-order" },
            [_c("OrderDetailsGpt")],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lc-choose-pricing-plan__logo" }, [
      _c("img", {
        staticClass: "lc-choose-pricing-plan-detail__logo-img",
        attrs: {
          src: require("@/assets/images/register-funnel/vega_logo.svg"),
          alt: "Logo",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }