export const commonFunctions = {
  methods: {
    generateRandomString(length) {
      const number = Math.random()
      const nstr = number.toString(36)
      return nstr.slice(-length)
    },
    getCookie(cname) {
      const name = `${cname}=`
      const decodedCookie = decodeURIComponent(document.cookie)
      const ca = decodedCookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    },
    getParameterByName(name) {
      const match = RegExp(`[?&]${name}=([^&]*)`).exec(window.location.search)
      return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
    },
    deleteCookie(name) {
      document.cookie = `${name}=;path=/;domain=${location.hostname};Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    },
    convertToCSV(objArray) {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
      let str = ''
      for (let i = 0; i < array.length; i++) {
        let line = ''
        for (const index in array[i]) {
          if (line !== '') line += ','

          line += array[i][index]
        }
        str += `${line}\r\n`
      }
      return str
    },
    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers)
      }

      // Convert Object to JSON
      const jsonObject = JSON.stringify(items)
      const csv = this.convertToCSV(jsonObject)
      const exportedFilename = `${fileTitle}.csv` || 'export.csv'

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilename)
      } else {
        const link = document.createElement('a')
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', exportedFilename)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }
    }
  }
}
