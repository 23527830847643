import firebase from 'firebase/app'
import 'firebase/auth'
import { mapActions } from 'vuex'

import { Device } from '@capacitor/device'
import { Preferences } from '@capacitor/preferences'

export const muser = {
  data() {
    return {}
  },
  computed: {},

  methods: {
    ...mapActions({
      resetMainStore: 'resetMainStore',
      resetInvoiceStore: 'invoice/resetInvoiceStore',
      resetDashboardStore: 'dashboard/resetDashboardStore'
    }),

    async getValueFromDataStorage(key) {
      try {
        const { value } = await Preferences.get({ key })

        return value
      } catch (error) {
        /* eslint-disable-next-line */
        console.log(error)
      }
    },

    async removeValueFromDataStorage(key) {
      try {
        await Preferences.remove({ key })
      } catch (error) {
        /* eslint-disable-next-line */
        console.log(error)
      }
    },

    async deleteNotificationsToken(user) {
      try {
        const { platform } = await Device.getInfo()

        if (!['ios', 'android'].includes(platform)) {
          return
        }

        const value = await this.getValueFromDataStorage('notificationsToken')

        if (!value) {
          return
        }

        const { uuid } = await Device.getId()

        let tokens = user.tokens || []

        const userRef = await firebase.firestore().collection('users').doc(user.uid).get()
        if (userRef && userRef.data() && userRef.data().tokens.length > 0) {
          tokens = userRef.data().tokens
        }

        const filteredTokens = tokens.filter((token) => token.uuid !== uuid && token.token !== value)

        await firebase.firestore().collection('users').doc(user.uid).set({ tokens: filteredTokens }, { merge: true })
        await this.removeValueFromDataStorage('notificationsToken')
      } catch (error) {
        /* eslint-disable-next-line */
        console.log(error)
      }
    },

    async LOGOUT_USER() {
      const firebaseCurrentUser = firebase.auth().currentUser

      if (firebaseCurrentUser) {
        await this.deleteNotificationsToken(firebaseCurrentUser)
        await firebase.auth().signOut()
      }

      const lsEndpoint = localStorage.getItem('pa-push-notification')
      const lsAudio = localStorage.getItem('pa-audio')
      const lsVideo = localStorage.getItem('pa-video')
      const lsSpeaker = localStorage.getItem('pa-speaker')
      const paShopifyState = localStorage.getItem('pa-shopify-state')

      localStorage.clear()
      if (lsEndpoint) {
        localStorage.setItem('pa-push-notification', lsEndpoint)
      }
      if (lsAudio) {
        localStorage.setItem('pa-audio', lsAudio)
      }
      if (lsVideo) {
        localStorage.setItem('pa-video', lsVideo)
      }
      if (lsSpeaker) {
        localStorage.setItem('pa-speaker', lsSpeaker)
      }
      if (paShopifyState) {
        localStorage.setItem('pa-shopify-state', paShopifyState)
      }

      this.resetDashboardStore()
      this.resetInvoiceStore()
      this.resetMainStore()
    },

    async USER_LOGOUT() {
      await this.LOGOUT_USER()
      // const dbs = await window.indexedDB.databases()
      // if (dbs) {
      //   dbs.forEach(async (db) => {
      //     if (db && db.name && db.name === 'firebaseLocalStorageDb') {
      //       await window.indexedDB.deleteDatabase(db.name)
      //     }
      //   })
      // }

      const channel = new BroadcastChannel('app-data')
      channel.postMessage({ type: 'logout' })
      await this.$vs.loading.close()
      window.location.reload(true)
    }
  }
}
