const plans = [
  {
    id: 'GPT_BASIC',
    name: 'Basic',
    description: 'GPT Connect Basic',
    billing_type: 'month',
    title: 'Basic',
    category: 'basic',
    messages: 2000,
    mostPopular: false,
    price: '19',
    level: 10,
    enabled: true,
    options: [
      {
        description: 'gpt.features.basicMessagesPerMonth',
        boldify: true
      },
      {
        description: 'gpt.features.unlimitedChatbots'
      },
      {
        description: 'gpt.features.uploadMultipleFiles'
      },
      {
        description: 'gpt.features.viewConversation'
      }
    ]
  },
  {
    id: 'GPT_PRO',
    name: 'Pro',
    description: 'GPT Connect Pro',
    billing_type: 'month',
    title: 'Pro',
    category: 'professional',
    messages: 10000,
    mostPopular: true,
    price: '99',
    enabled: true,
    level: 20,
    options: [
      {
        description: 'gpt.features.proMessagesPerMonth',
        boldify: true
      },
      {
        description: 'gpt.features.unlimitedChatbots'
      },
      {
        description: 'gpt.features.uploadMultipleFiles'
      },
      {
        description: 'gpt.features.viewConversation'
      }
    ]
  },
  {
    id: 'GPT_ENTERPRISE',
    name: 'Enterprise',
    description: 'GPT Connect Enterprise',
    billing_type: 'month',
    title: 'Enterprise',
    category: 'enterprise',
    messages: 40000,
    mostPopular: false,
    price: '349',
    enabled: true,
    level: 30,
    options: [
      {
        description: 'gpt.features.enterpriseMessagesPerMonth',
        boldify: true
      },
      {
        description: 'gpt.features.unlimitedChatbots'
      },
      {
        description: 'gpt.features.uploadMultipleFiles'
      },
      {
        description: 'gpt.features.viewConversation'
      }
    ]
  }
]

exports.plans = plans
