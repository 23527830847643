<template>
  <div class="mollie-checkout">
    <div class="mollie-checkout__checkout" v-if="supportedPaymentMethods.length > 1">
      <div class="mollie-checkout__checkout__buttons">
        <div
          v-if="supportedPaymentMethods.includes('card')"
          class="mollie-checkout__checkout__button"
          :class="{
            'mollie-checkout__checkout__button--active': paymentType === 'card'
          }"
          @click="() => showPaymentType('card')"
        >
          <div class="mollie-checkout__checkout__button__wrapper">
            <div class="flex flex-col">
              <div class="flex">
                <img class="m-px" src="@/assets/images/register-funnel/VISA.svg" alt="VISA" />
                <img class="m-px" src="@/assets/images/register-funnel/MASTERCARD.svg" alt="MASTERCARD" />
              </div>
              <div class="flex">
                <img class="m-px" src="@/assets/images/register-funnel/AMERICAN EXPRESS.svg" alt="AMERICAN EXPRESS" />
                <img class="m-px" src="@/assets/images/register-funnel/DISCOVER.svg" alt="DISCOVER" />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="supportedPaymentMethods.includes('paypal')"
          class="mollie-checkout__checkout__button mollie-checkout__checkout__button--paypal"
          @click="() => showPaymentType('paypal')"
        >
          <div class="mollie-checkout__checkout__button__wrapper">
            <img src="@/assets/images/register-funnel/paypal.png" alt="paypal" />
          </div>
        </div>

        <div
          v-if="supportedPaymentMethods.includes('invoice')"
          class="mollie-checkout__checkout__button"
          :class="{
            'mollie-checkout__checkout__button--active': paymentType === 'invoice'
          }"
          @click="() => showPaymentType('invoice')"
        >
          {{ $t('vue.invoice') }}
        </div>

        <div
          v-if="supportedPaymentMethods.includes('sepa')"
          class="mollie-checkout__checkout__button mollie-checkout__checkout__button--sepa"
          @click="() => showPaymentType('sepa')"
        >
          <div class="mollie-checkout__checkout__button__wrapper">
            <div class="flex flex-col">
              <img src="@/assets/images/register-funnel/sepa.svg" alt="sepa" />
              <img src="@/assets/images/register-funnel/Lastschrift.svg" alt="Lastschrift" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="mollie-checkout__holder"
      :class="{
        'mollie-checkout__holder--modal': isModal
      }"
    >
      <div v-if="isLoading" class="mollie-checkout__loading">
        <div v-if="isLoadingInProccess" class="mollie-checkout__loading__loader">
          <span class="mollie-checkout__loading__loader__spinner" />
          <div class="mollie-checkout__loading__loader__text">{{ loaderSpinnerText }}</div>
        </div>
        <div v-if="isLoadingSuccessful && !isLoadingInProccess" class="mollie-checkout__loading__loader">
          <img src="@/assets/images/register-funnel/check.png" alt="Success" />
          <div class="mollie-checkout__loading__loader__title">All good!</div>
          <div class="mollie-checkout__loading__loader__text" v-if="isFirstInvoice">We’ll redirect you to your account</div>
        </div>
      </div>

      <div v-show="paymentType === 'card' && mollie && areMollieComponentsReady">
        <div class="wrapper">
          <form class="form" id="mcForm" ref="mcForm" @submit.prevent="onMollieCreditCardPayment">
            <div class="mollie-checkout__fields-container form-fields relative overflow-hidden">
              <div class="row">
                <div class="form-group form-group--card-holder">
                  <div id="card-holder" ref="card-holder"></div>
                  <label class="label" for="card-holder"><div>Card holder</div></label>
                  <div id="card-holder-error" class="field-error" role="alert" ref="card-holder-error"></div>
                </div>
              </div>
              <div class="row">
                <div class="form-group form-group--card-number">
                  <div id="card-number" ref="card-number"></div>
                  <label class="label" for="card-number"><div>Card number</div></label>
                  <div id="card-number-error" class="field-error" role="alert" ref="card-number-error"></div>
                </div>
              </div>
              <div class="row">
                <div class="form-group form-group--expiry-date">
                  <label class="label" for="expiry-date"> <div>Expiry date</div></label>
                  <div id="expiry-date" ref="expiry-date"></div>
                  <div id="expiry-date-error" class="field-error" role="alert" ref="expiry-date-error"></div>
                </div>

                <div class="form-group form-group--verification-code">
                  <label class="label" for="verification-code"><div>CVC</div></label>
                  <div id="verification-code" ref="verification-code"></div>
                  <div id="verification-code-error" class="field-error" role="alert" ref="verification-code-error"></div>
                </div>
              </div>
            </div>

            <!-- COUPON CODE -->
            <Promocode v-if="!updatePaymentMethod" />
            <!-- COUPON CODE -->

            <div class="flex items-center justify-center">
              <button class="mollie-checkout__next-button" id="submit-button" type="submit" ref="submit-button">
                {{ btnTitle }}
                <img src="@/assets/images/register-funnel/forward-arrow.png" alt="Secure" class="mollie-checkout__next-button__icon" />
              </button>
            </div>
            <div id="form-error" class="field-error" role="alert" ref="form-error"></div>
          </form>
        </div>
      </div>
      <Promocode v-if="!selectedPaymentMethod && !updatePaymentMethod" />

      <div v-show="paymentType === 'paypal'">
        <div class="mollie-checkout__paypal__wrapper">Paypal</div>
      </div>

      <div v-show="paymentType === 'sepa'">Sepa</div>

      <div v-show="paymentType === 'invoice'">
        <div class="mollie-checkout__invoice__wrapper">
          <div class="mollie-checkout__invoice__wrapper__content">
            <div class="mollie-checkout__invoice__wrapper__content__form">
              <div class="mollie-checkout__invoice__wrapper__content__form__fields">
                <div class="mollie-checkout__invoice__wrapper__content__form__fields__field mt-0" style="max-width: 179px">
                  <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                    <multiselect
                      ref="title"
                      v-model="invoice.title"
                      :options="titles"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :placeholder="''"
                      :selectLabel="''"
                      :selectedLabel="''"
                      :deselectLabel="''"
                      label="name"
                      track-by="name"
                      :name="$t('inputs.salutation')"
                      class="mollie-checkout__invoice__wrapper__content__form__fields__field__select mollie-checkout__invoice__wrapper__content__form__fields__field__select--small-min-height"
                      open-direction="bottom"
                      v-validate="'required'"
                      :data-vv-as="$t('inputs.salutation')"
                      :preserve-search="true"
                      :preselect-first="false"
                      @close="isOpenTitleSelect = false"
                      @open="isOpenTitleSelect = true"
                    >
                      <template slot="noResult">No results found</template>
                    </multiselect>

                    <div
                      class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                      :class="{
                        'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved': !!invoice.title || isOpenTitleSelect,
                        'mollie-checkout__invoice__wrapper__content__form__fields__field__label--over': isOpenTitleSelect
                      }"
                      @click="$refs.title.$el.focus()"
                    >
                      {{ $t('inputs.salutation') }} *
                    </div>
                  </div>

                  <span v-if="errors.first($t('inputs.salutation'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                    <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.salutation')) }}
                  </span>
                </div>
                <div class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                  <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                    <input
                      ref="academicTitle"
                      type="text"
                      class="mollie-checkout__invoice__wrapper__content__form__fields__field__input"
                      v-model="invoice.academicTitle"
                      :name="$t('inputs.academicTitle')"
                      :label-placeholder="$t('inputs.academicTitle')"
                    />

                    <div
                      class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                      :class="{
                        'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved':
                          invoice.academicTitle && invoice.academicTitle.length > 0
                      }"
                      @click="$refs.academicTitle.focus()"
                    >
                      {{ $t('inputs.academicTitle') }}
                    </div>
                  </div>
                </div>
                <div class="mollie-checkout__invoice__wrapper__content__form__fullname">
                  <div class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                    <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                      <input
                        ref="firstName"
                        type="text"
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__input"
                        v-model="invoice.firstName"
                        v-validate="'required'"
                        :data-vv-as="$t('inputs.firstName')"
                        :name="$t('inputs.firstName')"
                        :label-placeholder="$t('inputs.firstName')"
                      />

                      <div
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                        :class="{
                          'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved': invoice.firstName && invoice.firstName.length > 0
                        }"
                        @click="$refs.firstName.focus()"
                      >
                        {{ $t('inputs.firstName') }} *
                      </div>
                    </div>

                    <span v-if="errors.first($t('inputs.firstName'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                      <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.firstName')) }}
                    </span>
                  </div>
                  <div class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                    <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                      <input
                        ref="lastName"
                        type="text"
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__input"
                        v-model="invoice.lastName"
                        v-validate="'required'"
                        :data-vv-as="$t('inputs.lastName')"
                        :name="$t('inputs.lastName')"
                        :label-placeholder="$t('inputs.lastName')"
                      />

                      <div
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                        :class="{
                          'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved': invoice.lastName && invoice.lastName.length > 0
                        }"
                        @click="$refs.lastName.focus()"
                      >
                        {{ $t('inputs.lastName') }} *
                      </div>
                    </div>

                    <span v-if="errors.first($t('inputs.lastName'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                      <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.lastName')) }}
                    </span>
                  </div>
                </div>

                <div class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                  <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                    <input
                      ref="company"
                      type="text"
                      class="mollie-checkout__invoice__wrapper__content__form__fields__field__input"
                      v-model="invoice.company"
                      v-validate="'required'"
                      :data-vv-as="$t('inputs.company')"
                      :name="$t('inputs.company')"
                      :label-placeholder="$t('inputs.company')"
                      @change="$validator.validate($t('inputs.vatNumber'))"
                    />

                    <div
                      class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                      :class="{
                        'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved': invoice.company && invoice.company.length > 0
                      }"
                      @click="$refs.company.focus()"
                    >
                      {{ $t('inputs.company') }} *
                    </div>
                  </div>

                  <span v-if="errors.first($t('inputs.company'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                    <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.company')) }}
                  </span>
                </div>

                <div class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                  <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                    <input
                      v-if="paymentType === 'invoice'"
                      ref="vatNumber"
                      type="text"
                      class="mollie-checkout__invoice__wrapper__content__form__fields__field__input"
                      v-model.lazy="invoice.vatNumber"
                      :name="$t('inputs.vatNumber')"
                      :label-placeholder="$t('inputs.vatNumber')"
                      v-validate="'isvatvalid'"
                      data-vv-validate-on="change"
                    />

                    <span v-if="errors.first($t('inputs.vatNumber'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                      <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.vatNumber')) }}
                    </span>

                    <div
                      class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                      :class="{
                        'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved': invoice.vatNumber && invoice.vatNumber.length > 0
                      }"
                      @click="$refs.vatNumber.focus()"
                    >
                      {{ $t('inputs.vatNumber') }}
                    </div>
                  </div>
                </div>

                <div class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                  <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                    <input
                      ref="streetAddress"
                      type="text"
                      class="mollie-checkout__invoice__wrapper__content__form__fields__field__input"
                      v-model="invoice.streetAddress"
                      v-validate="'required'"
                      :data-vv-as="$t('inputs.streetAddress')"
                      :name="$t('inputs.streetAddress')"
                      :label-placeholder="$t('inputs.streetAddress')"
                    />

                    <div
                      class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                      :class="{
                        'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved':
                          invoice.streetAddress && invoice.streetAddress.length > 0
                      }"
                      @click="$refs.streetAddress.focus()"
                    >
                      {{ $t('inputs.streetAddress') }} *
                    </div>
                  </div>

                  <span v-if="errors.first($t('inputs.streetAddress'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                    <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.streetAddress')) }}
                  </span>
                </div>

                <div class="mollie-checkout__invoice__wrapper__content__form__equal-parts">
                  <div class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                    <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                      <input
                        ref="city"
                        type="text"
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__input"
                        v-model="invoice.city"
                        v-validate="'required'"
                        :data-vv-as="$t('inputs.city')"
                        :name="$t('inputs.city')"
                        :label-placeholder="$t('inputs.city')"
                      />

                      <div
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                        :class="{
                          'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved': invoice.city && invoice.city.length > 0
                        }"
                        @click="$refs.city.focus()"
                      >
                        {{ $t('inputs.city') }} *
                      </div>
                    </div>

                    <span v-if="errors.first($t('inputs.city'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                      <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.city')) }}
                    </span>
                  </div>
                  <div class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                    <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                      <input
                        ref="zipOrPostalCode"
                        type="text"
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__input"
                        v-model="invoice.zipOrPostalCode"
                        v-validate="'required'"
                        :data-vv-as="$t('inputs.zipOrPostalCode')"
                        :name="$t('inputs.zipOrPostalCode')"
                        :label-placeholder="$t('inputs.zipOrPostalCode')"
                      />

                      <div
                        class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                        :class="{
                          'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved':
                            invoice.zipOrPostalCode && invoice.zipOrPostalCode.length > 0
                        }"
                        @click="$refs.zipOrPostalCode.focus()"
                      >
                        {{ $t('inputs.zipOrPostalCode') }} *
                      </div>
                    </div>

                    <span v-if="errors.first($t('inputs.zipOrPostalCode'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                      <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.zipOrPostalCode')) }}
                    </span>
                  </div>
                </div>

                <div class="mollie-checkout__invoice__wrapper__content__form__fields__field">
                  <div class="mollie-checkout__invoice__wrapper__content__form__fields__field__inner">
                    <multiselect
                      ref="country"
                      v-model="invoice.country"
                      :options="countryCodes"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :placeholder="''"
                      :selectLabel="''"
                      :selectedLabel="''"
                      :deselectLabel="''"
                      label="countryName"
                      track-by="name"
                      :name="$t('inputs.country')"
                      class="mollie-checkout__invoice__wrapper__content__form__fields__field__select"
                      open-direction="bottom"
                      v-validate="'required'"
                      :data-vv-as="$t('inputs.country')"
                      :preserve-search="true"
                      :preselect-first="false"
                      @close="isOpenCountrySelect = false"
                      @open="isOpenCountrySelect = true"
                    >
                      <template slot="noResult">No results found</template>
                    </multiselect>

                    <div
                      class="mollie-checkout__invoice__wrapper__content__form__fields__field__label"
                      :class="{
                        'mollie-checkout__invoice__wrapper__content__form__fields__field__label--moved': !!invoice.country || isOpenCountrySelect,
                        'mollie-checkout__invoice__wrapper__content__form__fields__field__label--over': isOpenCountrySelect
                      }"
                      @click="$refs.country.$el.focus()"
                    >
                      {{ $t('inputs.country') }} *
                    </div>
                  </div>

                  <span v-if="errors.first($t('inputs.country'))" class="text-danger text-lg flex items-center" style="margin-top: 10px">
                    <img src="@/assets/images/pages/login/error-icon.svg" class="mr-2" />{{ errors.first($t('inputs.country')) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-center">
          <button class="mollie-checkout__next-button" @click.prevent="onInvoiceSubmit">
            {{ btnTitle }}
            <img src="@/assets/images/register-funnel/forward-arrow.png" alt="Secure" class="mollie-checkout__next-button__icon" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
require('dayjs/locale/de')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

import Promocode from './Promocode.vue'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'
// VeeValidate
import { Validator } from 'vee-validate'
// Merge all defined languages in the dictionary and sets the current locale.
import validationDictionary from '@/validations/validationDictionary'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Mollie',
  components: {
    Promocode,
    Multiselect
  },
  props: {
    IS_LOTTERY_WINNER: {
      type: Boolean,
      default: false
    },
    redirectUrl: {
      type: String,
      required: false
    },
    updatePaymentMethod: {
      type: Boolean,
      default: false
    },
    subscriptionPlanId: {
      type: String,
      required: false
    },
    isFirstInvoice: {
      type: Boolean,
      default: false
    },
    btnTitle: {
      type: String,
      required: false,
      default: 'Pay now'
    },
    selectedPlan: {
      type: Object,
      required: false
    },
    supportedPaymentMethods: {
      type: Array,
      required: false,
      default() {
        return ['invoice', 'card', 'paypal', 'sepa']
      }
    },
    isModal: {
      type: Boolean,
      default: false
    }
  },
  data: (state) => ({
    isLoading: false,
    isLoadingInProccess: false,
    isLoadingSuccessful: false,
    loaderSpinnerText: '',
    paymentType: null,
    isOpenCountrySelect: false,
    isOpenTitleSelect: false,
    countryCodes: state.$i18n.locale === 'de' ? require('@/assets/countryCodes_de.json') : require('@/assets/countryCodes.json'),
    titles: [
      {
        id: 0,
        name: 'Herr'
      },
      {
        id: 1,
        name: 'Frau'
      }
    ],
    invoice: {
      title: '',
      academicTitle: '',
      firstName: '',
      lastName: '',
      company: '',
      companyType: '',
      vatNumber: '',
      email: '',
      streetAddress: '',
      city: '',
      zipOrPostalCode: '',
      country: '',
      selectedPlanId: '',
      status: 'open'
    },
    mollie: null,
    areMollieComponentsReady: false,
    mollieCardComponents: {
      cardNumber: null,
      cardHolder: null,
      cardExpiryDate: null,
      cardVerificationCode: null
    }
  }),
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      currency: 'currency',
      appliedPromoCode: 'appliedPromoCode',
      selectedPaymentMethod: 'selectedPaymentMethod'
    })
  },
  created() {
    /* Localize validator */
    Validator.localize(this.$i18n.locale, validationDictionary[this.$i18n.locale])
  },
  async mounted() {
    //this.isLoading = true
    //this.isLoadingInProccess = true

    if (this.IS_LOTTERY_WINNER) {
      this.updateCurrency({ text: 'EUR', symbol: '€' })
    }

    if (this.company) {
      this.invoice.company = this.company.name || ''
    }

    if (this.company && this.company.vatNumber) {
      this.invoice.vatNumber = this.company.vatNumber
    }

    if (this.company && this.company.vatNumber) {
      this.invoice.vatNumber = this.company.vatNumber
    }

    Validator.extend(
      'isvatvalid',
      {
        validate: this.isVatValid,
        getMessage: (field, params, data) => data.message
      },
      {
        immediate: false
      }
    )
    this.$serverBus.$on('setup-mollie-components', (paymentType) => {
      if (paymentType === 'card') {
        //this.setupMollieComponents()
      }
    })

    this.$serverBus.$on('clear-payment-type', () => {
      this.paymentType = null
    })
    this.setupMollieComponents()
    setTimeout(() => {
      this.isLoadingInProccess = false
      this.isLoadingSuccessful = false
      this.isLoading = false
    }, 2000)
  },

  beforeDestroy() {
    this.$serverBus.$off('setup-mollie-components')
    this.$serverBus.$off('clear-payment-type')
  },

  watch: {
    'invoice.country.code'(after, before) {
      if (before && after) {
        this.$validator.validate(this.$i18n.t('inputs.vatNumber'))
      }
    },

    'invoice.vatNumber'(after, before) {
      if (before && after) {
        this.invoice.vatNumber = this.invoice.vatNumber.toUpperCase()
        this.checkIfVatHasCountryCode()
      }
    },
    paymentType() {
      if (this.paymentType !== 'card') {
        this.$nextTick(() => {
          this.errors.clear()
          this.$validator.reset()
        })
      }
    }
  },

  methods: {
    ...mapMutations({
      updateCurrency: 'UPDATE_CURRENCY',
      updateShowChangePlanUI: 'UPDATE_SHOW_CHANGE_PLAN_UI',
      setPaymentMethod: 'SET_PAYMENT_METHOD',
      updateAppliedPromocode: 'UPDATE_APPLIED_PROMOCODE'
    }),

    async applyPromoCodeOfInvoice() {
      if (this.company && this.company.currentInvoiceId) {
        const invoiceRef = await this.$db.collection('pathadvice-invoices').doc(this.company.currentInvoiceId).get()
        const invoice = invoiceRef.data()
        if (invoice && invoice.appliedPromoCodeId) {
          const promoCodeRef = await this.$db.collection('promo-codes').doc(invoice.appliedPromoCodeId).get()
          const promoCode = promoCodeRef.data()
          if (promoCode) {
            promoCode.id = invoice.appliedPromoCodeId
            this.updateAppliedPromocode(promoCode)
          }
        }
      }
    },

    hasNumber(code) {
      return /\d/.test(code)
    },
    checkIfVatHasCountryCode() {
      if (!this.invoice || !this.invoice.vatNumber || this.invoice.vatNumber.length < 2) return false
      const vat = this.invoice.vatNumber.toLowerCase()
      const countryCodeVat = vat.substr(0, 2)
      /* Return false if country code has digits */
      if (this.hasNumber(countryCodeVat)) return false

      const countryCode = this.countryCodes.find((x) => x.code === countryCodeVat)
      if (countryCode) {
        this.invoice.country = countryCode
      }

      if (!countryCode) {
        return false
      }

      return true
    },

    customSpinnerLoad(enable, text) {
      if (enable) {
        this.loaderSpinnerText = text
        this.isLoading = enable
        this.isLoadingInProccess = enable
      } else {
        this.loaderSpinnerText = ''
        this.isLoading = enable
        this.isLoadingInProccess = enable
      }
    },

    isVatValid() {
      const vm = this
      return new Promise((resolve) => {
        vm.customSpinnerLoad(true, this.$i18n.t('vue.validatingVAT'))

        if (!vm.checkIfVatHasCountryCode()) {
          setTimeout(() => {
            vm.customSpinnerLoad(false)
          }, 100)
          return resolve({
            valid: false,
            data: {
              message: vm.$i18n.t('error.VV_002')
            }
          })
        }

        if (!vm.invoice || !vm.invoice.company || (vm.invoice && vm.invoice.company && vm.invoice.company.trim().length === 0)) {
          setTimeout(() => {
            vm.customSpinnerLoad(false)
          }, 100)
          return resolve({
            valid: false,
            data: {
              message: vm.$i18n.t('error.VV_004')
            }
          })
        }

        if (!vm.invoice || !vm.invoice.country || !vm.invoice.country.code) {
          setTimeout(() => {
            vm.customSpinnerLoad(false)
          }, 100)
          return resolve({
            valid: false,
            data: {
              message: vm.$i18n.t('error.VV_001')
            }
          })
        }

        if (!vm.invoice || !vm.invoice.vatNumber || vm.invoice.vatNumber.length <= 2) {
          setTimeout(() => {
            vm.customSpinnerLoad(false)
          }, 100)
          return resolve({
            valid: false,
            data: {
              message: vm.$i18n.t('error.VV_003')
            }
          })
        }

        const axios = require('axios')
        const vatNumber = vm.invoice.vatNumber.substring(2)
        const companyName = encodeURI(vm.invoice.company)
        axios
          .get(`${vm.$functionsURL}/validateVat?cc=${vm.invoice.country.code}&vat=${vatNumber}&cp=${companyName}`)
          .then(function (response) {
            if (response && response.data && response.data.success) {
              vm.customSpinnerLoad(false)
              return resolve({
                valid: true
              })
            }
            if (response && response.data && response.data.error) {
              vm.customSpinnerLoad(false)
              return resolve({
                valid: false,
                data: {
                  message: vm.$i18n.t(response.data.error)
                }
              })
            }
          })
          .catch(function (error) {
            vm.customSpinnerLoad(false)
            return resolve({
              valid: false,
              data: {
                message: error.message
              }
            })
          })
      })
    },
    showPaymentType(type) {
      if (this.paymentType === type) {
        return
      }
      this.paymentType = type
      this.setPaymentMethod(type)

      switch (this.paymentType) {
        case 'paypal':
          this.loaderSpinnerText = `Redirecting you to ${this.paymentType}...`
          this.isLoading = true
          this.isLoadingInProccess = true
          this.onMolliePayPal()
          break
        case 'sepa':
          this.loaderSpinnerText = `Redirecting you to ${this.paymentType}...`
          this.isLoading = true
          this.isLoadingInProccess = true
          this.onMollieSepa()
          break

        default:
          break
      }
    },
    async onInvoiceSubmit(event) {
      event.preventDefault()
      const result = await this.$validator.validateAll()

      if (!result) {
        return
      }
      this.loaderSpinnerText = 'Processing...'
      this.isLoading = true
      this.isLoadingInProccess = true

      try {
        const invoiceData = {
          ...this.invoice,
          companyId: this.activeUserInfo.company,
          selectedPlanId: this.subscriptionPlanId,
          isFirstInvoice: this.isFirstInvoice || false,
          createdAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
          emailLink: `${window.location.protocol}//${window.location.host}/payment?showInvoice=`
        }
        if (this.isFirstInvoice) {
          /* First Invoice is free */
          invoiceData.currency = this.currency
          invoiceData.amount = {}
          invoiceData.amount[this.currency.text] = '0.00'
          invoiceData.tax = '0.00'
          invoiceData.percent = '0.0'
          invoiceData.status = 'on-trial'
          invoiceData.valueGreaterThanZero = false
        } else {
          invoiceData.status = 'open'
        }

        const _company = {
          city: invoiceData.city,
          name: invoiceData.company,
          country: invoiceData.country,
          countryCode: invoiceData.country.code,
          street: invoiceData.streetAddress,
          vatNumber: invoiceData.vatNumber,
          zipcode: invoiceData.zipOrPostalCode
        }
        if (this.currency && this.currency.text) {
          _company.currency = this.currency
        }
        await this.$db.collection('company').doc(invoiceData.companyId).set(_company, { merge: true })

        const invoiceRef = await this.$db.collection('pathadvice-invoices').add(invoiceData)
        if (invoiceRef && invoiceRef.id) {
          if (this.isFirstInvoice && this.company && !this.company.isFirstPaymentCreated) {
            await this.$db.collection('company').doc(invoiceData.companyId).set({ isFirstPaymentCreated: true }, { merge: true })
          }

          if (!this.isModal) {
            this.isLoadingInProccess = false
            this.isLoadingSuccessful = true
          }

          setTimeout(() => {
            this.isLoadingInProccess = false
            this.isLoadingSuccessful = true
            this.isLoading = false
            this.$serverBus.$emit('invoice-created')

            if (this.isModal) {
              this.isLoading = false
            }
          }, 2000)
        }
      } catch (error) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
        setTimeout(() => {
          this.isLoadingInProccess = false
          this.isLoadingSuccessful = false
          this.isLoading = false
          this.loaderSpinnerText = ''
        }, 2000)
      }
    },
    onDisplayError(message) {
      this.$vs.notify({
        title: this.$i18n.t('vue.error'),
        text: this.$i18n.t(message),
        color: 'danger',
        time: 10000
      })
    },
    initializeMollie() {
      const mollieLocale = this.getMollieLocale()
      this.mollie = window.Mollie(this.$mollieProfileId, { locale: mollieLocale, testmode: this.$mollieTestMode })
    },
    getMollieLocale() {
      const locale = this.$i18n.locale
      let mollieLocale = 'en_US'
      switch (locale) {
        case 'en':
          mollieLocale = 'en_US'
          break

        case 'de':
          mollieLocale = 'de_DE'
          break
      }
      return mollieLocale
    },
    async onMolliePayPal() {
      await this.applyPromoCodeOfInvoice()
      const mollieCreateNewPayment = this.$functions.httpsCallable('mollieCreateNewPayment')
      const IS_LOTTERY_WINNER = this.IS_LOTTERY_WINNER || this.company.IS_LOTTERY_WINNER
      const payment = {
        customerName: this.activeUserInfo.displayName || `${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}`,
        customerEmail: this.activeUserInfo.email,
        locale: this.getMollieLocale(),
        metadata: {
          companyId: this.activeUserInfo.company,
          IS_LOTTERY_WINNER,
          userId: this.activeUserInfo.uid,
          planId: this.selectedPlan.id,
          type: this.updatePaymentMethod ? 'update_payment_method' : 'new',
          currency: this.currency
        },
        redirectUrl: this.redirectUrl || location.href,
        planId: this.selectedPlan.id,
        description: this.selectedPlan.description,
        sequenceType: 'first',
        method: 'paypal'
      }

      if (this.company.mollieCustomerId) {
        payment.mollieCustomerId = this.company.mollieCustomerId
        payment.metadata.customerId = this.company.mollieCustomerId
      }
      if (this.company.mollieSubscriptionId) {
        payment.metadata.mollieSubscriptionId = this.company.mollieSubscriptionId
      }
      if (this.company.currentInvoiceId) {
        payment.metadata.currentInvoiceId = this.company.currentInvoiceId
      }
      if (this.appliedPromoCode && this.appliedPromoCode.id && this.appliedPromoCode.enabled) {
        payment.metadata.appliedPromoCodeId = this.appliedPromoCode.id
      }
      const transaction = await mollieCreateNewPayment(payment)

      if (transaction && transaction.data && transaction.data.error) {
        this.onDisplayError(transaction.data.message)
        return
      }

      if (transaction && transaction.data && transaction.data._links && transaction.data._links.checkout && transaction.data._links.checkout.href) {
        window.open(transaction.data._links.checkout.href, '_self')
      }

      //this.$serverBus.$emit('close-mollie-form')
    },

    async onMollieSepa() {
      await this.applyPromoCodeOfInvoice()
      const mollieCreateNewPayment = this.$functions.httpsCallable('mollieCreateNewPayment')
      const IS_LOTTERY_WINNER = this.IS_LOTTERY_WINNER || this.company.IS_LOTTERY_WINNER
      const payment = {
        customerName: this.activeUserInfo.displayName || `${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}`,
        customerEmail: this.activeUserInfo.email,
        locale: this.getMollieLocale(),
        metadata: {
          companyId: this.activeUserInfo.company,
          userId: this.activeUserInfo.uid,
          IS_LOTTERY_WINNER,
          planId: this.selectedPlan.id,
          type: this.updatePaymentMethod ? 'update_payment_method' : 'new',
          currency: this.currency
        },
        redirectUrl: location.href,
        planId: this.selectedPlan.id,
        description: this.selectedPlan.id,
        method: 'directdebit'
      }

      if (this.appliedPromoCode && this.appliedPromoCode.id && this.appliedPromoCode.enabled) {
        payment.metadata.appliedPromoCodeId = this.appliedPromoCode.id
      }

      if (this.company.mollieCustomerId) {
        payment.mollieCustomerId = this.company.mollieCustomerId
        payment.metadata.customerId = this.company.mollieCustomerId
      }
      if (this.company.mollieSubscriptionId) {
        payment.metadata.mollieSubscriptionId = this.company.mollieSubscriptionId
      }
      if (this.company.currentInvoiceId) {
        payment.metadata.currentInvoiceId = this.company.currentInvoiceId
      }
      const createPaymentResponse = await mollieCreateNewPayment(payment)
      if (createPaymentResponse && createPaymentResponse.data && createPaymentResponse.data.error) {
        this.onDisplayError(createPaymentResponse.data.message)
      }
      this.$serverBus.$emit('close-mollie-form')
    },

    async onMollieCreditCardPayment() {
      this.loaderSpinnerText = 'Processing...'
      this.isLoading = true
      this.isLoadingInProccess = true
      await this.applyPromoCodeOfInvoice()

      const { token, error } = await this.mollie.createToken()
      if (error) {
        this.onDisplayError(error.message)

        setTimeout(() => {
          this.isLoadingInProccess = false
          this.isLoadingSuccessful = false
          this.isLoading = false
        }, 100)

        return
      }

      const mollieCreateNewPayment = this.$functions.httpsCallable('mollieCreateNewPayment')
      const IS_LOTTERY_WINNER = this.IS_LOTTERY_WINNER || this.company.IS_LOTTERY_WINNER
      const payment = {
        customerName: this.activeUserInfo.displayName || `${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}`,
        customerEmail: this.activeUserInfo.email,
        locale: this.getMollieLocale(),
        metadata: {
          IS_LOTTERY_WINNER,
          companyId: this.activeUserInfo.company,
          userId: this.activeUserInfo.uid,
          planId: this.selectedPlan.id,
          type: this.updatePaymentMethod ? 'update_payment_method' : 'new',
          currency: this.currency
        },
        redirectUrl: this.redirectUrl || location.href,
        planId: this.selectedPlan.id,
        description: this.selectedPlan.description,
        sequenceType: 'first',
        method: 'creditcard',
        cardToken: token
      }

      if (this.appliedPromoCode && this.appliedPromoCode.id && this.appliedPromoCode.enabled) {
        payment.metadata.appliedPromoCodeId = this.appliedPromoCode.id
      }

      if (this.company.mollieCustomerId) {
        payment.mollieCustomerId = this.company.mollieCustomerId
        payment.metadata.customerId = this.company.mollieCustomerId
      }
      if (this.company.mollieSubscriptionId) {
        payment.metadata.mollieSubscriptionId = this.company.mollieSubscriptionId
      }
      if (this.company.currentInvoiceId) {
        payment.metadata.currentInvoiceId = this.company.currentInvoiceId
      }
      const transaction = await mollieCreateNewPayment(payment)
      if (transaction && transaction.data && transaction.data._links && transaction.data._links.checkout && transaction.data._links.checkout.href) {
        window.open(transaction.data._links.checkout.href, '_self')
        return
      }

      if (transaction && transaction.data && transaction.data.status === 'paid') {
        if (!this.company.isFirstPaymentCreated) {
          await this.$db.collection('company').doc(this.company.id).set({ isFirstPaymentCreated: true }, { merge: true })
        }

        if (this.isModal) {
          this.$serverBus.$emit('close-mollie-form', {
            transaction
          })
        }

        setTimeout(() => {
          this.isLoadingInProccess = false
          this.isLoadingSuccessful = true
        }, 2000)

        setTimeout(() => {
          this.isLoading = false
          this.isLoadingSuccessful = false
          this.loaderSpinnerText = ''
          this.updateShowChangePlanUI(false)
          window.location.href = '/payment/success'
        }, 4000)
      } else {
        this.$vs.notify({
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('info.somethingWentWrong'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })
        setTimeout(() => {
          this.updateShowChangePlanUI(false)
        }, 2000)
      }
    },
    toggleFieldClass(elementClassObj) {
      const element = document.getElementById(elementClassObj.elementId)

      Object.keys(elementClassObj.toggleClassesObject).forEach((key) => {
        if (typeof elementClassObj.toggleClassesObject[key] !== 'boolean') {
          return
        }

        if (elementClassObj.toggleClassesObject[key]) {
          element.parentNode.classList.add(`is-${key}`)
        } else {
          element.parentNode.classList.remove(`is-${key}`)
        }
      })
    },
    async setupMollieComponents() {
      this.areMollieComponentsReady = false
      await this.initializeMollie()
      setTimeout(() => {
        if (this.mollie) {
          /**
           * Get elements
           */

          const options = {
            styles: {
              base: {
                color: '#878894',
                fontSize: '18px',
                '::placeholder': {
                  color: 'rgba(68, 68, 68, 0.2)'
                }
              },
              valid: {
                color: '#42b5ff'
              }
            }
          }

          /**
           * Create card holder input
           */
          this.mollieCardComponents.cardHolder = this.mollie.createComponent('cardHolder', options)
          this.mollieCardComponents.cardHolder.mount(this.$refs['card-holder'])
          const cardHolderError = this.$refs['card-holder-error']

          this.mollieCardComponents.cardHolder.addEventListener('change', (event) => {
            this.toggleFieldClass({
              elementId: 'card-holder',
              toggleClassesObject: event
            })
            if (event.error && event.touched) {
              cardHolderError.textContent = event.error
            } else {
              cardHolderError.textContent = ''
            }
          })

          /**
           * Create card number input
           */
          this.mollieCardComponents.cardNumber = this.mollie.createComponent('cardNumber', options)
          this.mollieCardComponents.cardNumber.mount(this.$refs['card-number'])

          const cardNumberError = this.$refs['card-number-error']

          this.mollieCardComponents.cardNumber.addEventListener('change', (event) => {
            this.toggleFieldClass({
              elementId: 'card-number',
              toggleClassesObject: event
            })
            if (event.error && event.touched) {
              cardNumberError.textContent = event.error
            } else {
              cardNumberError.textContent = ''
            }
          })

          /**
           * Create expiry date input
           */
          this.mollieCardComponents.cardExpiryDate = this.mollie.createComponent('expiryDate', options)
          this.mollieCardComponents.cardExpiryDate.mount(this.$refs['expiry-date'])

          const expiryDateError = this.$refs['expiry-date-error']

          this.mollieCardComponents.cardExpiryDate.addEventListener('change', (event) => {
            this.toggleFieldClass({
              elementId: 'expiry-date',
              toggleClassesObject: event
            })
            if (event.error && event.touched) {
              expiryDateError.textContent = event.error
            } else {
              expiryDateError.textContent = ''
            }
          })

          /**
           * Create verification code input
           */
          this.mollieCardComponents.cardVerificationCode = this.mollie.createComponent('verificationCode', options)
          this.mollieCardComponents.cardVerificationCode.mount(this.$refs['verification-code'])

          const verificationCodeError = this.$refs['verification-code-error']

          this.mollieCardComponents.cardVerificationCode.addEventListener('change', (event) => {
            this.toggleFieldClass({
              elementId: 'verification-code',
              toggleClassesObject: event
            })
            if (event.error && event.touched) {
              verificationCodeError.textContent = event.error
            } else {
              verificationCodeError.textContent = ''
            }
          })
          // Some extra listeners for styling purposes
          this.mollieCardComponents.cardHolder.addEventListener('focus', () => {
            this.toggleFieldClass({
              elementId: 'card-holder',
              toggleClassesObject: { focussed: true }
            })
          })

          this.mollieCardComponents.cardHolder.addEventListener('blur', () => {
            this.toggleFieldClass({
              elementId: 'card-holder',
              toggleClassesObject: { focussed: false }
            })
          })

          this.mollieCardComponents.cardNumber.addEventListener('focus', () => {
            this.toggleFieldClass({
              elementId: 'card-number',
              toggleClassesObject: { focussed: true }
            })
          })

          this.mollieCardComponents.cardNumber.addEventListener('blur', () => {
            this.toggleFieldClass({
              elementId: 'card-number',
              toggleClassesObject: { focussed: false }
            })
          })

          this.mollieCardComponents.cardExpiryDate.addEventListener('focus', () => {
            this.toggleFieldClass({
              elementId: 'expiry-date',
              toggleClassesObject: { focussed: true }
            })
          })
          this.mollieCardComponents.cardExpiryDate.addEventListener('blur', () => {
            this.toggleFieldClass({
              elementId: 'expiry-date',
              toggleClassesObject: { focussed: false }
            })
          })
          this.mollieCardComponents.cardVerificationCode.addEventListener('focus', () => {
            this.toggleFieldClass({
              elementId: 'verification-code',
              toggleClassesObject: { focussed: true }
            })
          })

          this.mollieCardComponents.cardVerificationCode.addEventListener('blur', () => {
            this.toggleFieldClass({
              elementId: 'verification-code',
              toggleClassesObject: { focussed: false }
            })
          })
        }
        this.isLoadingInProccess = false
        this.isLoadingSuccessful = true
        this.isLoading = false
        this.areMollieComponentsReady = true
      }, 3000)
    }
  }
}
</script>

<style lang="scss">
.mollie-checkout__invoice__wrapper__content__form__fields__field__select {
  height: 60px;
  .multiselect__single {
    text-transform: capitalize;
  }

  .multiselect__option {
    text-transform: capitalize;
    span {
      text-transform: capitalize;
    }
    &.multiselect__option--highlight {
      color: #262629;
      background: #f1f9ff;
    }
  }

  .multiselect {
    &__tags {
      min-height: 60px;
    }

    &__select {
      height: 55px;
    }

    &__single,
    &__input {
      padding-top: 10px;
    }

    &__content-wrapper {
      min-height: 200px !important;
    }
  }

  &--small-min-height {
    .multiselect {
      &__content-wrapper {
        min-height: 80px !important;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@font-face {
  font-family: 'Larsseit';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Regular.woff');
}
@font-face {
  font-family: 'Larsseit-Medium';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Medium.woff');
}
@font-face {
  font-family: 'Larsseit-Bold';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Bold.woff');
}
</style>
<style lang="scss">
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.mollie-checkout {
  font-feature-settings: 'ss01' on;
  font-family: 'Larsseit-Regular';

  @media (min-width: 576px) {
    min-width: 480px;
  }

  &__fields-container {
    padding: 33px 20px 26px 20px !important;
    margin: 0 !important;
    // margin-bottom: 50px !important;
    border: 1px solid #a3cde8;
    border-radius: 8px;
  }

  &_charging_info {
    padding: 30px;
    text-align: center;
    font-size: 1.1rem;
  }

  &__holder {
    margin-bottom: 32px;
    //padding: 33px 20px 26px 20px;
    // border: 1px solid #a3cde8;
    // border-radius: 8px;
    min-height: 370px;
    height: 100%;

    &--modal {
      overflow: hidden auto;
      max-height: 450px;
    }
  }
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.95);
    z-index: 99;

    &__loader {
      text-align: center;

      &__spinner {
        display: inline-block;
        width: 70px;
        height: 70px;
        border: 10px solid rgb(222, 241, 254);
        border-bottom-color: rgb(66, 181, 255);
        border-radius: 50%;
        animation: rotation 1s linear infinite;
      }

      &__title {
        margin-top: 28px;
        color: rgb(38, 38, 41);
        font-family: 'Larsseit-Bold';
        font-size: 28px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.155556px;
      }

      &__text {
        margin-top: 6px;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        letter-spacing: -0.111111px;
        color: rgb(83, 83, 91);
      }
    }
  }
  &__invoice {
    &__wrapper {
      margin-bottom: 32px;
      &__content {
        &__form {
          &__fullname {
            display: flex;
            .mollie-checkout__invoice__wrapper__content__form__fields__field {
              &:first-child {
                width: 100%;
                max-width: 179px;
                margin-right: 16px;
              }
              &:last-child {
                width: 100%;
                max-width: 248px;
              }
            }
            @media (max-width: 575px) {
              flex-direction: column;
              .mollie-checkout__invoice__wrapper__content__form__fields__field {
                &:first-child,
                &:last-child {
                  max-width: 100%;
                }
              }
            }
          }
          &__equal-parts {
            display: flex;
            justify-content: space-between;
            .mollie-checkout__invoice__wrapper__content__form__fields__field {
              width: 100%;
              max-width: calc((100% / 2) - 8px);
            }
            @media (max-width: 575px) {
              flex-direction: column;
              .mollie-checkout__invoice__wrapper__content__form__fields__field {
                max-width: 100%;
              }
            }
          }
          &__fields {
            &__field {
              margin-top: 28px;

              &__inner {
                position: relative;
              }

              &__label {
                position: absolute;
                top: 50%;
                left: 20px;
                padding: 0 2px;
                touch-action: none;
                color: #878894;
                font-size: 20px;
                line-height: 20px;
                letter-spacing: -0.111111px;
                background: #ffffff;
                transform: translateY(-50%);
                max-width: calc(100% - 50px);

                &--moved {
                  top: 0;
                  font-family: 'Larsseit-Bold';
                  font-size: 15px;
                  line-height: 20px;
                  letter-spacing: -0.0833333px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  transition: all 0.2s linear;
                }

                &--over {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  z-index: 98;
                }
              }

              &__input {
                padding: 5px 20px;
                width: 100%;
                height: 60px;
                color: #35495e;
                font-family: 'Larsseit-Regular';
                font-size: 16px;
                line-height: 18px;
                background: rgb(255, 255, 255);
                border: 1px solid rgb(217, 219, 221);
                border-radius: 8px;

                &:focus + .mollie-checkout__invoice__wrapper__content__form__fields__field__label {
                  top: 0;
                  font-family: 'Larsseit-Bold';
                  font-size: 15px;
                  line-height: 20px;
                  letter-spacing: -0.0833333px;
                  transition: all 0.2s linear;
                }
              }

              &__rules-wrapper {
                display: flex;
                flex-wrap: wrap;
                margin-top: 24px;
              }

              &__rule {
                display: inline-flex;
                align-items: center;
                margin-top: 5px;
                margin-right: 4px;
                padding: 4px 14px;
                color: #538fb5;
                font-family: 'Larsseit-Medium';
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.04375px;
                background: #e8f6ff;
                border-radius: 12px;

                &__icon {
                  margin-right: 5px;
                }

                &--valid {
                  color: rgb(65, 184, 131);
                  background: rgba(65, 184, 131, 0.3);
                }
              }
            }
          }
        }
      }
    }
  }
  &__checkout {
    margin-bottom: 13px;
    &__caption {
      color: rgb(83, 83, 91);
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.1px;
      text-align: center;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 28px;

      @media (max-width: 575px) {
        flex-wrap: wrap;
        margin-top: 18px;
      }
    }

    &__button {
      flex: 1 1 auto;
      margin-left: 5px;
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      color: rgb(38, 38, 41);
      font-family: 'Larsseit-Bold';
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.0888889px;
      background: rgb(255, 255, 255);
      border: 1px solid rgb(217, 219, 221);
      box-shadow: 0px 2px 1px rgba(52, 53, 110, 0.0800422);
      border-radius: 8px;
      font-weight: bold;
      position: relative;
      overflow: hidden;
      @media (max-width: 575px) {
        margin-top: 10px;
        width: calc(50% - 5px);
      }
      &__wrapper {
        /* opacity: 0.5; */
        &.mollie-checkout__checkout__button__wrapper--not-active {
          opacity: 0.5;
        }
      }
      &__soon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        &__wrapper {
          background: rgba(130, 206, 255, 0.2);
          backdrop-filter: blur(1px);
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: 1;
        }
        span {
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: #262629;
          position: relative;
          z-index: 2;
        }
      }
      &.mollie-checkout__checkout__button--active {
        border-color: #a3cde8;
        background: #f1f9ff;
      }

      &:hover {
        cursor: pointer;
        background: #f1f9ff;
      }
      &--paypal {
        img {
          width: 75px;
          height: 20px;
        }
      }
      &--sepa {
        img:first-child {
          margin-bottom: 2.5px;
        }
      }
    }
  }
  &__next-button {
    display: flex;
    align-items: center;
    color: white;
    font-family: 'Larsseit-Bold';
    font-size: 20px;
    line-height: 18px;
    letter-spacing: -0.111111px;
    padding: 20px 43px;
    background: #3034f7;
    border: none;
    border-radius: 8px;
    margin-top: 50px;

    &:hover {
      cursor: pointer;
      background: rgba(48, 52, 247, 0.9);
    }

    &-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 50px;
    }

    &__icon {
      margin-left: 17px;
      width: 20px;
    }
  }
}

.wrapper {
  display: flex;
}

.wrapper form {
  width: 100%;
}

.row {
  display: flex;
  width: 100%;
  margin: 1px 0;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

/* form group */
.form-group {
  width: 100%;
  position: relative;
  margin: 0 8px;
  flex: 1;
}
.form-group.form-group--expiry-date {
  width: 100%;
  max-width: 263px;
}
.form-group.form-group--verification-code {
  width: 100%;
  max-width: 158px;
}

.row .form-group:first-child {
  margin-left: 0;
}

.row .form-group:nth-last-child(1) {
  margin-right: 0;
}

.form-group label {
  position: absolute;
  left: 20px;
  top: 0;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #a3b2c6;
  height: 60px;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 5;
  div {
    display: inline-flex;
  }
}

input:not(:placeholder-shown) ~ label,
input:focus ~ label,
.form-group--expiry-date label,
.form-group.is-dirty label,
.form-group.is-focussed label {
  font-size: 12px;
  transform: translateY(-50%);
  div {
    background: white;
    border-radius: 8px;
    padding: 0 5px;
  }
}

.form-input {
  outline: none;
  background-color: transparent;
  font-size: 18px;
}

.form-input,
.mollie-component {
  background: #eff1f3;
  min-height: 60px;
  border-radius: 8px;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.mollie-component::after {
  transition: 0.5s width cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #3a4;
  content: '';
  position: absolute;
  bottom: -1px;
  width: 0%;
  left: 0;
  border-bottom: 1px solid #ce0000;
}

.mollie-component.is-invalid::after {
  width: 100%;
}

.form-group.is-touched.is-valid .field-error {
  padding: 0;
}

.form-group.is-touched .field-error {
  color: #ce0000;
  font-weight: 400;
  padding: 1px 0;
  font-size: 10px;
}

button.submit-button {
  width: 100%;
  padding: 14px;
  color: #162941;
  font-weight: 600;
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  outline: 0;
  transition: 0.3s opacity cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid #162941;
  background-color: transparent;
}

.form-fields {
  margin-bottom: 32px;
  padding: 33px 0 0 0;
  margin-top: -33px;
}
</style>
