var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      { staticClass: "payment-details" },
      [
        !_vm.selectedPaymentMethod
          ? _c("div", { staticClass: "payment-details--heading" }, [
              _vm._v(_vm._s(_vm.$t("choosePricingPlan.choosePaymentMethod"))),
            ])
          : _c("div", { staticClass: "payment-details--heading" }, [
              _vm._v(_vm._s(_vm.$t("choosePricingPlan.addPaymentDetails"))),
            ]),
        _vm.selectedPlan &&
        _vm.subscriptionPlanId &&
        !_vm.showMollieVega &&
        _vm.newUidFlowStep === 3
          ? _c("Mollie", {
              attrs: {
                btnTitle: _vm.$t("vue.finish"),
                subscriptionPlanId: _vm.subscriptionPlanId,
                selectedPlan: _vm.selectedPlan,
                supportedPaymentMethods: _vm.supportedPaymentMethods,
                redirectUrl: _vm.mollieRedirectUrl,
              },
            })
          : _vm._e(),
        _vm.showMollieVega && _vm.newUidFlowStep === 3
          ? _c("MollieVega")
          : _vm._e(),
        _vm.showMollieGpt && _vm.newUidFlowStep === 3
          ? _c("MollieGpt")
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }