const state = {
  selectedAudio: null,
  selectedOutput: null,
  selectedVideo: null,

  micEnabled: false,
  videoEnabled: false,
  isChat: true,

  isAudioVideoAllowed: false,

  microphoneOptions: [],
  audioOutputOptions: [],
  cameraOptions: [],

  localStream: null,
  remoteStream: null,

  screenStream: null,
  cameraStream: null,
  mixer: null,

  hasWebrtcCommunicationStarted: false,

  screenSharingStatus: {
    status: 'stopped',
    initiatedBy: 'agent'
  },

  SCREEN_SHARING_STATUS: {
    STOPPED: 'stopped',
    STARTED: 'started',
    SCREEN_SHARING: 'screen-sharing',
    ENDED: 'ended'
  },

  messageModes: [],
  messageMode: 'chat',
  audioVideoStarted: false,
  visitorId: null,
  visitor: null,
  blurBackground: false,
  visitorVideoOn: false,
  visitorAudioOn: false,
  hasWebrtcSessionStarted: false
}

const getters = {
  selectedAudio: (state) => state.selectedAudio,
  selectedOutput: (state) => state.selectedOutput,
  selectedVideo: (state) => state.selectedVideo,

  micEnabled: (state) => state.micEnabled,
  videoEnabled: (state) => state.videoEnabled,
  isChat: (state) => state.isChat,

  isAudioVideoAllowed: (state) => state.isAudioVideoAllowed || state.selectedAudio || state.selectedVideo,

  microphoneOptions: (state) => state.microphoneOptions,
  audioOutputOptions: (state) => state.audioOutputOptions,
  cameraOptions: (state) => state.cameraOptions,

  localStream: (state) => state.localStream,
  remoteStream: (state) => state.remoteStream,
  screenStream: (state) => state.screenStream,
  cameraStream: (state) => state.cameraStream,
  mixer: (state) => state.mixer,

  screenSharingStatus: (state) => state.screenSharingStatus,
  SCREEN_SHARING_STATUS: (state) => state.SCREEN_SHARING_STATUS,

  hasWebrtcCommunicationStarted: (state) => state.hasWebrtcCommunicationStarted,

  messageModes: (state) => state.messageModes,
  messageMode: (state) => state.messageMode,
  audioVideoStarted: (state) => state.audioVideoStarted,
  visitorId: (state) => state.visitorId,
  visitor: (state) => state.visitor,
  blurBackground: (state) => state.blurBackground,
  visitorVideoOn: (state) => state.visitorVideoOn,
  visitorAudioOn: (state) => state.visitorAudioOn,
  hasWebrtcSessionStarted: (state) => state.hasWebrtcSessionStarted
}

const mutations = {
  setScreenSharingStatus: (state, payload) => {
    state.screenSharingStatus = payload
  },

  setSelectedAudio: (state, payload) => {
    state.selectedAudio = payload

    if (!state.micEnabled && payload && payload.value === 'microphone-off') {
      return
    }

    state.micEnabled = payload && payload.value !== 'microphone-off'
  },

  setSelectedOutput: (state, payload) => {
    state.selectedOutput = payload
  },

  setSelectedVideo: (state, payload) => {
    state.selectedVideo = payload

    if (!state.videoEnabled && payload && payload.value === 'camera-off') {
      return
    }

    state.videoEnabled = payload && payload.value !== 'camera-off'
  },

  setMicEnabled: (state, payload) => {
    state.micEnabled = payload
  },

  setVideoEnabled: (state, payload) => {
    state.videoEnabled = payload
  },

  setIsChat: (state, payload) => {
    state.isChat = payload
  },

  setIsAudioVideoAllowed: (state, payload) => {
    state.isAudioVideoAllowed = payload
  },

  setMicrophoneOptions: (state, payload) => {
    state.microphoneOptions = payload
  },

  setAudioOutputOptions: (state, payload) => {
    state.audioOutputOptions = payload
  },

  setCameraOptions: (state, payload) => {
    state.cameraOptions = payload
  },

  setLocalStream: (state, payload) => {
    if (!payload && state.localStream) {
      if (typeof state.localStream.getTracks === 'undefined') {
        state.localStream.stop()
      } else {
        if (state.localStream.getVideoTracks()) {
          const tracks = state.localStream.getVideoTracks()
          tracks.forEach((track) => {
            track.stop()
          })
        }
        if (state.localStream.getAudioTracks()) {
          const tracks = state.localStream.getAudioTracks()
          tracks.forEach((track) => {
            track.stop()
          })
        }
      }
    }
    state.localStream = payload
  },

  setRemoteStream: (state, payload) => {
    state.remoteStream = payload
  },

  setScreenStream: (state, payload) => {
    if (!payload && state.screenStream) {
      if (typeof state.screenStream.getTracks === 'undefined') {
        state.screenStream.stop()
      } else {
        if (state.screenStream.getVideoTracks()) {
          const tracks = state.screenStream.getVideoTracks()
          tracks.forEach((track) => {
            track.stop()
          })
        }
        if (state.screenStream.getAudioTracks()) {
          const tracks = state.screenStream.getAudioTracks()
          tracks.forEach((track) => {
            track.stop()
          })
        }
      }
    }
    state.screenStream = payload
  },

  setCameraStream: (state, payload) => {
    if (!payload && state.cameraStream) {
      if (typeof state.cameraStream.getTracks === 'undefined') {
        state.cameraStream.stop()
      } else {
        if (state.cameraStream.getVideoTracks()) {
          const tracks = state.cameraStream.getVideoTracks()
          tracks.forEach((track) => {
            track.stop()
          })
        }
        if (state.cameraStream.getAudioTracks()) {
          const tracks = state.cameraStream.getAudioTracks()
          tracks.forEach((track) => {
            track.stop()
          })
        }
      }
    }
    state.cameraStream = payload
  },

  setMixer: (state, payload) => {
    state.mixer = payload
  },

  setHasWebrtcCommunicationStarted: (state, payload) => {
    state.hasWebrtcCommunicationStarted = payload
  },

  setMessageModes: (state, payload) => {
    state.messageModes = payload
  },
  setMessageMode: (state, payload) => {
    state.messageMode = payload
  },
  setAudioVideoCallStarted: (state, payload) => {
    state.audioVideoStarted = payload
  },
  setVisitorId: (state, payload) => {
    state.visitorId = payload
  },
  setVisitor: (state, payload) => {
    state.visitor = payload
  },
  setBlurBackground: (state, payload) => {
    state.blurBackground = payload
  },
  setVisitorAudioOn: (state, payload) => {
    state.visitorAudioOn = payload
  },
  setVisitorVideoOn: (state, payload) => {
    state.visitorVideoOn = payload
  },
  setHasWebrtcSessionStarted: (state, payload) => {
    state.hasWebrtcSessionStarted = payload
  }
}

export const webrtc = {
  namespaced: true,
  state,
  getters,
  mutations
}
