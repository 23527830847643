<template>
  <transition name="fade">
    <div class="choose-country">
      <div v-if="isLoading" class="mollie-checkout__loading">
        <div v-if="isLoadingInProccess" class="mollie-checkout__loading__loader">
          <span class="mollie-checkout__loading__loader__spinner" />
          <div class="mollie-checkout__loading__loader__text">{{ loaderSpinnerText }}</div>
        </div>
      </div>
      <div class="choose-country-heading">
        {{ $t('choosePricingPlan.chooseYourCountry') }}
      </div>
      <div class="choose-country-multiselect" :class="{ 'choose-country-multiselect--open': isOpen }">
        <div class="choose-country-multiselect-header" :class="{ 'choose-country-multiselect-header--closed': !isOpen }" @click="isOpen = true">
          <div class="choose-country-multiselect-header--location" v-if="!isOpen">
            <span :class="{ 'choose-country-hidden': selectedCountry && selectedCountry.countryName }">{{
              $t('choosePricingPlan.whereIsCompanyLocated')
            }}</span>
            <span class="choose-country-multiselect-header--country" v-if="selectedCountry && selectedCountry.countryName">{{
              selectedCountry.countryName
            }}</span>
          </div>
          <div v-else>
            <input
              name="search"
              key="search"
              class="choose-country-multiselect--text-input"
              :placeholder="$t('choosePricingPlan.whereIsCompanyLocated')"
              v-model="search"
            />
          </div>
          <div class="choose-country-multiselect-header--arrow" @click="onToggleCountry($event)">
            <i v-if="isOpen" class="arrow up"></i>
            <i v-else class="arrow down"></i>
          </div>
        </div>
        <div class="choose-country-multiselect-countries" :class="{ 'choose-country-multiselect-countries--list': isOpen }">
          <scroll-view style="max-height: 242px">
            <div
              v-for="(country, i) of countries"
              :key="i"
              class="choose-country-multiselect-countries--country"
              :class="{ 'choose-country-multiselect-countries--country-selected': country.code === selectedCountry.code }"
              @click="onSelectCountry(country)"
            >
              <div class="choose-country-multiselect-countries--country-detail">
                <div v-if="selectedCountry.code === country.code">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.75" y="0.75" width="18.5" height="18.5" rx="3.25" fill="#3B86F7" stroke="#3B86F7" stroke-width="1.5" />
                    <path d="M14 7L7.8125 13L5 10.2727" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
                <div v-else>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.75" y="0.75" width="18.5" height="18.5" rx="3.25" stroke="#575757" stroke-width="1.5" />
                  </svg>
                </div>
                <div class="choose-country-multiselect-countries--country-text">{{ country.countryName }}</div>
              </div>
            </div>
          </scroll-view>
        </div>
      </div>
      <div class="choose-country-action" :class="{ 'choose-country-action--disabled': !selectedCountry.code }" @click="onClickNextStep">
        {{ $t('vue.nextStep') }}

        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.4223 0.440632L19.5795 7.94073C19.8613 8.23136 20 8.61574 20 9.00012C20 9.3845 19.8602 9.76794 19.5806 10.0604L12.4234 17.5605C11.8642 18.1465 10.9584 18.1465 10.3992 17.5605C9.84008 16.9746 9.84008 16.0254 10.3992 15.4394L15.1152 10.5001H1.39119C0.599866 10.5001 0 9.82841 0 9.04231C0 8.2562 0.599866 7.5001 1.39119 7.5001L15.1152 7.5001L10.4004 2.55941C9.8412 1.97347 9.8412 1.02423 10.4004 0.438288C10.9595 -0.147657 11.8631 -0.145313 12.4223 0.440632Z"
            :fill="actionButtonIconColor"
          />
        </svg>
      </div>
    </div>
  </transition>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { mpayment } from '@/mixins/mpayment'
import ScrollView from '@blackbp/vue-smooth-scrollbar'

export default {
  name: 'ChooseCountry',
  mixins: [mpayment],
  components: {
    ScrollView
  },
  data() {
    return {
      isOpen: false,
      search: '',
      countryCodes: this.$i18n.locale === 'de' ? require('@/assets/countryCodes_de.json') : require('@/assets/countryCodes.json'),
      selectedCountry: {
        code: null
      }
    }
  },
  watch: {
    'company.country'() {
      if (this.company.country) {
        this.selectedCountry = this.company.country
      }
    }
  },
  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser'
    }),
    countries() {
      return this.countryCodes.filter((x) => x.countryName.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
    },
    actionButtonIconColor() {
      return this.selectedCountry && this.selectedCountry.code ? '#42B5FF' : '#000000'
    }
  },
  mounted() {
    if (this.company.country && this.company.country.code && this.company.country.countryName) {
      this.selectedCountry = JSON.parse(JSON.stringify(this.company.country))
      this.setSelectedBillingCountry(this.selectedCountry)
    }
  },
  methods: {
    ...mapMutations({
      setSelectedBillingCountry: 'SET_SELECTED_BILLING_COUNTRY'
    }),
    onToggleCountry(event) {
      event.stopPropagation()
      this.isOpen = !this.isOpen
    },
    onSelectCountry(country) {
      if (this.selectedCountry && this.selectedCountry.code === country.code) {
        this.selectedCountry = { code: null }
      } else {
        this.selectedCountry = country
      }
      this.isOpen = false
      this.setSelectedBillingCountry(this.selectedCountry)
    },
    async onClickNextStep() {
      if (this.activeUserInfo && this.selectedCountry && this.selectedCountry.code) {
        this.loadCustomSpinner(true, this.$i18n.t('promocode.processing'))
        const _country = this.selectedCountry
        if (!this.selectedCountry.vatPercent) _country.vatPercent = null
        await this.$db.collection('company').doc(this.activeUserInfo.company).set({ country: _country }, { merge: true })
        this.$emit('go-to-next-step')
        this.loadCustomSpinner(false)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.choose-country {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-heading {
    margin-top: 45px;
    font-family: 'Larsseit';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.177778px;
    color: #262629;
  }

  &-action {
    margin-top: 60px;
    background: #3034f7;
    border-radius: 8px;
    width: 193px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Larsseit';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 19px;
    letter-spacing: -0.111111px;
    color: #ffffff;

    &--disabled {
      background: #d3d3d3;
    }

    cursor: pointer;

    svg {
      margin-left: 20px;
    }
  }

  &-multiselect {
    position: relative;
    margin-top: 40px;
    width: 480px;
    background: #ffffff;
    border: 1px solid #d9dbdd;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 58px;
    transition: height 500ms;

    &--text-input {
      border: none;
      width: 100%;
    }

    input:focus::placeholder {
      color: transparent;
    }

    &-header {
      position: relative;
      width: 100%;
      height: 58px;
      padding: 19px 22px 19px 22px;
      border-bottom: 1px solid #d9dbdd;

      &--location {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;
      }

      &--country {
        margin-left: 20px;
        padding: 5px 10px 5px 10px;
        text-transform: capitalize;
        background: #3034f7;
        border-radius: 6px;
        color: #fff;
        width: fit-content;
        max-width: 180px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        display: inline-block;
      }

      &--arrow {
        position: absolute;
        right: 20px;
        top: 15px;
        cursor: pointer;
      }

      &--closed {
        border-bottom: none;
      }
    }

    &--open {
      height: 300px;
      position: absolute;
    }

    &-countries {
      height: 242px;
      width: 480px;
      visibility: hidden;
      opacity: 0;
      transition: visibility 1s, opacity 1s linear;
      cursor: pointer;

      &--country {
        padding: 17px 20px 17px 20px;
        height: 60px;

        div * {
          text-transform: capitalize;
        }

        &-detail {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          width: 100%;
          align-items: center;
          div * {
            margin-right: 10px;
          }
        }

        &-text {
          height: 28px;
        }

        &-selected {
          background: #f1f9ff;
        }
      }

      &--list {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    &-multiselect {
      width: 100%;
      max-width: calc(100vw - 40px);
    }
    &-hidden {
      display: none;
    }
    &-action {
      width: 100%;
    }
  }
}

.arrow {
  border: solid #575757;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
</style>
