import firebase from 'firebase/app'
import 'firebase/firestore'
// Initialize Firebase
const configStage = {
  apiKey: 'AIzaSyCkk2zrfcoNOZUOhqlsVq0fRzlG4Wgkkf8',
  authDomain: 'pathadvice-stage.pathadvice.ai',
  databaseURL: 'https://pathadvice-stage.firebaseio.com',
  projectId: 'pathadvice-stage',
  storageBucket: 'pathadvice-stage.appspot.com',
  messagingSenderId: '56546318056',
  appId: '1:56546318056:web:9c18bf4214a49d9f21db5d',
  measurementId: 'G-E32DM83BTJ'
}
// Initialize Firebase
const configBeta = {
  apiKey: 'AIzaSyBWKDz830cgUEqG-IlJ5jyE4t4_EHfqV48',
  authDomain: 'pathadvice-beta.firebaseapp.com',
  databaseURL: 'https://pathadvice-beta-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'pathadvice-beta',
  storageBucket: 'pathadvice-beta.appspot.com',
  messagingSenderId: '1045350392568',
  appId: '1:1045350392568:web:5953827189b8186e05d10d',
  measurementId: 'G-MCBG8YJD8Q'
}
const configProduction = {
  apiKey: 'AIzaSyAJK_BQR5O8lJO5jo87DpnCrZpT-mmjOZE',
  authDomain: 'app.letsconnect.at',
  databaseURL: 'https://pathadvice-app.firebaseio.com',
  projectId: 'pathadvice-app',
  storageBucket: 'pathadvice-app.appspot.com',
  messagingSenderId: '226997819079',
  appId: '1:226997819079:web:643288fdc02bddf39ed0e2',
  measurementId: 'G-RY63CGY6NC'
}
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'pathadvice-app') {
  firebase.initializeApp(configProduction)
} else if (process.env.NODE_ENV === 'pathadvice-beta') {
  firebase.initializeApp(configBeta)
} else {
  firebase.initializeApp(configStage)
}

firebase.firestore().settings({ experimentalAutoDetectLongPolling: true, merge: true })
