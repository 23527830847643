<template>
  <div class="order-details">
    <div v-if="chosenPlan">
      <div class="order-details--heading">{{ $t('choosePricingPlan.orderDetails') }}</div>
      <div class="order-details__summary-container">
        <div class="order-details__summary-container--logo">
          <Logo />
        </div>
        <div class="order-details__summary-container__price">
          <div class="order-details__summary-container__price--title">{{ planName }}</div>
          <div class="order-details__summary-container__price--price">{{ totalAmountWithSymbol }}</div>
        </div>
      </div>
      <div class="order-details__price_container">
        <div class="order-details__price">
          <div class="order-details__price--title">{{ $t('choosePricingPlan.subtotal') }}</div>
          <div class="order-details__price--amount">{{ planPriceWithSymbol }}</div>
        </div>

        <div class="order-details__price">
          <div class="order-details__price--title">{{ $t('choosePricingPlan.vat') }} {{ vatPercent }}%</div>
          <div class="order-details__price--amount">{{ vatAmountWithSymbol }}</div>
        </div>
        <div class="order-details__total">
          <div class="order-details__total--title">{{ $t('choosePricingPlan.total') }}</div>
          <div class="order-details__total--amount">{{ totalAmountWithSymbol }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Logo from './Logo.vue'
export default {
  name: 'OrderDetailsGpt',
  components: {
    Logo
  },

  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser',
      chosenPlan: 'gpt/selectedGptPlan',
      accountVatNumber: 'accountVatNumber',
      selectedBillingCountry: 'selectedBillingCountry'
    }),
    planPrice() {
      if (!this.chosenPlan) return Number('0.0')

      return Number(this.chosenPlan.price)
    },
    planPriceWithSymbol() {
      return `€ ${this.planPrice.toFixed(2)}`
    },
    vatNumber() {
      return this.accountVatNumber
    },
    vatPercent() {
      /*
      vatPercent is only stored for EU companies
      If they are outside of the EU (i.e vatPercent does not exist in invoice) we need no VAT.
    */

      if (!this.selectedBillingCountry) {
        return Number('0.0')
      }

      if (typeof this.selectedBillingCountry.vatPercent === 'undefined') {
        return Number('0.0')
      }

      /* Vat is 20% if the customers are based in Austria  */
      if (this.selectedBillingCountry.code === 'at') {
        return Number(this.selectedBillingCountry.vatPercent)
      }

      /* If they have entered a VAT number and are inside the EU - then we do not need a VAT. */
      if (this.vatNumber && this.vatNumber.trim().length > 0 && this.selectedBillingCountry.vatPercent) {
        return Number(0.0)
      }

      /*If they have not entered a VAT number and are inside the EU - we charge the VAT from the country on top. */
      if (!this.vatNumber || this.vatNumber.trim().length === 0) {
        return Number(this.selectedBillingCountry.vatPercent)
      }

      return Number(0.0)
    },
    vatAmount() {
      return (this.planPrice * this.vatPercent) / 100
    },
    vatAmountWithSymbol() {
      return `€ ${this.vatAmount.toFixed(2)}`
    },
    totalAmount() {
      return this.planPrice + this.vatAmount
    },
    totalAmountWithSymbol() {
      return `€ ${this.totalAmount.toFixed(2)}`
    },

    planName() {
      const title = this.chosenPlan.description
      const type = this.chosenPlan.billing_type === 'year' ? this.$i18n.t('pricing.yearly') : this.$i18n.t('pricing.monthly')
      return `${title} ${type}`
    }
  }
}
</script>
<style lang="scss" scoped>
.order-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &--heading {
    font-family: 'Larsseit';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.0888889px;
    color: #262629;
  }

  &__summary-container {
    display: flex;
    margin-top: 16px;

    &__price {
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      &--title {
        font-family: 'Larsseit';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: -0.0889976px;
        color: #262629;
      }
      &--price {
        font-family: 'Larsseit';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: -0.0889976px;
        color: #878894;
      }
    }
  }

  &__price_container {
    margin-top: 62px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__price {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;

    &--title {
      font-family: 'Larsseit';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      letter-spacing: -0.0833333px;
      color: #53535b;
    }

    &--amount {
      font-family: 'Larsseit';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      text-align: right;
      letter-spacing: -0.0833333px;

      color: #262629;
    }
  }
  &__total {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 15px;

    &--title {
      font-family: 'Larsseit';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 21px;
      letter-spacing: -0.0833333px;
      color: #262629;
    }

    &--amount {
      font-family: 'Larsseit';
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 21px;
      text-align: right;
      letter-spacing: -0.0833333px;
      color: #262629;
    }
  }
}
</style>
